import 'core-js/es/object';
import 'core-js/es/array';

if (!Object.entries) {
    // tslint:disable-next-line: only-arrow-functions
    Object.entries = function (obj: any) {
        const ownProps = Object.keys(obj);
        const i = ownProps.length;
        // tslint:disable-next-line: prefer-array-literal
        const resArray = new Array(i); // preallocate the Array while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
}
