import React, { FC, useEffect } from 'react';
import { ServiceboardHeader } from 'components/layout/header/ServiceboardHeader';
import { ProducerMenu } from './Producer/ProducerMenu';
import { Notifications } from './Notifications';

import './MyNotificationPage.scss';

export const MyNotificationsPage: FC = () => {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => document.body.classList.remove('overflow-hidden');
    }, []);

    return <div className="vh-100 my-notification-page d-flex flex-column overflow-hidden">
        <ServiceboardHeader minimalHeader={true} />
        <div className="d-flex flex-column flex-md-row flex-grow-1 border-top border-opacity-40 my-notification-container">
            <ProducerMenu />
            <Notifications />
        </div>
    </div>;
};
