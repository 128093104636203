import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEssentialServices } from 'components/hooks/services/useEssentialServices';

export const LearnMoreTitle = () => {
    const { essentialServices } = useEssentialServices();
    const { t: translate } = useTranslation('service-list');
    return (
        <h3 className="mb-3">
            {translate(
                essentialServices.length > 0 ? 'service-list:learnMore' : 'service-list:getStarted',
            )}
        </h3>
    );
};
