import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
export { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

const defaultTimeZone = 'Europe/Paris';

// default thresholds directly copied/pasted from relativeTime.js
const defaultThresholds = [
    { l: 's', r: 44, d: 'second' },
    { l: 'm', r: 89 },
    { l: 'mm', r: 44, d: 'minute' },
    { l: 'h', r: 89 },
    { l: 'hh', r: 21, d: 'hour' },
    { l: 'd', r: 35 },
    { l: 'dd', r: 25, d: 'day' },
    { l: 'M', r: 45 },
    { l: 'MM', r: 10, d: 'month' },
    { l: 'y', r: 17 }, { l: 'yy', d: 'year' },
];

const customThresholds = [
    { l: 's', r: 1 }, // display seconds as minutes
];

const relativeTimeConfig = {
    thresholds: [
        ...defaultThresholds.filter(x => customThresholds.findIndex(c => c.l === x.l) === -1),
        ...customThresholds,
    ],
};

dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(relativeTime, relativeTimeConfig);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);

dayjs.tz.setDefault(defaultTimeZone);

const dateFormat = 'D MMM YYYY';
const timeFormat = 'h:mm A [(PT)]';
const timeSecFormat = 'h:mm:ss A [(PT)]';

type DateType = Date | string | number | null | undefined;

const dayjstz = (date: DateType) => dayjs(date)?.tz();

const differenceInHours = (date1: DateType, date2: DateType) => {
    return dayjs(date1).diff(date2, 'hour');
};

export const useDate = () => {
    const { i18n: { language } } = useTranslation();

    const formatYear = (date: DateType) => date && dayjstz(date).locale(language).format('YYYY');

    const formatMonth = (date: DateType) => date && dayjstz(date).locale(language).format('MMM');

    const formatDay = (date: DateType) => date && dayjstz(date).locale(language).format('D');

    const formatTime = (date: DateType) => date && dayjstz(date).locale(language).format(timeFormat);

    const formatTimeSec = (date: DateType) => date && dayjstz(date).locale(language).format(timeSecFormat);

    const formatShortDatetime = (date: DateType) => date && (dayjstz(date).isToday() ? formatTime(date) : formatDate(date));

    const formatDate = (date: DateType) => dayjstz(date).locale(language).format(dateFormat);

    const displayDate = (date: DateType, withoutSuffix = false) => {
        if (!date || !dayjstz(date).isValid()) {
            return '';
        }

        const duration = differenceInHours(date, Date.now());
        if (duration <= 0 && duration > -48) {
            return dayjstz(date).locale(language).fromNow(withoutSuffix);
        }

        return formatDate(date);
    };

    return {
        formatYear,
        formatMonth,
        formatDay,
        formatTimeSec,
        formatShortDatetime,
        formatDate,

        displayDate,
    };
};
