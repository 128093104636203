import React, { MutableRefObject, PropsWithChildren, useEffect } from 'react';

type ComboboxProps = {
    rootRef: MutableRefObject<HTMLElement | null>;
    isOpen: boolean;
    onOutsideClick: () => void;
};

export const Combobox: React.FunctionComponent<PropsWithChildren<ComboboxProps>> = ({
    children,
    rootRef,
    isOpen,
    onOutsideClick,
}) => {
    useEffect(() => {
        const listenOutsideClick = async (event: MouseEvent) => {
            const target = event.target as Node;
            if (rootRef.current && !rootRef.current.contains(target)) {
                onOutsideClick();
            }
        };
        
        document.addEventListener('click', listenOutsideClick);
        return () => {
            document.removeEventListener('click', listenOutsideClick);
        };
    }, [rootRef, onOutsideClick]);

    return (
        <div
            className={`position-relative w-100 ${isOpen ? 'show' : 'd-none'}`}
            aria-hidden={!isOpen}
        >
            {children}
        </div>
    );
};
