import React from 'react';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';
import { useTranslation } from 'react-i18next';
import { SettingListGroup, SettingListGroupItemProps } from '../components/SettingListGroup';
import { ActionLinkButton } from '../components/ActionLinkButton';

export const ServiceSettings = () => {
    const { t: translate } = useTranslation('my-account');
    const { isInsightSubscriptionsVisible, isSharinboxVisible } = useApplicationContext();
    const { insightSubscriptionUrl, sharinboxPersoInfoUrl } = getConfiguration();

    const items: SettingListGroupItemProps[] = [];
    if (isInsightSubscriptionsVisible && isInsightSubscriptionsVisible === true) {
        const insightServiceName = 'Research & Insight';
        const item: SettingListGroupItemProps = {
            title: insightServiceName,
            titleLngKey: '',
            description: translate('settings-service-description', {
                'serviceName': insightServiceName,
            }),
            descriptionLngKey: '',
            iconName: 'email',
            wrapInLink: true,
            itemUrl: insightSubscriptionUrl,
            actionComponent: <ActionLinkButton labelLngKey="label-manage" actionUrl={insightSubscriptionUrl} />,
        };

        items.push(item);
    }

    if (isSharinboxVisible && isSharinboxVisible === true) {
        const shareinboxName = 'Sharinbox';
        const item: SettingListGroupItemProps = {
            title: shareinboxName,
            titleLngKey: '',
            description: translate('settings-service-description', {
                'serviceName': shareinboxName,
            }),
            descriptionLngKey: '',
            iconName: 'how_to_reg',
            wrapInLink: true,
            itemUrl: sharinboxPersoInfoUrl,
            actionComponent: <ActionLinkButton labelLngKey="label-manage" actionUrl={sharinboxPersoInfoUrl} />,
        };

        items.push(item);
    }

    return <SettingListGroup items={items} titleLngKey="settings-service" />;
};