import { Scroller } from 'components/common/scroll/Scroller';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useRecommendedServices } from 'components/hooks/services/useRecommendedServices';
import { TopicTracked } from 'models/tracking';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecommendationCard } from './RecommendationCard';
import { Link } from 'react-router-dom';
import { CATALOG_FOR_YOU_PATH } from 'models/constants';

const RECOMMENDED_COUNT = 3;

export const RecommendationsLayout: React.FunctionComponent = () => {
    const { recommendedServices } = useRecommendedServices(RECOMMENDED_COUNT);
    const { t: translate } = useTranslation('service-list');

    if (recommendedServices.length === 0) {
        return <></>;
    }

    return (
        <ActionTrackingInfoProvider actionTrackedInfo={{ topic: TopicTracked.RecommendedServices }}>
            <div className="container-fluid bg-lvl2 h-100 px-0">
                <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3 pt-5">
                    <div className="d-flex">
                        <h3 className="mb-0 flex-grow-1 text-truncate">{translate('service-list:recommended')}</h3>
                        <Link
                            to={CATALOG_FOR_YOU_PATH}
                            className="btn btn-lg btn-icon-end btn-flat-secondary">
                            {translate('service-list:recommendedViewMore')} <i className="ms-1 icon icon-lg">arrow_forward_ios</i>
                        </Link>
                    </div>
                    <Scroller className="row row-cols-1 row-cols-md-2 row-cols-lg-3 flex-row flex-nowrap flex-md-wrap pb-4 px-3 px-sm-1 px-lg-1">
                        {recommendedServices.map(service => (
                            <RecommendationCard key={service.ServiceKey} service={service} />
                        ))}
                    </Scroller>
                </div>
            </div>
        </ActionTrackingInfoProvider>
    );
};
