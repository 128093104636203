import React from 'react';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { TopicTracked } from 'models/tracking';
import { useHighlightLayout } from 'components/hooks/highlightLayout/useHighlightLayout';
import { HighlightLayouts } from '../components/highlightLayout/HighlightLayouts';
import { HighlightLayoutPage } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const CatalogForYouPage = () => {
    const { getLayoutsByPage } = useHighlightLayout();
    const highlightLayouts = getLayoutsByPage(HighlightLayoutPage.ForYou);

    return <ActionTrackingInfoProvider actionTrackedInfo={{ topic: TopicTracked.CatalogForYou }}>
        <HighlightLayouts layouts={highlightLayouts} />
    </ActionTrackingInfoProvider>;
};