import { IPaginatedSgmSearchResults } from 'components/hooks/search/useSearchItemResults';

export const clipList = <T>(
    fullList: Nullable<T[]>,
    limit: Nullable<number>,
) => {
    const list = fullList || [];
    return {
        clipped: limit ? list.slice(0, limit) : list,
        total: list.length,
        hasMore: !!limit && list.length > limit,
    };
};

export const clipPaginateList = <TList>(
    paginateList: Required<IPaginatedSgmSearchResults<TList>>,
    limit: Nullable<number>,
): {
    clipped: TList[],
    total: number,
    hasMore: boolean,
} => {
    const list = paginateList.items || [];
    return {
        clipped: limit ? list.slice(0, limit) : list,
        total: paginateList.searchMetaPageData.totalCount || 0,
        hasMore: !!limit && list.length > limit,
    };
};
