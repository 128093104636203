import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes as Switch, useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useQuery } from 'components/hooks/navigation/useQuery';
import { useAllMyServices } from 'components/hooks/services/useAllMyServices';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { AllMyServicesNavbar } from 'models/navigation';
import { TopicTracked, FeatureTracked } from 'models/tracking';
import { EssentialsNavBar } from '../essentials/EssentialsNavBar';
import { ServicesCompactList } from './ServicesCompactList';
import { SortMenu } from './SortMenu';
import { getSortField, SortField, sortServicesBy } from './sort-service-list';
import { ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

import './AllServicesLayout.scss';

export const AllServicesLayout = () => {
    const { allMyservices, sgmServices, nonSgmServicebookmarked } =
        useAllMyServices();
    const { getParam } = useQuery();
    const currentSort = getSortField(getParam('sort'));

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3">
            <EssentialsNavBar active="all-services" />
            <div className="mb-4 all-services-navbar">
                <div className="col-md-12 col-xxl-10 d-block">
                    <Switch>
                        <Route
                            path={'all-my-sgm-services'}
                            element={
                                <AllServicesList
                                    active="all-my-sgm-services"
                                    services={sgmServices}
                                    currentSort={currentSort}
                                    isSgmServices={true}
                                />
                            }
                        />
                        <Route
                            path={'all-sg-bookmarked-services'}
                            element={
                                <AllServicesList
                                    active="all-sg-bookmarked-services"
                                    services={nonSgmServicebookmarked}
                                    currentSort={currentSort}
                                    isSgmServices={false}
                                />
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <AllServicesList
                                    active="all-my-services"
                                    services={allMyservices}
                                    currentSort={currentSort}
                                    isSgmServices={true}
                                />
                            }
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const AllServicesNavbar: React.FunctionComponent<{
    active: AllMyServicesNavbar;
}> = ({ active }) => {
    const [openIsTracked, setOpenIsTracked] = useState<boolean>(false);
    const { t: translate } = useTranslation('service-list');
    const { hasNonSgmServicebookmarked } = useAllMyServices();
    const { trackAction } = useServiceTracking();

    useEffect(() => {
        let unmounted = false;
        if (!openIsTracked && !unmounted) {
            setOpenIsTracked(true);
            trackAction(FeatureTracked.OpenAllMyServices, {
                topic: TopicTracked.AllMyServices,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [openIsTracked, trackAction]);

    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.AllMyServices }}
        >
            {hasNonSgmServicebookmarked && (
                <Nav className="nav nav-underline my-0 me-auto">
                    <AllServicesNavLink
                        to="all-my-services"
                        featureName={FeatureTracked.OpenAllMyServices}
                        className="px-0 me-3"
                        active={active}
                    >
                        <span className="d-none d-lg-block">
                            {translate('service-list:allServices')}
                        </span>
                        <span className="d-block d-lg-none">
                            {translate('service-list:all')}
                        </span>
                    </AllServicesNavLink>
                    <AllServicesNavLink
                        to="all-my-sgm-services"
                        featureName={FeatureTracked.OpenSGMarketsTab}
                        active={active}
                    >
                        {translate('service-list:sgmTabLabel')}
                    </AllServicesNavLink>
                    <AllServicesNavLink
                        to="all-sg-bookmarked-services"
                        featureName={FeatureTracked.OpenSGTab}
                        active={active}
                    >
                        <span className="d-none d-lg-inline">
                            {translate('service-list:sgTabLabel')}
                        </span>
                        <span className="d-inline d-lg-none">
                            {translate('service-list:sgTabLabelShort')}
                        </span>
                    </AllServicesNavLink>
                    <div className="flex-grow-1" />
                    <NavItem>
                        <SortMenu />
                    </NavItem>
                </Nav>
            )}
        </ActionTrackingInfoProvider>
    );
};

type AllServicesNavLinkProps = {
    to: AllMyServicesNavbar;
    active: AllMyServicesNavbar;
    featureName: FeatureTracked;
    className?: string;
};

const AllServicesNavLink: React.FunctionComponent<
    PropsWithChildren<AllServicesNavLinkProps>
> = ({ to, active, featureName, className, children }) => {
    const { search } = useLocation();
    const { trackAction } = useServiceTracking();
    const navigate = useNavigate();
    const navId = `all-services-sub-nav-${to}`;

    const trackClick = () => {
        trackAction(featureName, { topic: TopicTracked.AllMyServices });
    };

    const handleSearchClick = async (subCategoryUrl: string) => {
        navigate(subCategoryUrl);
        trackClick();
    };

    return (
        <>
            <NavItem className="d-none d-lg-block">
                <NavLink
                    tag={Link}
                    to={`/myservices/all-services/${to}${search}`}
                    className={classNames({ active: active === to }, className)}
                    onClick={trackClick}
                >
                    {children}
                </NavLink>
            </NavItem>
            <a
                role="button"
                href={`/myservices/all-services/${to}${search}`}
                className="d-flex d-lg-none badge rounded-pill badge-default align-items-center me-2 mt-0 mb-1 text-decoration-none form-check"
                onClick={() => handleSearchClick(`/myservices/all-services/${to}${search}`)}
            >
                <input
                    role="button"
                    type="radio"
                    name="all-services-sub-nav"
                    id={navId}
                    checked={active === to}
                    className="form-check-input"
                    tabIndex={-1}
                    readOnly
                />
                <label
                    htmlFor={navId}
                    className="form-check-label text-large ms-2"
                    style={{ lineHeight: '20px' }}
                >
                    {children}
                </label>
            </a>
        </>
    );
};

type AllServicesListProps = {
    active: AllMyServicesNavbar;
    services: ServiceDto[];
    currentSort: SortField;
    isSgmServices: boolean;
};

const AllServicesList: React.FunctionComponent<AllServicesListProps> = ({
    active,
    services,
    currentSort,
    isSgmServices,
}) => (
    <>
        <AllServicesNavbar active={active} />
        <div className="px-0">
            <ServicesCompactList
                services={sortServicesBy(services, currentSort)}
                isSgmServices={isSgmServices}
            />
        </div>
    </>
);
