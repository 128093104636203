import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';
import { extractCategoryKey } from 'components/hooks/services/useCategories';
import { Scroller } from 'components/common/scroll/Scroller';
import { CATALOG_BROWSE_PATH, MARKETS_CATEGORY } from 'models/constants';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';

export const useActiveCategoryKey = () => {
    const { pathname } = useLocation();
    const { visibleServices } = useCatalogServices();

    const [categoryPath, subCategoryPath] = pathname.replace(`${CATALOG_BROWSE_PATH}/`, '').split('/');

    const activeCategory = visibleServices.find(x => extractCategoryKey(x.Category?.Key) === categoryPath)?.Category;

    const activeCategoryKey = activeCategory?.Key || MARKETS_CATEGORY;
    const activeSubCategory = visibleServices.find(x => extractCategoryKey(x.Subcategory?.Key) === subCategoryPath)?.Subcategory;

    return {
        activeCategoryKey: activeCategoryKey,
        activeSubCategoryKey: activeSubCategory?.Key,
        isMarketsCategory: activeCategoryKey === MARKETS_CATEGORY,
    };
};

export const CatalogBrowseCategoryTabs: FC = () => {
    const { activeCategoryKey } = useActiveCategoryKey();
    const { trackAction } = useServiceTracking();
    const { visibleCategories } = useCatalogServices();

    const getCategoryLinkClassName = (categoryKey: Nullable<string>) => {
        return classNames('nav-link nav-link-lg', { 'active': categoryKey === activeCategoryKey });
    };

    return <div className="d-flex flex-column">
        <Scroller>
            <ul className="nav nav-lg nav-underline nav-hover flex-nowrap">
                {visibleCategories.map(x => {
                    return <li key={x.Key} className="nav-item col-auto">
                        <Link
                            to={`${CATALOG_BROWSE_PATH}/${extractCategoryKey(x.Key)}`}
                            className={getCategoryLinkClassName(x.Key)}
                            onClick={async () => await trackAction(FeatureTracked.BrowseCategory, {
                                topic: TopicTracked.CatalogBrowse,
                                parameters: {
                                    'category-key': x.Key || '',
                                    'category-label': x.Name || '',
                                },
                            })}
                        >
                            {x.Name}
                        </Link>
                    </li>;
                })}
            </ul>
        </Scroller>
        <div className="border-bottom"></div>
    </div>;
};