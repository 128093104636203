import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalizedObject } from 'components/hooks/translation/useLocalizedObject';
import { TextModel } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type ServiceDescriptionMainTextProps = {
    text: Nullable<TextModel>;
};

export const ServiceDescriptionMainText: React.FunctionComponent<ServiceDescriptionMainTextProps> = ({
    text,
}) => {
    const localize = useLocalizedObject();
    const { t: translate } = useTranslation('service-description');

    if (!text) {
        return <></>;
    }

    const paragraphs = localize<string[]>(text);
    if (!paragraphs || paragraphs.length === 0) {
        return <></>;
    }

    return (
        <div className="mb-4">
            <h2 className="h4 mb-4">
                {translate('service-description:description')}
            </h2>

            {paragraphs.map((paragraph, index) => (
                <p key={index} dangerouslySetInnerHTML={{
                    __html: paragraph,
                }} />
            ))}
        </div>
    );
};
