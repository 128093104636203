import { useLocation } from 'react-router-dom';
import 'core-js/features/url-search-params'; // URLSearchParams polyfill for IE

export const useQuery = () => {
    const { search, pathname } = useLocation();
    const queryParams = new URLSearchParams(search);

    const getParam = (key: string) => queryParams.get(key);

    const getLink = (newParams: { [key: string]: string }) => {
        const linkParams = new URLSearchParams(queryParams);
        Object.keys(newParams).forEach(key => {
            linkParams.set(key, newParams[key]);
        });
        return `${pathname}?${linkParams.toString()}`;
    };

    return { getParam, getLink };
};
