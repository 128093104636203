import classNames from 'classnames';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { useSearchQuery } from 'components/hooks/search/useSearchQuery';
import { useCategories } from 'components/hooks/services/useCategories';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { scrollUp } from 'core/utils/animations';
import { FeatureTracked } from 'models/tracking';
import React, { FC, useCallback, useState } from 'react';
import { Collapse } from 'reactstrap';
import { Category, Subcategory } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

const SearchFilterSgmCategory: FC<{ category: Category }> = ({ category }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const toggle = () => setIsOpen(!isOpen);
    const {
        selectedCategories,
        selectedSubcategories,
        addCategory,
        removeCategory,
        addSubcategory,
        removeSubcategory,
    } = useSearchQuery();

    const trackCategoryCallback = useCallback(async (category: Category) => {
        await trackAction(FeatureTracked.FilterSGResultsByCategory, {
            topic: actionTrackedInfo.topic,
            parameters: {
                'category-key': category.key,
                'category-label': category.label,
            },
        });
    }, [trackAction, actionTrackedInfo]);

    const trackSubCategoryCallback = useCallback(async (subCategory: Subcategory) => {
        await trackAction(FeatureTracked.FilterSGResultsBySubCategory, {
            topic: actionTrackedInfo.topic,
            parameters: {
                ...subCategory,
                'category-key': category.key,
                'category-label': category.label,
                'sub-category-key': subCategory.key,
                'sub-category-label': subCategory.label,
            },
        });
    }, [trackAction, actionTrackedInfo]);

    const toggleCategory = async () => {
        scrollUp();
        if (isSelected) removeCategory(category.key || '');
        else {
            addCategory(category.key || '');
            await trackCategoryCallback(category);
        }
    };

    const isSelected = selectedCategories
        .map((e) => e.key)
        .includes(category.key);
    const hasSubcategories = !!category.subcategories?.length;

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 m-0 py-2 px-3 bg-lvl2 border-top">
                {hasSubcategories ? (
                    <button
                        disabled={!hasSubcategories}
                        type="button"
                        onClick={toggle}
                        className="btn btn-flat-primary m-0 text-secondary fw-medium btn-icon-text"
                    >
                        <i className="icon icon-sm">
                            {!isOpen ? 'expand_more' : 'expand_less'}
                        </i>
                        {category.label}
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={toggleCategory}
                        className="btn btn-flat-primary m-0 text-secondary fw-medium btn-icon-text"
                    >
                        <i className="icon icon-sm">remove</i>
                        {category.label}
                    </button>
                )}
                <button
                    className="btn btn-discreet p-0"
                    style={{ width: '17px' }}
                    type="button"
                    onClick={toggleCategory}
                >
                    <i
                        className={classNames('icon', {
                            'text-info': isSelected,
                        })}
                        style={{ fontSize: '17px', fontWeight: 100 }}
                    >
                        {isSelected ? 'check_box' : 'check_box_outline_blank'}
                    </i>
                </button>
            </div>
            {hasSubcategories && (
                <Collapse isOpen={isOpen}>
                    <div className="px-2 bg-lvl2 pb-3">
                        {category.subcategories?.map((s) => {
                            const isSelectedSubcategory = selectedSubcategories
                                .map((e) => e.key)
                                .includes(s.key);

                            const toggleSubcategory = async () => {
                                scrollUp();
                                if (isSelectedSubcategory) {
                                    removeSubcategory(s.key || '');
                                } else {
                                    addSubcategory(s.key || '', category.key);
                                    await trackSubCategoryCallback(s);
                                }
                            };

                            return (
                                <button
                                    type="button"
                                    key={`${category.key}-${s.key}`}
                                    className={classNames('btn m-1 border', {
                                        'btn-discreet-info border-info':
                                            isSelectedSubcategory &&
                                            (isSelected ||
                                                selectedCategories.length ===
                                                0),
                                        'btn-discreet-warning border-warning':
                                            isSelectedSubcategory &&
                                            !isSelected &&
                                            selectedCategories.length > 0,
                                        'btn-discreet': !isSelectedSubcategory,
                                    })}
                                    onClick={toggleSubcategory}
                                >
                                    {s.label}
                                </button>
                            );
                        })}
                    </div>
                </Collapse>
            )}
        </>
    );
};

export const SearchSgmFilterMenu = () => {
    const categories = useCategories();

    return (
        <>
            {categories.map((c, i) => (
                <SearchFilterSgmCategory key={i} category={c} />
            ))}
        </>
    );
};
