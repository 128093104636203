import React, { FC, createContext, useContext, PropsWithChildren } from 'react';
import { TopicTracked, ServiceTracked, ActionTrackedInfo } from 'models/tracking';

interface ServiceTrackingContextProps {
    serviceTracked: ServiceTracked;
}

export const ServiceTrackingContext = createContext<ServiceTrackingContextProps>({
    serviceTracked: {
        topic: TopicTracked.None,
        service: undefined,
        origin: undefined,
    },
});

export const useServiceTrackingContext = () => useContext(ServiceTrackingContext);

export const ServiceTrackingInfoProvider: FC<PropsWithChildren<ServiceTrackingContextProps>> = ({ serviceTracked, children }) => {
    return (
        <ServiceTrackingContext.Provider
            value={{
                serviceTracked,
            }}
        >
            {children}
        </ServiceTrackingContext.Provider>
    );
};

interface ActionTrackingContextProps {
    actionTrackedInfo: ActionTrackedInfo,
}

export const ActionTrackingContext = createContext<ActionTrackingContextProps>(
    {
        actionTrackedInfo: {
            topic: TopicTracked.None,
        },
    });

export const useActionTrackingContext = () => useContext(ActionTrackingContext);

export const ActionTrackingInfoProvider: FC<PropsWithChildren<ActionTrackingContextProps>> = ({ actionTrackedInfo, children }) => {
    return (
        <ActionTrackingContext.Provider
            value={{
                actionTrackedInfo,
            }}
        >
            {children}
        </ActionTrackingContext.Provider>
    );
};
