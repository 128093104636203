import classNames from 'classnames';
import React, { useId } from 'react';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';

type InputFormProps<T extends FieldValues> = {
    name: FieldPath<T>;
    label: string;
    placeholder?: string;
    helper?: string;
    className?: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const InputForm = <T extends FieldValues>({ name, label, placeholder, helper, className, register, errors, disabled }: InputFormProps<T>): JSX.Element => {
    const inputId = useId();
    const error = errors[name];

    const rootClassName = classNames('input-container', className);

    const inputClassName = classNames('form-control', {
        'is-invalid': error,
    });

    return <div className={rootClassName}>
        <label htmlFor={inputId}>
            {label}
        </label>
        <input
            id={inputId}
            type="text"
            placeholder={placeholder}
            {...register(name)}
            className={inputClassName}
            disabled={disabled}
        />
        {helper && <span className="form-text">{helper}</span>}
        {error && <div className="invalid-feedback">
            {error.message as string}
        </div>}
    </div>;
};