import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/store';
import { TopicTracked } from 'models/tracking';
import { BookmarkableServiceCard } from 'components/common/bookmarkableItem/BookmarkableServiceCard';
import { useBookmark } from 'components/hooks/bookmark/useBookmark';
import { BookmarkDto, BookmarkOrigin } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

import './BookmarksLayout.scss';

export const GetBookmarkItems = (store: AppState) => [
    ...(store.bookmark.items || []),
];

export const BookmarksLayout = () => {
    const { bookmarks } = useBookmark();
    const { t: translate } = useTranslation('bookmark');
    if (bookmarks.length === 0) {
        return null;
    }

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3">
            <div className="mb-4">
                <div id="bookmarks" className="mb-5">
                    <h3 className="mb-3">{translate('bookmark:bookmarks')}</h3>
                    <div className="row row-cols-4 row-cols-sm-5 row-cols-md-6 row-cols-lg-8 row-cols-xl-10 g-0">
                        {bookmarks.map((bookmarkItem, index) => (
                            <BookmarkCard key={index} item={bookmarkItem} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const BookmarkCard = ({ item }: { item: BookmarkDto }) => {
    const isSgmService = item.Origin === BookmarkOrigin.SgmService;
    const showAsSgmItem = isSgmService || item.Origin === BookmarkOrigin.SgDashboardBoard || (item.Origin === BookmarkOrigin.NonSgmService && item.RelationType === 'RelatedToSgmPlatform');
    const bookmarkOrigin = item.Origin ? BookmarkOrigin[item.Origin] : null;

    return (
        <BookmarkableServiceCard
            bookmarkableItemId={item.ReferenceId || ''}
            showAsSgmItem={showAsSgmItem}
            bookmarkOrigin={bookmarkOrigin}
            initials={item.Initials}
            title={item.Title}
            itemPageUrl={item.PageUrl}
            topicTracked={TopicTracked.Bookmarks}
            bookmarkId={item.Id}
        />
    );
};