import classNames from 'classnames';
import { handleA11y } from 'core/utils/a11y';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TabType = 'info' | 'banner';

type TabsSignatureProps = {
    activeTab: TabType;
    onChange: (tab: TabType) => void
}

export const TabsSignature: FC<TabsSignatureProps> = ({ activeTab, onChange }) => {
    const { t: translate } = useTranslation('signature');

    return <ul className="nav nav-lg nav-tabs border-primary my-3">
        <li className="nav-item">
            <span
                className={classNames('nav-link', { active: activeTab === 'info' })}
                onClick={handleA11y(() => onChange('info'))}
                onKeyUp={handleA11y(() => onChange('info'))}
                tabIndex={0}
                role="button"
            >
                <i className="icon icon-xs me-2">border_color</i>
                {translate('signature:infoTab')}
            </span>
        </li>
        <li className="nav-item">
            <span
                className={classNames('nav-link', { active: activeTab === 'banner' })}
                onClick={handleA11y(() => onChange('banner'))}
                onKeyUp={handleA11y(() => onChange('banner'))}
                tabIndex={0}
                role="button"
            >
                <i className="icon icon-xs me-2">color_lens</i>
                {translate('signature:bannerTab')}
            </span>
        </li>
    </ul>;
};