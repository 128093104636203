import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createBookmarkFetchFailureAction,
    createBookmarkFetchRequestAction,
    createBookmarkFetchSuccessAction,

    createBookmarkCreateFailureAction,
    createBookmarkCreateRequestAction,
    createBookmarkCreateSuccessAction,

    createBookmarkDeleteFailureAction,
    createBookmarkDeleteRequestAction,
    createBookmarkDeleteSuccessAction,
} from 'store/actions/bookmarkAction';
import { toastSuccess } from 'components/common/toaster/toast';
import { BookmarkApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';
import { BookmarkOrigin, CreateBookmarkDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const getUserBookmarks = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { logger },
    ) => {
        dispatch(createBookmarkFetchRequestAction());
        try {
            const bookmarks = await BookmarkApi.getUserBookmarks();
            dispatch(createBookmarkFetchSuccessAction(bookmarks?.Items ?? []));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createBookmarkFetchFailureAction());
        }
    };
};

export const createUserBookmark = (referenceId: Nullable<string>, origin: Nullable<BookmarkOrigin>): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { logger, translate },
    ) => {
        dispatch(createBookmarkCreateRequestAction());
        try {
            const request = { referenceId, origin } as CreateBookmarkDto;
            const bookmarkCreated = await BookmarkApi.createUserBookmark(request);
            toastSuccess(translate('bookmark:addToBookmarkToaster', { title: bookmarkCreated?.Title ?? '' }));
            dispatch(createBookmarkCreateSuccessAction(bookmarkCreated));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createBookmarkCreateFailureAction());
        }
    };
};

export const deleteUserBookmark = (id: number): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { logger, translate },
    ) => {
        dispatch(createBookmarkDeleteRequestAction());
        try {
            await BookmarkApi.deleteBookmark(id);
            const title = state().bookmark?.items?.filter(e => e.Id === id)[0]?.Title ?? '';
            toastSuccess(translate('bookmark:removeBookmarkToaster', { title }));
            dispatch(createBookmarkDeleteSuccessAction(id));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createBookmarkDeleteFailureAction());
        }
    };
};