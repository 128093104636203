import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentationPage } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type ServiceDescriptionDocumentationPagesProps = {
    documentationPages: Nullable<DocumentationPage[]>
};

export const ServiceDescriptionDocumentationPages: React.FunctionComponent<ServiceDescriptionDocumentationPagesProps> = ({
    documentationPages,
}) => {
    const { t: translate } = useTranslation('service-description');
    if (!documentationPages || documentationPages.length === 0) {
        return <></>;
    }

    return (
        <div>
            <h2 className="h4 mb-3">
                {translate('service-description:relatedLinks')}
            </h2>
            <div className="list-group">
                {documentationPages.map((documentationPage, index) => (
                    <div key={index} className="list-group-item list-group-item-action d-flex align-items-center bg-secondary bg-opacity-05"  >
                        <i className="icon icon-md text-secondary">public</i>
                        <div className="ms-3">
                            <div className="fw-bold">{documentationPage.Label}</div>
                            <div>
                                <a href={documentationPage.Url || ''} className="stretched-link" target="_blank" rel="noreferrer">{documentationPage.Url}</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
