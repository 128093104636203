import { Section } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const createFetchBannersAction = () => ({
    type: '@BANNER/FETCH_BANNERS',
} as const);

export const createFetchBannersSuccessAction = (bannerSections: Section[]) => ({
    type: '@BANNER/FETCH_BANNERS_SUCCESS',
    payload: {
        bannerSections,
    },
} as const);

export const createFetchBannersFailureAction = () => ({
    type: '@BANNER/FETCH_BANNERS_FAILURE',
} as const);


export const createFetchBannerTemplateAction = (templateUrl: string) => ({
    type: '@BANNER/FETCH_BANNER_TEMPLATE',
    payload: {
        templateUrl,
    },
} as const);

export const createFetchBannerTemplateSuccessAction = (templateUrl: string, templateContent: Nullable<string>) => ({
    type: '@BANNER/FETCH_BANNER_TEMPLATE_SUCCESS',
    payload: {
        templateUrl,
        templateContent,
    },
} as const);

export const createFetchBannerTemplateFailureAction = (templateUrl: string) => ({
    type: '@BANNER/FETCH_BANNER_TEMPLATE_FAILURE',
    payload: {
        templateUrl,
    },
} as const);

export type BannerAction =
    | ReturnType<typeof createFetchBannersAction>
    | ReturnType<typeof createFetchBannersSuccessAction>
    | ReturnType<typeof createFetchBannersFailureAction>
    | ReturnType<typeof createFetchBannerTemplateAction>
    | ReturnType<typeof createFetchBannerTemplateSuccessAction>
    | ReturnType<typeof createFetchBannerTemplateFailureAction>;
