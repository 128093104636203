import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLastValidatedDisclaimer } from 'core/services/disclaimer/disclaimerService';
import { Loading } from 'components/common/loading/Loading';
import { AppState } from 'store/store';

const defaultDisclaimer = '/disclaimer/page/SGM_ACCOUNT_CENTER';

const getLastValidatedContent = (app: AppState) => {
    return app.disclaimer.lastValidatedContent || {};
};

export const DisclaimerLastValidated = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const disclaimer = useSelector(getLastValidatedContent);

    useEffect(() => {
        dispatch<any>(getLastValidatedDisclaimer());
    }, [dispatch]);

    useEffect(() => {
        if (!disclaimer.isLoading && !disclaimer.hasError && disclaimer.data && !disclaimer.data.html) {
            // disclaimer was loaded without error but was not found
            navigate(defaultDisclaimer);
        }
    }, [disclaimer, navigate]);

    return <div className="d-flex mx-3">
        <aside className="col-lg-3 d-none d-lg-flex">
            <div className="disclaimer-page-background" />
        </aside>
        <div className="d-flex flex-column col-lg-6 px-lg-5 disclaimer-page-column overflow-hidden">
            <div className="d-flex flex-grow-1 overflow-auto">
                {disclaimer.isLoading && <div
                    className="p-2 p-lg-4 pb-5 pb-lg-5">
                    <Loading />
                </div>}
                {!disclaimer.isLoading && <div
                    className="p-2 p-lg-4 pb-5 pb-lg-5"
                    dangerouslySetInnerHTML={{
                        __html: disclaimer.data?.html || '',
                    }}
                />}
            </div>
        </div>
    </div>;
};
