import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHasAccessServices } from 'components/hooks/services/useBoardServices';
import { RequestAccess } from 'components/common/request-access/RequestAccess';
import { BookmarkButton, BookmarkStarButton } from '../../myServicesPage/components/bookmark/BookmarkButtons';
import { FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { BookmarkOrigin, ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { ShareButton } from './ShareButton';

type ServiceDescriptionButtonsProps = {
    sgmService: ServiceDto;
};

export const ServiceDescriptionButtons: React.FunctionComponent<ServiceDescriptionButtonsProps> = ({
    sgmService: {
        ServiceKey,
        Title,
        Url,
        PopsIdentifier,
        Share,
        PublicPageLink,
    },
}) => {
    const { hasAccessTo } = useHasAccessServices();
    const { t: translate } = useTranslation('service-description');
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    if (!Url || !ServiceKey) {
        return <></>;
    }

    const canAccess = !!ServiceKey && hasAccessTo(ServiceKey);

    return (
        <div className="d-flex flex-grow-1 mt-2">
            <>
                {canAccess ? (
                    <a
                        href={Url}
                        onClick={async () => await trackService(FeatureTracked.OpenService, serviceTracked)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-lg btn-primary me-1"
                    >{translate('service-description:access')}</a>
                ) : (
                    <RequestAccess
                        serviceKey={ServiceKey}
                        name={Title}
                        url={Url}
                        popsIdentifier={PopsIdentifier}
                        buttonClass="btn-outline-socgen"
                    />
                )}
                {PublicPageLink && <a
                    href={PublicPageLink}
                    onClick={async () => await trackService(FeatureTracked.OpenService, serviceTracked)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-lg btn-flat-secondary me-1"
                >{translate('service-description:learnMore')}</a>
                }
            </>
            <div className="flex-grow-1" />
            <div className="d-block d-lg-none">
                <div className="d-block d-lg-none">
                    <BookmarkStarButton
                        referenceId={ServiceKey}
                        origin={BookmarkOrigin.SgmService}
                    />
                </div>
            </div>
            <div className="d-none d-lg-block">
                <BookmarkButton
                    referenceId={ServiceKey}
                    origin={BookmarkOrigin.SgmService}
                />
                <ShareButton url={Url} share={Share} />
            </div>
        </div>
    );
};
