import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import { doMarkAsReadAll } from 'store/Notification/notificationThunk';
import { AppState } from 'store/store';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import classNames from 'classnames';

export const DismissAllButton = () => {
    const { producerCodeActive, isAllProducerActive } = useNotificationListFilter();
    const { t: translate } = useTranslation('my-notification');
    const dispatch = useDispatch();
    const isDismissingAll = useSelector((state: AppState) => state.notification.isDismissingAll);
    const mediaBreakpoint = useMediaBreakpoint();

    const handleDismissAll = async (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        const selectedProducerCode = isAllProducerActive ? null : producerCodeActive;
        await dispatch<any>(doMarkAsReadAll(selectedProducerCode));
    };

    const isMobileView = ['xs', 'sm'].includes(mediaBreakpoint);
    const btnClass = classNames('btn text-nowrap', {
        'btn-md btn-flat mx-2': isMobileView,
        'btn-lg btn-discreet-secondary ms-4': !isMobileView,
    });

    return (
        <>
            <button type="button" className={btnClass} onClick={handleDismissAll} disabled={isDismissingAll}>
                {translate('dismissAllBtn')} {isDismissingAll && (<div className="spinner spinner-sm text-info ms-2"></div>)}
            </button>
        </>
    );
};
