import React, { useState } from 'react';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { AccountCenter } from 'components/layout/accountCenter/AccountCenter';
import { ServiceboardHeaderMenuNavbar } from './ServiceboardHeaderMenuNavbar';
import sgmWordmark from 'static/images/sgm_wordmark.svg';
import socgenLogoCompactUrl from 'static/images/socgen_logo_compact.svg';
import { ServiceboardHeaderNavbar } from 'models/navigation';
import { SearchBar } from 'components/pages/myServicesPage/components/search/SearchBar';
import { SingleServiceModal } from 'components/pages/serviceDescription/SingleServiceModal';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import './ServiceboardHeader.scss';

type ServiceboardHeaderProps = {
    minimalHeader?: boolean
};

const isIE = (() => {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
})();

export const ServiceboardHeader: React.FC<ServiceboardHeaderProps> = ({ minimalHeader = false }) => {
    const mediaBreakpoint = useMediaBreakpoint();
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const { pathname } = useLocation();

    const navbarClassName = {
        'navbar-sm': ['xs', 'sm'].includes(mediaBreakpoint),
        'navbar-md': ['md', 'lg', 'xl'].includes(mediaBreakpoint),
        'navbar-lg': ['xxl'].includes(mediaBreakpoint),
    };

    const navbarHeaderClassName = {
        'overflow-hidden': ['xs', 'sm'].includes(mediaBreakpoint),
    };

    const toggleSearch = () => {
        const toggleSearchBar = !openSearchBar;
        setOpenSearchBar(toggleSearchBar);
        setDropdownMenuOpen(false);
    };

    const toggleDropdownMenu = () => {
        const toggleDropdownMenu = !dropdownMenuOpen;
        setDropdownMenuOpen(toggleDropdownMenu);
        setOpenSearchBar(false);
    };

    const menuActive: ServiceboardHeaderNavbar = pathname?.startsWith('/catalog') ? 'catalog' : 'myservices';

    return (
        <>
            <header className="sticky-top bg-lvl1">
                <nav className={classNames('h-100 navbar navbar-expand-lg d-flex align-items-center py-0', navbarClassName)}>
                    <div className={classNames('d-flex navbar-title-link-wrapper mt-1 mt-md-0 navbar-header-align align-items-center', navbarHeaderClassName)}>
                        <a href="/" className="navbar-title-link">
                            <div className="navbar-logo">
                                <img src={socgenLogoCompactUrl} alt="SG logo" />
                            </div>
                            <div className="navbar-title-divider"></div>
                            <div className="navbar-service-name">
                                <img src={sgmWordmark} alt="SG logo" />
                            </div>
                        </a>
                    </div>
                    <div className="d-flex pe-2 pe-lg-4 mt-1 mt-md-0 navbar-header-align align-items-center" style={{ position: 'absolute', right: '0', zIndex: 2 }}>
                        {!minimalHeader && (<button
                            className={classNames('navbar-menu-btn d-flex d-md-none btn btn-lg btn-icon btn-flat', {
                                'show': openSearchBar,
                            })}
                            onClick={toggleSearch}
                        >
                            <span className="icon icon-md">search</span>
                        </button>
                        )}
                        <AccountCenter />
                    </div>
                    <div
                        className="container-xl d-block ps-3 px-md-4 px-md-64px px-xl-5 px-xxl-3 align-items-baseline"
                        style={{ zIndex: 1, visibility: minimalHeader ? 'hidden' : 'visible' }}>
                        <div className="navbar-menu-btn border-0 ps-32px mt-1">
                            <button
                                className={classNames('navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center', navbarClassName, {
                                    'show': dropdownMenuOpen,
                                })}
                                type="button"
                                onClick={toggleDropdownMenu}>
                                <span className="icon">menu</span>
                            </button>
                        </div>
                        <div className="row">
                            <div className={classNames('col-12 col-md-6 col-xl-8 col-xxl-9 d-md-flex flex-md-nowrap align-items-center bg-lvl1 navbar-search collapse', { 'show': openSearchBar })}>
                                <SearchBar />
                                <SingleServiceModal />
                            </div>
                            <div className={classNames('col-12 col-md-auto col-xxl-3 d-md-flex bg-lvl1 collapse', { 'show': dropdownMenuOpen })}>
                                <ServiceboardHeaderMenuNavbar active={menuActive} />
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {isIE && <div style={{ height: '110px' }} />}
        </>
    );
};
