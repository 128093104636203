import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createEssentialServicesFetchFailureAction,
    createEssentialServicesFetchRequestAction,
    createEssentialServicesFetchSuccessAction,
} from '../../../store/actions/essentialServicesAction';
import { ServicesApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const getEssentialServices = (language?: string): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { logger },
    ) => {
        const { essentialService: { isLoading, items } } = state();
        if (isLoading || items) {
            return;
        }

        dispatch(createEssentialServicesFetchRequestAction());
        try {
            const essentialServicesModel = await ServicesApi.getEssentialServices(language);
            dispatch(createEssentialServicesFetchSuccessAction(essentialServicesModel?.Items ?? []));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createEssentialServicesFetchFailureAction());
        }
    };
};
