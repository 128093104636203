import React, { FC } from 'react';
import { CatalogBrowseCategoryTabs, useActiveCategoryKey } from './CatalogBrowseCategoryTabs';
import { CatalogBrowseMarkets } from './marketsLayout/CatalogBrowseMarkets';
import { CatalogBrowseCategory } from './categoryLayout/CatalogBrowseCategory';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { TopicTracked } from 'models/tracking';

export const CatalogBrowsePage: FC = () => {
    const { activeCategoryKey, activeSubCategoryKey, isMarketsCategory } = useActiveCategoryKey();

    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.CatalogBrowse }}
        >
            <CatalogBrowseCategoryTabs />
            {isMarketsCategory ? <CatalogBrowseMarkets subCategory={activeSubCategoryKey} /> : <CatalogBrowseCategory categoryKey={activeCategoryKey} subCategoryKey={activeSubCategoryKey} />}
        </ActionTrackingInfoProvider>
    );
};