import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { CookiesRefused } from './CookieRefused';

const getVideoUrl = (videoId: string) =>
    `https://socgen.qumucloud.com/view/${videoId}?autoplay=false`;

type VideoProps = {
    videoId: string;
};

const retrieveQumuConsent = (state: AppState) => state.consent.qumu;

export const Video: React.FunctionComponent<VideoProps> = ({ videoId }) => {
    const { t: translate } = useTranslation('service-description');
    const qumuConsent = useSelector(retrieveQumuConsent);
    if (!qumuConsent) {
        return <CookiesRefused />;
    }
    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                role="presentation"
                title={translate('service-description:video')}
                className="embed-responsive-item"
                scrolling="no"
                src={getVideoUrl(videoId)}
                frameBorder={0}
                allowFullScreen={true}
            >
                {translate('service-description:noIframe')}
            </iframe>
        </div>
    );
};
