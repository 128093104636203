import { useSelector } from 'react-redux';
import { MyAccountSetting } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { AppState } from 'store/store';

const getMyAccountSetting = (state: AppState) => state.myAccountSetting;
type UseMyAccountResponse = {
    setting: Nullable<MyAccountSetting>;    
    isLoading: boolean;    
};

export const useMyAccountSetting = (): UseMyAccountResponse => {
    const myAccountSetting = useSelector(getMyAccountSetting);
    return {
        ...myAccountSetting,
    };
};