import React, { FC } from 'react';
import image404 from 'static/images/404.svg';
import { Error } from './Error';

export interface INotFoundPageProps {
    buttonLabel?: string;
    onButtonClick?: () => void;
}

const NotFoundPage: FC<INotFoundPageProps> = ({ buttonLabel, onButtonClick }) =>
    <Error
        code={404}
        title="Page not found."
        message="Sorry, we couldn't find this page."
        imageUrl={image404}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
    />;

export default NotFoundPage;