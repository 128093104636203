export const ConsentUpdateQumuActionType = '@CONSENT/UPDATE_QUMU';

export type ConsentUpdateQumuRequestAction = ReduxAction<
    typeof ConsentUpdateQumuActionType,
    { payload: boolean }
>;

export function consentUpdateQumuAction(
    consent: boolean,
): ConsentUpdateQumuRequestAction {
    return {
        type: ConsentUpdateQumuActionType,
        payload: consent,
    };
}

export type ConsentAction = ConsentUpdateQumuRequestAction;
