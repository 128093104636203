import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createRecommendationsFetchFailureAction,
    createRecommendationsFetchRequestAction,
    createRecommendationsFetchSuccessAction,
} from '../../../store/actions/recommendationsAction';
import { ServicesApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const getRecommendations = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { logger },
    ) => {
        dispatch(createRecommendationsFetchRequestAction());
        try {
            const recommendedServices = await ServicesApi.getRecommendedServices();
            dispatch(createRecommendationsFetchSuccessAction(recommendedServices));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createRecommendationsFetchFailureAction());
        }
    };
};
