import React, { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { GetNotificationResponse, NotificationKind } from 'services/ApiService/Notification/NotificationApiClient';
import { useTranslation } from 'react-i18next';
import { useDate } from 'services/date';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { ProducerItem } from './Producer/ProducerItem';
import { useDispatch } from 'react-redux';
import { internalMarkAsRead } from 'store/Notification/notificationThunk';

import './NotificationItem.scss';

interface INotificationItemProps {
    notif: GetNotificationResponse;
}
export const NotificationItem: FC<INotificationItemProps> = ({ notif }) => {
    const mediaBreakpoint = useMediaBreakpoint();
    const isMobileView = ['xs', 'sm'].includes(mediaBreakpoint);

    const notifWrapperClass = classNames(
        'notification-item stretched-link-parent border border-opacity-40', {
        'mobile-view d-flex flex-wrap list-group-item': isMobileView,
        'desktop-view': !isMobileView,
        'bg-lvl2': notif.isRead || notif.isExpired,
    });

    const readClass = {
        wrapper: '',
        inner: 'mt-2 mt-md-3',
    };

    const actionClass = {
        wrapper: classNames({
            'd-flex order-3 ': isMobileView,
            'ps-3': !isMobileView,
        }),
        inner: 'mt-2',
    };

    const summaryClass = {
        wrapper: classNames(
            'notification-item-summary', {
            'ps-3 pe-2 pt-2 order-2 overflow-hidden': isMobileView,
            'ps-3 w-auto': !isMobileView,
        }),
        inner: classNames(
            'notification-item-summary-inner', {
            'd-flex align-items-center': !isMobileView,
        }),
    };

    const producerClass = {
        wrapper: classNames({
            'd-flex align-items-center ps-2': isMobileView,
            'ps-3 pe-4': !isMobileView,
        }),
        inner: classNames({
            'mt-2': !isMobileView,
        }),
    };

    const durationClass = {
        wrapper: classNames({
            'd-flex flex-grow-1 justify-content-end fs-12 ps-2': isMobileView,
        }),
        inner: classNames('notification-item-duration d-flex align-items-center justify-content-end', {
            'mt-2': !isMobileView,
        }),
    };

    return (
        <article className={notifWrapperClass}>
            <div className={readClass.wrapper}>
                <div className={readClass.inner}>
                    <UnreadIcon isRead={notif.isRead} isExpired={notif.isExpired} />
                </div>
            </div>
            <div className={actionClass.wrapper}>
                <div className={actionClass.inner}>
                    <ActionToDoIcon kind={notif.kind} isExpired={notif.isExpired} />
                </div>
            </div>
            <div className={summaryClass.wrapper}>
                <div className={summaryClass.inner}>
                    <SummaryElement notif={notif} />
                </div>
            </div>
            <div className={producerClass.wrapper}>
                <div className={producerClass.inner}>
                    <ProducerItem producer={notif.producer} className={classNames('fs-12', {
                        'text-secondary': notif.isExpired,
                    })} />
                </div>
            </div>
            <div className={durationClass.wrapper}>
                <div className={durationClass.inner}>
                    <DurationElement createdDate={notif.createdDate} />
                </div>
            </div>
        </article>
    );
};

interface IUnreadIconProps {
    isRead: boolean;
    isExpired: boolean;
}
const UnreadIcon: FC<IUnreadIconProps> = ({ isRead, isExpired }) => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextUnread');
    const shouldShow = !isRead && !isExpired;

    return (
        <DefaultTooltip
            text={ariaTextIcon}
            placement="left"
            enabled={shouldShow}
        >
            <div
                className={classNames('stretched-link-exclude', {
                    'invisible': !shouldShow,
                })}
                aria-label={ariaTextIcon}
            >
                <div className="bg-info border-radius-circle" style={{
                    height: '8px',
                    width: '8px',
                }} />
            </div>
        </DefaultTooltip>
    );
};

interface IActionToDoProps {
    kind: NotificationKind;
    isExpired: boolean;
}
const ActionToDoIcon: FC<IActionToDoProps> = ({ kind, isExpired }) => {
    const { t: translate } = useTranslation('my-notification');
    const shouldShow = kind === NotificationKind.Action || isExpired;
    const tooltipText = isExpired ? translate('tooltipIsExpired') : translate('tooltipActionRequired');

    return (
        <DefaultTooltip
            text={tooltipText}
            placement="left"
            enabled={shouldShow}
        >
            <span
                className={classNames('bg-opacity-10 border-radius-circle flex-center p-1 stretched-link-exclude', {
                    'invisible': !shouldShow,
                    'text-warning bg-warning': !isExpired,
                    'text-secondary bg-secondary': isExpired,
                })}
                aria-label={translate('ariaTextRequiresAction')}
            >
                <i className="icon icon-xs lh-1">priority_high</i>
            </span>
        </DefaultTooltip>
    );
};

const SummaryElement: FC<INotificationItemProps> = ({ notif }) => {
    const { category, payload, url, isExpired } = notif;
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch<any>(internalMarkAsRead(notif.id));
    };

    const handleAuxClick: MouseEventHandler = (e) => {
        if (e.button === 1) {
            // Scroll click
            handleClick();
        }
    };

    return (
        <div className={classNames({ 'text-secondary': isExpired })}>
            <div className="fw-semibold text-truncate">{category}</div>
            <div className="notification-item-summary-content fw-normal text-break">
                {isExpired ? payload : <a
                    href={url}
                    rel="noopener noreferrer"
                    className="stretched-link text-primary"
                    style={{ textDecoration: 'none' }}
                    onClick={handleClick}
                    onAuxClick={handleAuxClick}
                >
                    {payload}
                </a>}
            </div>
        </div>
    );
};

interface IDurationElementProps {
    createdDate: Date | undefined;
}
const DurationElement: FC<IDurationElementProps> = ({ createdDate }) => {
    const { displayDate } = useDate();

    return (
        <span className="text-secondary font-weight-medium text-nowrap">
            {displayDate(createdDate)}
        </span>
    );
};
