import { caseInsensitiveEquals } from 'core/utils/service-matchers';
import { useSelector } from 'react-redux';
import { HighlightLayoutPage, MyHighlightLayoutDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { AppState } from 'store/store';

const getLayouts = (state: AppState): Nullable<MyHighlightLayoutDto[]> => state.highlightLayout.layouts;

type UseHighlightLayoutResponse = {
    getLayoutsByPage: (page: HighlightLayoutPage) => MyHighlightLayoutDto[],
    getLayoutsByCategoryKey: (categoryKey: string, page: HighlightLayoutPage) => MyHighlightLayoutDto[],
};

export const useHighlightLayout = (): UseHighlightLayoutResponse => {
    const layouts = useSelector(getLayouts) || [];
    const getLayoutsByPage = (page: HighlightLayoutPage) => layouts?.filter(x => caseInsensitiveEquals(page, x.page)) || [];
    const getLayoutsByCategoryKey = (categoryKey: string, page: HighlightLayoutPage) =>
        layouts?.filter(x => caseInsensitiveEquals(categoryKey, x.categoryKey) && caseInsensitiveEquals(page, x.page)) || [];

    return {
        getLayoutsByPage,
        getLayoutsByCategoryKey,
    };
};
