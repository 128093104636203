import { Signature } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const createFetchSignatureBodyAction = () => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_BODY',
} as const);

export const createFetchSignatureBodySuccessAction = (signature: Nullable<string>) => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_BODY_SUCCESS',
    payload: {
        signature,
    },
} as const);

export const createFetchSignatureBodyFailureAction = () => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_BODY_FAILURE',
} as const);

export const createFetchSignatureInfoAction = () => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_INFO',
} as const);

export const createFetchSignatureInfoSuccessAction = (info: Nullable<Signature>) => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_INFO_SUCCESS',
    payload: {
        info,
    },
} as const);

export const createFetchSignatureInfoFailureAction = () => ({
    type: '@SIGNATURE/FETCH_SIGNATURE_INFO_FAILURE',
} as const);

export const createSaveSignatureInfoAction = () => ({
    type: '@SIGNATURE/SAVE_SIGNATURE_INFO',
} as const);

export const createSaveSignatureInfoSuccessAction = () => ({
    type: '@SIGNATURE/SAVE_SIGNATURE_INFO_SUCCESS',
} as const);

export const createSaveSignatureInfoFailureAction = () => ({
    type: '@SIGNATURE/SAVE_SIGNATURE_INFO_FAILURE',
} as const);

export type SignatureAction =
    | ReturnType<typeof createFetchSignatureBodyAction>
    | ReturnType<typeof createFetchSignatureBodySuccessAction>
    | ReturnType<typeof createFetchSignatureBodyFailureAction>
    | ReturnType<typeof createFetchSignatureInfoAction>
    | ReturnType<typeof createFetchSignatureInfoSuccessAction>
    | ReturnType<typeof createFetchSignatureInfoFailureAction>
    | ReturnType<typeof createSaveSignatureInfoAction>
    | ReturnType<typeof createSaveSignatureInfoSuccessAction>
    | ReturnType<typeof createSaveSignatureInfoFailureAction>;