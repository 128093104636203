import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

type UseExternalServiceResponse = {
    showOnlyExternalService: boolean;
};

export const useExternalService = (): UseExternalServiceResponse => {
    const showOnlyExternalService = useSelector(
        (state: AppState) => state.externalService?.showOnly,
    ) || false;

    return {
        showOnlyExternalService,
    };
};
