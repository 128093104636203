import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { ISignatureForm } from './EditSignature';
import { useBanners } from 'components/hooks/banner/useBanner';
import classNames from 'classnames';
import { handleA11y } from 'core/utils/a11y';
import { getBanners } from 'core/services/banner/bannerService';
import { useDispatch } from 'react-redux';
import { Section } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type BannerPartSignatureProps = {
    control: Control<ISignatureForm>;
    setValue: UseFormSetValue<ISignatureForm>;
    isSubmitting: boolean;
}

const getDefaultBannerSection = (bannerSections: Section[], bannerId: Nullable<number>): Nullable<Section> => {
    const section = bannerSections.find(x => x.Banners?.find(b => b.BannerId === bannerId));
    return section || bannerSections?.at(0);
};

export const BannerPartSignature: FC<BannerPartSignatureProps> = ({ control, setValue, isSubmitting }) => {
    const dispatch = useDispatch();
    const { bannerSections } = useBanners();

    useEffect(() => {
        dispatch<any>(getBanners());
    }, []);

    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({ name: 'bannerId', control });

    const [section, setSection] = useState(getDefaultBannerSection(bannerSections, value));
    const bannerGroup = section?.Banners?.sort(x => x.Rank || 0) || [];

    const disabled = isSubmitting;

    const handleChangeSection = (newValue: Section) => () => setSection(newValue);
    const handleChangeBanner = (newValue: Nullable<number>) => () => {
        if (disabled) {
            return;
        }
        const banner = bannerGroup.find(x => x.BannerId === newValue);
        onChange(newValue || 0);
        setValue('templateUrl', banner?.TemplateUrl || '');
        setValue('pictureUrl', banner?.PictureUrl || '');
        setValue('bannerLink', banner?.BannerLink || '');
        setValue('templateRessourceKey', banner?.TemplateRessourceKey || '');
    };

    if (!bannerSections?.length) {
        return null;
    }

    const cardClassName = (bannerId: number | undefined) => classNames('card', 'card-bordering-info', {
        active: bannerId === value,
        'pe-none': disabled,
    });

    const titleCardClassName = (bannerId: number | undefined) => classNames('fw-bold', ' text-secondary', {
        'text-info': bannerId === value,
    });

    return <>
        <ul className="nav nav-underline mb-3">
            {bannerSections.sort(x => x.SectionRank || 0).map((x) =>
                <li className="nav-item" key={x.SectionName}>
                    <span
                        className={classNames('nav-link', { active: x.SectionName === section?.SectionName })}
                        onClick={handleA11y(handleChangeSection(x))}
                        onKeyUp={handleA11y(handleChangeSection(x))}
                        tabIndex={0}
                        role="button"
                    >
                        {x.SectionName}
                    </span>
                </li>,
            )}
        </ul>
        {error && <div className="mb-3 alert alert-discreet-danger">{error.message}</div>}
        <div className="row g-3 mb-3">
            {bannerGroup?.map((x) =>
                <div key={x.BannerId} className="col-4">
                    <div className={titleCardClassName(x.BannerId)}>
                        {x.Name}
                    </div>
                    <div
                        className={cardClassName(x.BannerId)}
                        style={{ minHeight: 40 }}
                        onClick={handleA11y(handleChangeBanner(x.BannerId))}
                        onKeyUp={handleA11y(handleChangeBanner(x.BannerId))}
                        tabIndex={0}
                        role="button"
                    >
                        <img src={x.PictureUrl || ''} width="100%" alt={x.Name || ''} />
                    </div>
                </div>)}
        </div>
    </>;
};