import { MyAccountPersonalInformation } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const MyAccountPersonalInformationFetchRequestActionType =
    '@MY_ACCOUNT_PERSONAL_INFORMATION/FETCH_REQUEST';
export const MyAccountPersonalInformationFetchSuccessActionType =
    '@MY_ACCOUNT_PERSONAL_INFORMATION/FETCH_SUCCESS';
export const MyAccountPersonalInformationFetchFailureActionType =
    '@MY_ACCOUNT_PERSONAL_INFORMATION/FETCH_FAILURE';

export type MyAccountPersonalInformationFetchRequestAction = ReduxAction<
    typeof MyAccountPersonalInformationFetchRequestActionType
>;
export type MyAccountPersonalInformationFetchSuccessAction = ReduxAction<
    typeof MyAccountPersonalInformationFetchSuccessActionType,
    { payload: Nullable<MyAccountPersonalInformation> }
>;
export type MyAccountPersonalInformationFetchFailureAction = ReduxAction<
    typeof MyAccountPersonalInformationFetchFailureActionType
>;

export const createMyAccountPersonalInformationFetchRequestAction = (): MyAccountPersonalInformationFetchRequestAction => ({
    type: MyAccountPersonalInformationFetchRequestActionType,
});

export function createMyAccountPersonalInformationFetchSuccessAction(
    myAccountPersonalInformationMyAccountPersonalInformation: Nullable<MyAccountPersonalInformation>,
): MyAccountPersonalInformationFetchSuccessAction {
    return {
        type: MyAccountPersonalInformationFetchSuccessActionType,
        payload: myAccountPersonalInformationMyAccountPersonalInformation,
    };
}

export const createMyAccountPersonalInformationFetchFailureAction = (): MyAccountPersonalInformationFetchFailureAction => ({
    type: MyAccountPersonalInformationFetchFailureActionType,
});

export type MyAccountPersonalInformationAction =
    | MyAccountPersonalInformationFetchRequestAction
    | MyAccountPersonalInformationFetchSuccessAction
    | MyAccountPersonalInformationFetchFailureAction;
