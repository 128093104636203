import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { Loading } from 'components/common/loading/Loading';
import {
    CloseButton,
    CloseButtonProps,
} from 'components/common/modal/CloseButton';
import { ServiceTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useCustomParamsFromUrl } from 'components/hooks/UseCustomParams';
import { TopicTracked, ServiceTracked } from 'models/tracking';
import { ServiceLogo } from '../../common/logo/ServiceLogo';
import { ScreenshotCarousel } from './body/carousel/ScreenshotCarousel';
import { ServiceDescriptionFeatures } from './body/ServiceDescriptionFeatures';
import { ServiceDescriptionDocumentationPages } from './body/ServiceDescriptionDocumentationPages';
import { ServiceDescriptionMainText } from './body/ServiceDescriptionMainText';
import { ServiceDescriptionButtons } from './header/ServiceDescriptionButtons';
import { ServiceDescriptionTitle } from './header/ServiceDescriptionTitle';
import { useBoardServiceForDetail } from 'components/hooks/services/useBoardServices';
import { BookmarkOrigin, ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type ServiceDescriptionProps = {
    backLink: string;
};

export const ServiceDescription: React.FunctionComponent<
    ServiceDescriptionProps
> = ({ backLink }) => {
    const { pathname } = useLocation();
    const backPath = pathname.split('/service/')[0];
    const match = useCustomParamsFromUrl(`${backPath}/service/:serviceKey`);
    const navigate = useNavigate();
    const { loading, getSgmServiceByKey } = useBoardServiceForDetail();

    const sgmService = getSgmServiceByKey(match?.serviceKey ?? '');

    const closeModal = () => {
        navigate(backLink);
    };

    return (
        <Modal
            isOpen={true}
            className="modal-dialog modal-dialog-centered modal-lg"
            aria-labelledby="serviceDescriptionTitle"
        >
            {loading ? (
                <LoadingModal closeModal={closeModal} />
            ) : sgmService ? (
                <DescriptionModal
                    sgmService={sgmService}
                    closeModal={closeModal}
                />
            ) : (
                <NotFoundModal closeModal={closeModal} />
            )}
        </Modal>
    );
};

const LoadingModal: React.FunctionComponent<CloseButtonProps> = ({
    closeModal,
}) => (
    <>
        <div className="modal-header">
            <Loading />
            <CloseButton closeModal={closeModal} />
        </div>
        <ModalBody />
    </>
);

const NotFoundModal: React.FunctionComponent<CloseButtonProps> = ({
    closeModal,
}) => {
    const { t: translate } = useTranslation('service-description');
    return (
        <>
            <div className="modal-header">
                <h1 className="modal-title h3" id="serviceDescriptionTitle">
                    {translate('service-description:notFoundTitle')}
                </h1>
                <CloseButton closeModal={closeModal} />
            </div>
            <ModalBody>
                {translate('service-description:notFoundBody')}
            </ModalBody>
        </>
    );
};

type ServiceProps = {
    sgmService: ServiceDto;
};

type DescriptionProps = ServiceProps & CloseButtonProps;

const DescriptionModal: React.FunctionComponent<DescriptionProps> = ({
    sgmService,
    closeModal,
}) => {
    const serviceTracked: ServiceTracked = {
        topic: TopicTracked.ServiceDetail,
        service: sgmService,
        origin: BookmarkOrigin.SgmService,
    };

    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTracked}>
            <div className="modal-header">
                <DescriptionHeader
                    sgmService={sgmService}
                    closeModal={closeModal}
                />
            </div>
            <ModalBody>
                <DescriptionBody sgmService={sgmService} />
            </ModalBody>
        </ServiceTrackingInfoProvider>
    );
};

const DescriptionHeader: React.FunctionComponent<DescriptionProps> = ({
    sgmService,
    closeModal,
}) => {
    const { Title, Category, Initials, IsInternal } = sgmService;

    return (
        <div className="d-flex w-100">
            <div className="flex-0">
                <ServiceLogo initials={Initials} size={120} />
            </div>
            <div className="flex-grow-1 ms-4 d-flex flex-column justify-content-between">
                <div className="flex-grow-1 d-flex justify-content-between align-items-start">
                    <ServiceDescriptionTitle name={Title} categoryKey={Category?.Key} isInternal={IsInternal} />
                    <CloseButton closeModal={closeModal} />
                </div>
                <div className="flex-grow-1 d-flex justify-content-between align-items-end">
                    <ServiceDescriptionButtons
                        sgmService={sgmService}
                    />
                </div>
            </div>
        </div>
    );
};

const DescriptionBody: React.FunctionComponent<ServiceProps> = ({
    sgmService: { Images, QumuId, Features, DescriptionText, DocumentationPages },
}) => {
    const videoId = QumuId;
    const hasCarousel = (Images && Images?.length > 0) || videoId;
    return (
        <div className="d-flex flex-column">
            {hasCarousel && (
                <ScreenshotCarousel
                    images={Images || []}
                    videoId={videoId || null}
                />
            )}
            <ServiceDescriptionMainText text={DescriptionText} />
            <ServiceDescriptionFeatures features={Features} />
            <ServiceDescriptionDocumentationPages documentationPages={DocumentationPages} />
        </div>
    );
};
