import classNames from 'classnames';
import { useNotificationCountByProducer } from 'components/hooks/Notification/useNotificationCountByProducer';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ReadStatus } from 'services/ApiService/Notification/NotificationApiClient';
import { DismissAllButton } from './DismissAllButton';

interface ISortOption {
    key: ReadStatus;
    label: string;
}

export const NotificationFilter: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const navigate = useNavigate();
    const { producerCodeActive, readStatusActive, isAllProducerActive, getFilterNavigatePath } = useNotificationListFilter();
    const { allUnreadTotalCount, getUnreadCount } = useNotificationCountByProducer();

    const sortOptions: ISortOption[] = [
        { key: ReadStatus.Any, label: translate('sortAll') },
        { key: ReadStatus.Unread, label: translate('sortUnread') },
        { key: ReadStatus.Dismissed, label: translate('sortDismissed') },
    ];

    const handleReadStatusSort = (op: ISortOption) => {
        navigate(getFilterNavigatePath(producerCodeActive, op.key));
    };

    const selectedUnreadCount = isAllProducerActive ? allUnreadTotalCount : getUnreadCount(producerCodeActive);

    return (
        <>
            <div className="ms-xl-auto d-none d-md-flex">
                <div className="btn-group btn-group-toggle my-auto" data-toggle="buttons" role="group" aria-label="Single select toggle">
                    {sortOptions.map((op) => {
                        const id = `sortByReadStatus_${op.key}`;
                        return (<Fragment key={id}>
                            <input className="btn-check"
                                type="radio"
                                name="sortByReadStatus"
                                id={id}
                                readOnly
                                checked={readStatusActive === op.key}
                                value={op.key}
                                onClick={() => handleReadStatusSort(op)}
                            />
                            <label className="btn btn-lg btn-toggle-primary text-nowrap" htmlFor={id}>
                                {op.label}
                                {op.key === ReadStatus.Unread && (
                                    <div className="badge badge-discreet-info rounded-pill ms-2">
                                        {selectedUnreadCount}
                                    </div>
                                )}
                            </label>
                        </Fragment>);
                    })}
                </div>
            </div>
            <div className="d-flex d-md-none">
                <ul className="nav nav-underline ms-3" aria-label="Single select toggle">
                    {sortOptions.map(op => {
                        const id = `sortByReadStatusTab_${op.key}`;
                        return <li key={id} className="nav-item">
                            <Link
                                to={getFilterNavigatePath(producerCodeActive, op.key)}
                                className={classNames('nav-link nav-link-lg text-nowrap', { 'active': readStatusActive === op.key })}
                            >
                                {op.label}
                                {op.key === ReadStatus.Unread && (
                                    <div className="badge badge-discreet-info rounded-pill ms-1">
                                        {selectedUnreadCount}
                                    </div>
                                )}
                            </Link>
                        </li>;
                    })}
                </ul>
            </div>
            <DismissAllButton />
        </>
    );
};
