import { combineReducers } from 'redux';
import {
    AuthenticatedUserFetchSuccessActionType,
    AuthenticatedUserFetchRequestActionType,
    AuthenticatedUserFetchFailureActionType,
} from '../actions/authenticatedUserAction';
import { ActionType } from 'store/actions/ActionType';
import { LoggedUser } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const user = (
    state: Nullable<LoggedUser> = null,
    action: ActionType,
) => {
    if (action.type === AuthenticatedUserFetchSuccessActionType) {
        return action.payload;
    }
    return state;
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case AuthenticatedUserFetchRequestActionType: {
            return true;
        }
        case AuthenticatedUserFetchFailureActionType:
        case AuthenticatedUserFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    user,
    isLoading,
});
