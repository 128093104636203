import classNames from 'classnames';
import React, { useId } from 'react';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';

type TextAreaFormProps<T extends FieldValues> = {
    name: FieldPath<T>;
    label?: string;
    placeholder?: string;
    rows?: number;
    className?: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const TextAreaForm = <T extends FieldValues>({ name, label, placeholder, rows = 4, className, register, errors, disabled }: TextAreaFormProps<T>): JSX.Element => {
    const inputId = useId();
    const error = errors[name];

    const rootClassName = classNames(className, 'input-container');

    const inputClassName = classNames('form-control', {
        'is-invalid': error,
    });

    const labelClassName = classNames({
        'form-check-label': false,
    });

    return <div className={rootClassName}>
        {label && <label
            htmlFor={inputId}
            className={labelClassName}
        >
            {label}
        </label>}
        <textarea
            id={inputId}
            placeholder={placeholder}
            rows={rows}
            {...register(name)}
            className={inputClassName}
            disabled={disabled}
        />
        {error && <div className="invalid-feedback">
            {error.message as string}
        </div>}
    </div>;
};