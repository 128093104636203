import { MyAccountSetting } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const MyAccountSettingFetchRequestActionType =
    '@MY_ACCOUNT_SETTING/FETCH_REQUEST';
export const MyAccountSettingFetchSuccessActionType =
    '@MY_ACCOUNT_SETTING/FETCH_SUCCESS';
export const MyAccountSettingFetchFailureActionType =
    '@MY_ACCOUNT_SETTING/FETCH_FAILURE';

export type MyAccountSettingFetchRequestAction = ReduxAction<
    typeof MyAccountSettingFetchRequestActionType
>;
export type MyAccountSettingFetchSuccessAction = ReduxAction<
    typeof MyAccountSettingFetchSuccessActionType,
    { payload: Nullable<MyAccountSetting> }
>;
export type MyAccountSettingFetchFailureAction = ReduxAction<
    typeof MyAccountSettingFetchFailureActionType
>;

export const createMyAccountSettingFetchRequestAction = (): MyAccountSettingFetchRequestAction => ({
    type: MyAccountSettingFetchRequestActionType,
});

export function createMyAccountSettingFetchSuccessAction(
    myAccountSetting: Nullable<MyAccountSetting>,
): MyAccountSettingFetchSuccessAction {
    return {
        type: MyAccountSettingFetchSuccessActionType,
        payload: myAccountSetting,
    };
}

export const createMyAccountSettingFetchFailureAction = (): MyAccountSettingFetchFailureAction => ({
    type: MyAccountSettingFetchFailureActionType,
});

export type MyAccountSettingAction =
    | MyAccountSettingFetchRequestAction
    | MyAccountSettingFetchSuccessAction
    | MyAccountSettingFetchFailureAction;
