import { GetSearchResponse, SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

export const createSetSearchTermAction = (searchTerm: Nullable<string>) => ({
    type: '@SEARCH/TERM',
    payload: {
        searchTerm,
    },
} as const);

export const createSearchItemFetchRequestAction = (requestKey: string) => ({
    type: '@SEARCH/ITEM/FETCH_REQUEST',
    payload: {
        requestKey,
    },
} as const);

export const createSearchItemFetchSuccessAction = (requestKey: string, response: GetSearchResponse, searchResultTypes: SearchResultType[]) => ({
    type: '@SEARCH/ITEM/FETCH_SUCCESS',
    payload: {
        requestKey,
        response,
        searchResultTypes,
    },
} as const);

export const createSearchItemFetchFailureAction = (requestKey: string, searchResultTypes: SearchResultType[]) => ({
    type: '@SEARCH/ITEM/FETCH_FAILURE',
    payload: {
        requestKey,
        searchResultTypes,
    },
} as const);

export type SgmSearchAction =
    | ReturnType<typeof createSetSearchTermAction>
    | ReturnType<typeof createSearchItemFetchRequestAction>
    | ReturnType<typeof createSearchItemFetchSuccessAction>
    | ReturnType<typeof createSearchItemFetchFailureAction>;
