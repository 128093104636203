import React from 'react';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { extractCategoryKey, useCategories } from 'components/hooks/services/useCategories';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { CATALOG_BROWSE_PATH } from 'models/constants';
import { Category } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { FeatureTracked } from 'models/tracking';
import { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface QuicksearchStartTypingProps {
    onCategoryClick: () => void;
}

export const QuicksearchStartTyping: FC<QuicksearchStartTypingProps> = ({ onCategoryClick }) => {
    const categories = useCategories();
    const { canSearchPersonsAndCompanies } = useApplicationContext();
    const { t: translate } = useTranslation('service-list');
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();

    const trackSuggestedCategoryCallback = useCallback(async (suggestedCategory: Category) => {
        await trackAction(FeatureTracked.OpenSuggestedCategory, {
            topic: actionTrackedInfo.topic,
            parameters: {
                'category-key': suggestedCategory.key,
                'category-label': suggestedCategory.label,
            },
        });
    }, [trackAction, actionTrackedInfo]);

    const handleCategoryClick = (category: Category) => {
        trackSuggestedCategoryCallback(category);
        onCategoryClick();
    };

    return (
        <div
            className="position-absolute overflow-auto w-100 p-3 bg-lvl1 border shadow-max"
            style={{ maxHeight: '80vh' }}
        >
            {categories.length > 0 && (
                <h4 className="quicksearch-title text-secondary">
                    {translate('suggestedCategories')}
                </h4>
            )}
            {categories.map((x) => (
                <Link
                    key={x.key}
                    to={`${CATALOG_BROWSE_PATH}/${extractCategoryKey(x.key)}`}
                    className="badge badge-default badge-lg badge-prepend-square me-1 mb-2 py-2"
                    onClick={() => handleCategoryClick(x)}
                >
                    {x.label}
                </Link>
            ))}
            <div className="flex my-4 text-secondary">
                <div className="text-center">
                    <i className="icon icon-xl">search</i>
                </div>
                <div className="text-center">
                    {canSearchPersonsAndCompanies ? (
                        <>
                            <p className="h4">{translate('searchAnything')}</p>
                            <p>{translate('searchAnythingSubtitle')}</p>
                        </>
                    ) : (
                        <>
                            <p className="h4">
                                {translate('searchServicesPlaceHolder')}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
