import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NotificationSettingsLink: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextOpenInNewTab');

    return (
        <div>
            <Link className="d-none d-lg-inline-flex btn btn-flat-secondary btn-icon-start m-3"
                to="/myaccount/settings"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={ariaTextIcon}
                title={ariaTextIcon}
            >
                <i className="icon">open_in_new</i>
                {translate('linkNotificationSettings')}
            </Link>
            <Link className="d-lg-none btn btn-flat-secondary mt-md-2"
                to="/myaccount/settings"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={ariaTextIcon}
                title={ariaTextIcon}
                style={{ width: 48, height: 48 }}
            >
                <i className="icon icon-sm">settings</i>
            </Link>
        </div>
    );
};