import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useLearnMoreCardTranslation } from 'components/hooks/translation/useLearnMoreCardTranslation';
import { markAsReadLearnMore } from 'core/services/learnMore/availableLearnMore';
import { EXTERNAL_LINK_PROPS } from 'core/utils/link';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { LearnMoreItem } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type LearnMoreLinkProps = PropsWithChildren & {
    item: LearnMoreItem;
    style?: React.CSSProperties;
};

export const LearnMoreLink: FC<LearnMoreLinkProps> = ({
    item,
    style,
    children,
}) => {
    const { i18n: { language } } = useTranslation();
    const { translate } = useLearnMoreCardTranslation();
    const dispatch = useDispatch();
    const { trackAction } = useServiceTracking();
    const { pathname } = useLocation();

    const isNewsLink = !!item.publication_head_id;

    const targetUrl = isNewsLink
        ? `${pathname}/news/${item.id}/${language}`
        : translate(item.content_url, item.content_url_fr) || '/';

    const markAsRead = async () => {
        if (item.id) {
            await dispatch<any>(markAsReadLearnMore(item.id));
        }
        await trackAction(FeatureTracked.OpenStory, {
            topic: TopicTracked.Learnmore,
            parameters: {
                'story-id': item.id || 0,
                'story-title': item.title || '',
                'is-internal': !item.show_external || false,
            },
        });
    };

    const linkProps = {
        onClick: markAsRead,
        className: 'card card-raising h-100 pointer',
        style,
    };

    if (isNewsLink) {
        return (
            <Link
                to={targetUrl}
                {...linkProps}
            >{children}</Link>
        );
    }

    return (
        <a
            href={targetUrl}
            {...EXTERNAL_LINK_PROPS}
            {...linkProps}
        >{children}</a>
    );
};
