import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useServiceDescriptionLink } from 'components/hooks/services/useServiceDescriptionLink';
import { FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { useBoardServiceForDetail } from 'components/hooks/services/useBoardServices';
import { ignoreRedirect } from 'core/services/redirectService';

import './RecommendationDetailsLink.scss';

type RecommendationDetailsLinkProps = {
    serviceKey: Nullable<string>;
};

export const RecommendationDetailsLink: React.FunctionComponent<RecommendationDetailsLinkProps> = ({ serviceKey }) => {
    const descriptionUrl = useServiceDescriptionLink(serviceKey);
    const { t: translate } = useTranslation('service-list');
    const { hasSgmServiceKey } = useBoardServiceForDetail();
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    if (!serviceKey) { return null; }

    const existSgmServiceKey = hasSgmServiceKey(serviceKey);
    if (!existSgmServiceKey) { return null; }

    return (
        <div
            className="position-absolute d-flex"
            style={{
                bottom: 0,
                right: 0,
            }}
            onClick={ignoreRedirect}
            aria-hidden
        >
            <div className="link-fade-shim">&nbsp;</div>
            <Link
                to={descriptionUrl}
                onClick={async () => await trackService(FeatureTracked.ViewServiceDetails, serviceTracked)}
                className="bg-white px-1 text-link text-secondary fw-semibold"
            >
                {translate('service-list:viewDetails')}
            </Link>
        </div>
    );
};
