import { BookmarkDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const BookmarkFetchRequestActionType = '@BOOKMARK/FETCH_REQUEST';
export const BookmarkFetchSuccessActionType = '@BOOKMARK/FETCH_SUCCESS';
export const BookmarkFetchFailureActionType = '@BOOKMARK/FETCH_FAILURE';

export const BookmarkCreateRequestActionType = '@BOOKMARK/CREATE_REQUEST';
export const BookmarkCreateSuccessActionType = '@BOOKMARK/CREATE_SUCCESS';
export const BookmarkCreateFailureActionType = '@BOOKMARK/CREATE_FAILURE';

export const BookmarkDeleteRequestActionType = '@BOOKMARK/DELETE_REQUEST';
export const BookmarkDeleteSuccessActionType = '@BOOKMARK/DELETE_SUCCESS';
export const BookmarkDeleteFailureActionType = '@BOOKMARK/DELETE_FAILURE';

export type BookmarkFetchRequestAction = ReduxAction<
    typeof BookmarkFetchRequestActionType
>;
export type BookmarkFetchSuccessAction = ReduxAction<
    typeof BookmarkFetchSuccessActionType,
    { payload: BookmarkDto[] }
>;
export type BookmarkFetchFailureAction = ReduxAction<
    typeof BookmarkFetchFailureActionType
>;

export type BookmarkCreateRequestAction = ReduxAction<
    typeof BookmarkCreateRequestActionType
>;
export type BookmarkCreateSuccessAction = ReduxAction<
    typeof BookmarkCreateSuccessActionType,
    { payload: BookmarkDto }
>;
export type BookmarkCreateFailureAction = ReduxAction<
    typeof BookmarkCreateFailureActionType
>;

export type BookmarkDeleteRequestAction = ReduxAction<
    typeof BookmarkDeleteRequestActionType
>;
export type BookmarkDeleteSuccessAction = ReduxAction<
    typeof BookmarkDeleteSuccessActionType,
    { payload: number }
>;
export type BookmarkDeleteFailureAction = ReduxAction<
    typeof BookmarkDeleteFailureActionType
>;

export const createBookmarkFetchRequestAction = (): BookmarkFetchRequestAction => ({
    type: BookmarkFetchRequestActionType,
});

export function createBookmarkFetchSuccessAction(
    bookmarks: BookmarkDto[],
): BookmarkFetchSuccessAction {
    return {
        type: BookmarkFetchSuccessActionType,
        payload: bookmarks,
    };
}

export const createBookmarkFetchFailureAction = (): BookmarkFetchFailureAction => ({
    type: BookmarkFetchFailureActionType,
});

export const createBookmarkCreateRequestAction = (): BookmarkCreateRequestAction => ({
    type: BookmarkCreateRequestActionType,
});

export function createBookmarkCreateSuccessAction(
    bookmarkCreated: BookmarkDto,
): BookmarkCreateSuccessAction {
    return {
        type: BookmarkCreateSuccessActionType,
        payload: bookmarkCreated,
    };
}

export const createBookmarkCreateFailureAction = (): BookmarkCreateFailureAction => ({
    type: BookmarkCreateFailureActionType,
});

export const createBookmarkDeleteRequestAction = (): BookmarkDeleteRequestAction => ({
    type: BookmarkDeleteRequestActionType,
});

export function createBookmarkDeleteSuccessAction(id: number): BookmarkDeleteSuccessAction {
    return {
        type: BookmarkDeleteSuccessActionType,
        payload: id,
    };
}

export const createBookmarkDeleteFailureAction = (): BookmarkDeleteFailureAction => ({
    type: BookmarkDeleteFailureActionType,
});


export type BookmarkAction =
    | BookmarkFetchRequestAction
    | BookmarkFetchSuccessAction
    | BookmarkFetchFailureAction
    | BookmarkCreateRequestAction
    | BookmarkCreateSuccessAction
    | BookmarkCreateFailureAction
    | BookmarkDeleteRequestAction
    | BookmarkDeleteSuccessAction
    | BookmarkDeleteFailureAction;
