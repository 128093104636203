import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useSearchTerm } from 'components/hooks/search/useSearchTerm';
import { TopicTracked } from 'models/tracking';
import { SingleServiceModal } from '../serviceDescription/SingleServiceModal';
import { SearchMenu, SearchNav, transformStringToSearchNav } from './SearchMenu';
import { SearchNonSgmResults } from './SearchNonSgmResults';
import { SearchSgmResults } from './SearchSgmResults';
import { SearchPersonResults } from './SearchPersonResults';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { SearchCompanyResults } from './SearchCompanyResults';
import { useCustomParamsFromUrl } from 'components/hooks/UseCustomParams';
import { getAccessServices } from 'core/services/serviceBoardService/serviceBoardService';
import { getUserBookmarks } from 'core/services/bookmark/userBookmarkService';
import { getCategories } from 'store/Categories/categoriesThunk';

const MAX_SGM_SERVICES = 4;
const MAX_NON_SGM_SERVICES = 4;
const MAX_PERSON_ON_BEST_RESULT = 4;
const MAX_COMPANY_ON_BEST_RESULT = 4;
const MAX_PERSON_ON_FULL_PAGE = 30;
const MAX_COMPANY_ON_FULL_PAGE = 30;

const SearchPageAll: FC = () => (
    <>
        <SearchSgmResults limit={MAX_SGM_SERVICES} />
        <SearchNonSgmResults limit={MAX_NON_SGM_SERVICES} />
        <SearchPersonResults
            limit={MAX_PERSON_ON_BEST_RESULT}
            forceEmptyMessage={true}
        />
        <SearchCompanyResults
            limit={MAX_COMPANY_ON_BEST_RESULT}
            forceEmptyMessage={true}
        />
    </>
);

interface ISearchPageRouteProps {
    route: SearchNav;
    isSgGroup: Nullable<boolean>;
    canSearchPersonsAndCompanies: Nullable<boolean>;
}

const SearchPageRoute: FC<ISearchPageRouteProps> = ({ route, isSgGroup, canSearchPersonsAndCompanies }) => {
    if (route === 'sgmarkets') {
        return <SearchSgmResults />;
    }
    if (route === 'societe' && isSgGroup) {
        return <SearchNonSgmResults forceEmptyMessage={true} />;
    }
    if (route === 'person' && canSearchPersonsAndCompanies) {
        return <SearchPersonResults
            limit={MAX_PERSON_ON_FULL_PAGE}
            forceEmptyMessage={true}
            isFullPage={true}
        />;
    }
    if (route === 'company' && canSearchPersonsAndCompanies) {
        return <SearchCompanyResults
            limit={MAX_COMPANY_ON_FULL_PAGE}
            forceEmptyMessage={true}
            isFullPage={true}
        />;
    }
    return <SearchPageAll />;
};

export const SearchPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const matchWithEmptySearch = useCustomParamsFromUrl('/search//:searchCategory/*');

    const { searchTerm: resultsSearchTerm } = useSearchTerm();
    const { t: translate, i18n: { language } } = useTranslation('service-list');

    const { canSearchPersonsAndCompanies, isSgGroupCurrentUser } = useApplicationContext();
    const activeSearch = transformStringToSearchNav(params.searchCategory ?? matchWithEmptySearch?.searchCategory ?? '');

    useEffect(() => {
        document.title = 'SG Markets - ' + translate('service-list:search');
    }, []);

    useEffect(() => {
        dispatch<any>(getAccessServices(language));
        dispatch<any>(getCategories(language));
        dispatch<any>(getUserBookmarks());
    }, [language]);

    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.AdvancedSearch }}
        >
            <main className="container mt-3 mb-5 pt-0 pb-5 px-3">
                <div className="row offset-xxl-1 col-xxl-10 px-3 px-xl-2 px-xxl-0 my-4">
                    <div className="col-12 col-lg-8">
                        <h2 className="mb-4">
                            {resultsSearchTerm
                                ? translate('service-list:searchResults', { searchTerm: resultsSearchTerm })
                                : translate('service-list:searchResultsEmptyTerm')}
                        </h2>
                        <SearchPageRoute
                            route={activeSearch}
                            isSgGroup={isSgGroupCurrentUser}
                            canSearchPersonsAndCompanies={canSearchPersonsAndCompanies}
                        />
                        <Outlet />
                    </div>
                    <div className="col-12 col-12 col-lg-4">
                        <SearchMenu active={activeSearch} />
                    </div>
                </div>
            </main>
            <SingleServiceModal />
        </ActionTrackingInfoProvider>
    );
};
