import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { LearnMoreCard } from './LearnMoreCard';
import { LearnMoreTitle } from './LearnMoreTitle';
import { ScrollerCarousel } from 'components/common/scroll/ScrollerCarousel';

const GetLearnMoreItem = (store: AppState) => store.learnMore.items;

export const LearnMoreLayout = () => {
    const learnMoreItems = useSelector(GetLearnMoreItem) || [];
    if (learnMoreItems.length === 0) {
        return null;
    }

    return (
        <div className="container-fluid bg-lvl2 h-100 px-0">
            <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3 pt-5">
                <LearnMoreTitle />
                <ScrollerCarousel name="learn-more" maxDisplayedItems={3} className="pb-4">
                    {learnMoreItems.map((card, cardIndex) => (
                        <LearnMoreCard key={cardIndex} item={card} />
                    ))}
                </ScrollerCarousel>
            </div>
        </div>
    );
};
