import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface CookiesConsentElement extends Element {
    displayUserConsentPopup: () => void;
}

declare global {
    interface Window {
        Didomi:
            | {
                  reset: () => void;
                  setUserAgreeToAll: () => void;
              }
            | undefined;
    }
}

export const displayUserConsentPopup = () => {
    const widget = document.querySelector(
        'sgwt-mini-footer',
    ) as CookiesConsentElement | null;
    widget?.displayUserConsentPopup();
};

export const allowAllCookies = () => {
    window.Didomi?.setUserAgreeToAll();
};

const manageCookies = (event: MouseEvent) => {
    event.preventDefault();
    displayUserConsentPopup();
};

const allowCookie = (event: MouseEvent) => {
    event.preventDefault();
    allowAllCookies();
};

export const CookiesRefused = () => {
    const { t: translate } = useTranslation('cookie');

    return (
        <div className="bg-lvl1">
            <div className="m-md-5 px-5 d-flex flex-column align-items-center">
                <div className="mb-md-5 mb-3">
                    <div className="rounded-circle bg-socgen-alpha-md d-inline-flex p-3">
                        <svg
                            width="3rem"
                            height="3rem"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            fill="#e60028"
                        >
                            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                        </svg>
                    </div>
                </div>
                <div className="mb-md-5 mb-3 h2 text-center">
                    {translate('cookie:title')}
                </div>
                <div className="text-justify my-2">
                    {translate('cookie:subTitle')}
                </div>
                <div className="text-justify my-2">
                    <Trans
                        i18nKey="cookie:text"
                        defaults=""
                        components={[
                            <span key={0} className="fw-bold" />,
                        ]}
                    />
                </div>
                <div className="mt-md-5 mt-3 d-flex align-items-center justify-content-md-around w-100 flex-column flex-md-row">
                    <button
                        className={classNames('btn btn-xl', 'btn-flat-primary')}
                        style={{ width: '200px' }}
                        onClick={manageCookies}
                    >
                        {translate('cookie:manageMyCookie')}
                    </button>
                    <button
                        className={classNames(
                            'btn btn-xl',
                            'btn-outline-primary mt-3 mt-md-0',
                        )}
                        style={{ width: '200px' }}
                        onClick={allowCookie}
                    >
                        {translate('cookie:allowAll')}
                    </button>
                </div>
            </div>
        </div>
    );
};
