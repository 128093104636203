import React from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselControl } from 'reactstrap';
import { DefaultTooltip } from '../tooltip/Tooltip';
import classNames from 'classnames';

import './Carousel.scss';

type CarouselControlTooltipProps = {
    idPrefix: string;
    onClickHandler: () => void;
    showTop?: boolean
};

export const ControlPrev: React.FunctionComponent<CarouselControlTooltipProps> = ({
    idPrefix,
    onClickHandler,
    showTop = false,
}) => {
    const { t: translate } = useTranslation('tooltip');
    const id = `${idPrefix}Prev`;

    return (
        <DefaultTooltip            
            text={translate('tooltip:prev')}
            placement="left"
        >
            <span
                id={id}
                className={classNames('carousel-control-wrapper carousel-control-wrapper-prev', { 'carousel-control-show-top': showTop })}
            >
                <CarouselControl
                    className="bg-opacity-20 bg-secondary text-secondary w-100"
                    direction="prev"
                    onClickHandler={onClickHandler}
                />
            </span>
        </DefaultTooltip>
    );
};

export const ControlNext: React.FunctionComponent<CarouselControlTooltipProps> = ({
    idPrefix,
    onClickHandler,
    showTop = false,
}) => {
    const { t: translate } = useTranslation('tooltip');
    const id = `${idPrefix}Next`;

    return (
        <DefaultTooltip text={translate('tooltip:next')}>
            <span
                id={id}
                className={classNames('carousel-control-wrapper carousel-control-wrapper-next', { 'carousel-control-show-top': showTop })}
            >
                <CarouselControl
                    className="bg-colour bg-opacity-20 bg-secondary text-secondary w-100"
                    direction="next"
                    onClickHandler={onClickHandler}
                />
            </span>
        </DefaultTooltip>
    );
};
