import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { take } from 'lodash-es';
import { EssentialServiceDto, ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const ESSENTIALS_COUNT = 7;

const getEssentialServices = (state: AppState): Nullable<EssentialServiceDto[]> => {
    return state.essentialService.items;
};

type UseEssentialServicesResponse = {
    allEssentialServices: ServiceDto[];
    essentialServices: ServiceDto[];
    showSeeAllServices: boolean;
};

export const useEssentialServices = (): UseEssentialServicesResponse => {
    const essentialServices = useSelector(getEssentialServices) || [];
    return {
        allEssentialServices: essentialServices,
        essentialServices: take(essentialServices, ESSENTIALS_COUNT),
        showSeeAllServices: essentialServices.length > ESSENTIALS_COUNT,
    };
};
