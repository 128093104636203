import { Section } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest, mergeRecords } from 'store/store-tools';

export interface IBannerState {
    list: ApiRequest<Section[]>,
    templates: Record<string, ApiRequest<string>>,
}
export const initialState: IBannerState = {
    list: initialApiRequest(),
    templates: {},
};

const banner: Reducer<IBannerState> = (state = initialState, action) => {
    switch (action.type) {
        case '@BANNER/FETCH_BANNERS':
            return {
                ...state,
                list: {
                    ...state.list,
                    state: 'isFetching',
                },
            };

        case '@BANNER/FETCH_BANNERS_SUCCESS':
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.bannerSections || [],
                    state: 'valid',
                },
            };

        case '@BANNER/FETCH_BANNERS_FAILURE':
            return {
                ...state,
                list: {
                    ...state.list,
                    state: 'error',
                },
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        state: 'isFetching',
                    }),
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE_SUCCESS':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        data: action.payload.templateContent || '',
                        state: 'valid',
                    }),
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE_FAILURE':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        state: 'error',
                    }),
            };

        default:
            return state;
    }
};

export default banner;
