import { AppState } from 'store/store';
import { useSelector, shallowEqual } from 'react-redux';
import { Category } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { SgmSearchSelectors } from 'store/Normalizr/SgmSearchSelectors';

export const extractCategoryKey = (key: string | null | undefined) => (key || '').split('.').reverse()[0];

const getCategories = (state: AppState): Category[] => {
    const keys = state.category.categories.data || [];
    return SgmSearchSelectors.getCategoryArray(keys, state.entities.sgmSearch);
};

export const useCategories = (): Category[] => useSelector(getCategories, shallowEqual);
