import { combineReducers } from 'redux';
import {
    MyAccountPersonalInformationFetchSuccessActionType,
    MyAccountPersonalInformationFetchRequestActionType,
    MyAccountPersonalInformationFetchFailureActionType,
} from '../actions/myAccountPersonalInformationAction';
import { ActionType } from 'store/actions/ActionType';
import { MyAccountPersonalInformation } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const personalInformation = (
    state: Nullable<MyAccountPersonalInformation> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case MyAccountPersonalInformationFetchSuccessActionType: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case MyAccountPersonalInformationFetchRequestActionType: {
            return true;
        }
        case MyAccountPersonalInformationFetchFailureActionType:
        case MyAccountPersonalInformationFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    personalInformation,
    isLoading,
});
