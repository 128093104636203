import {
    Category,
    HealthStatusReportDetailed,
    Subcategory,
} from './SgmSearchApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './SgmSearchApiSchema';

export class BaseSgmSearchApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.Category: {
                return (value as Category).key || '';
            }
            case ObjectsWithoutIdPropertyEnum.Subcategory: {
                return (value as Subcategory).key || '';
            }
            case ObjectsWithoutIdPropertyEnum.HealthStatusReportDetailed: {
                return (value as HealthStatusReportDetailed).apiCode || '';
            }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
