import React, { PropsWithChildren } from 'react';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { displayIntroductionTour } from 'core/services/introductionTour/introductionTourService';
import { CATALOG_PATH } from 'models/constants';

const IntroTour = (state: AppState) => state.introTour;

export const NoServices = () => {
    const { t: translate } = useTranslation('service-list');
    const { introTour, isLoading } = useSelector(IntroTour);

    const onLearnMoreClicked = async () => {
        if (!isLoading && introTour) {
            displayIntroductionTour(introTour);
        }
    };

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3 my-5 py-5 flex-grow-1">
            <div className="offset-xxl-1 col-xxl-10">
                <div className="py-5">
                    <h3>{translate('service-list:welcomeTitle')}</h3>
                    <h3
                        className="text-secondary"
                        style={{ maxWidth: '400px' }}
                    >
                        {translate('service-list:welcomeText')}
                    </h3>
                    <div className="mt-4">
                        <Link to={CATALOG_PATH}
                            className="btn btn-lg btn-discreet-primary btn-icon-end fw-medium">
                            {translate('service-list:welcomeCatalogLink')} <i className="ms-1 icon icon-lg">arrow_forward</i>
                        </Link>
                        <button onClick={onLearnMoreClicked}
                            className="ms-3 btn btn-lg btn-flat-secondary">
                            {translate('service-list:learnMore')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const NoNonSgmServices: React.FunctionComponent = () => {
    const { infoUrl } = getConfiguration();
    const { t: translate } = useTranslation('service-list');
    return (
        <p className="text-secondary fw-normal mb-0">
            {translate('service-list:noResultsFoundMessageBefore')}
            <NullableLink href={infoUrl}>
                {translate('service-list:noResultsFoundMessageLink')}
            </NullableLink>
            {translate('service-list:noResultsFoundMessageAfter')}
        </p>
    );
};

type NullableLinkProps = {
    href: Nullable<string>,
    onLinkClick?: () => Promise<void>
};
export const NullableLink: React.FunctionComponent<PropsWithChildren<NullableLinkProps>> = ({
    children,
    href,
    onLinkClick,
}) => {
    if (!href) {
        return <>{children}</>;
    }

    return (
        <a
            href={href}
            onClick={onLinkClick}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};
