import React from 'react';

type ServiceLogoProps = {
    initials: Nullable<string>;
    size: number;
};

export const ServiceLogo: React.FunctionComponent<ServiceLogoProps> = ({
    initials,    
    size,
}) => (
    <div className="flex-0 card h-100">
        <div className="card-body d-flex align-items-center justify-content-center p-0">
            <div
                className="card d-flex justify-content-center align-items-center text-black bg-white border p-0"
                style={{
                    width: scaleBySize(size, 1),
                    height: scaleBySize(size, 1),
                    borderRadius: scaleBySize(size, 5),
                    fontSize: scaleBySize(size, 2.5),
                }}
            >
                {initials}
            </div>
        </div>
    </div>
);

const scaleBySize = (logoSize: number, ratio: number): string => `${
    Math.round(logoSize / ratio)
}px`;
