import React, { FC, PropsWithChildren } from 'react';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useDate } from 'services/date';
import { MyHighlightInsightPublicItemDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

import './InsightPublicCard.scss';

type InsightPublicCardProps = {
    insight: MyHighlightInsightPublicItemDto;
}

const Tag: FC<PropsWithChildren> = ({ children }) =>
    <span className="badge badge-discreet-primary badge-lg badge-prepend-square">{children}</span>;

export const InsightPublicCard: FC<InsightPublicCardProps> = ({ insight }) => {
    const { trackAction } = useServiceTracking();
    const { displayDate } = useDate();

    const handleClick = () => {
        trackAction(FeatureTracked.OpenInsightPublicCard, { topic: TopicTracked.CatalogForYou, parameters: { insightUrl: insight.link } });
    };

    if (!insight) {
        return null;
    }

    return (
        <div className="d-flex position-relative insight-public-card">
            <div className="col-4">
                <div className="border-radius h-100 me-3 image-linear-gradient"                >
                    <i className="icon m-1 px-1 text-white position-absolute image-icon">
                        {insight.publicationType === 'Insight' ? 'article' : 'podcasts'}
                    </i>
                    <img
                        src={insight.coverImageUrl || ''}
                        className="card-img card-fluid h-100 rounded-1 image-filter"
                        alt="..."
                    />
                </div>
            </div>
            <div className="col-8">
                <div
                    className="card-body d-flex flex-column h-100 pt-0 px-0 pb-3 border-bottom border-opacity-50">
                    <a
                        className="stretched-link text-primary card-title text-truncate h5"
                        href={insight.link || ''}
                        onClick={handleClick}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {insight.title}
                    </a>
                    <p className="card-text mb-0 text-truncate-2">{insight.summary}</p>
                    <div className="card-text mt-auto d-flex align-items-center gap-3">
                        {insight.tags && <Tag>{insight.tags}</Tag>}
                        {insight.publicationType === 'Podcast' && <Tag>{insight.publicationType}</Tag>}
                        <span className="text-secondary text-nowrap">{displayDate(insight.publicationDateUtc, true)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};