import React from 'react';
import { isFunction } from 'lodash-es';
import { loadScript } from 'core/services/loadScript';

export interface IProps {
    script: string;
    children?: React.ReactNode | (() => React.ReactNode);
    onLoad?: () => void;
}

export interface IState {
    scriptLoaded: boolean;
}

export class WidgetLazyLoader extends React.PureComponent<IProps, IState> {
    public state: IState = {
        scriptLoaded: false,
    };

    public componentDidMount(): void {
        this.setupWidget();
    }

    public componentDidUpdate(): void {
        this.setupWidget();
    }

    public render() {
        const { scriptLoaded } = this.state;
        if (!scriptLoaded) {
            return null;
        }
        if (isFunction(this.props.children)) {
            return <>{this.props.children()}</>;
        }
        return <>{this.props.children}</>;
    }

    private async setupWidget() {
        const { scriptLoaded } = this.state;
        const { onLoad } = this.props;
        if (scriptLoaded) {
            return;
        }
        await loadScript(this.props.script, this.props.script, false);
        this.setState((prev) => ({ ...prev, scriptLoaded: true }));

        if (onLoad) {
            onLoad();
        }
    }
}
