import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { ControlPrev, ControlNext } from 'components/common/carousel/CarouselControlTooltip';
import { Video } from './Video';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';

type ScreenshotCarouselProps = {
    images: string[];
    videoId: Nullable<string>;
};

export const ScreenshotCarousel: React.FunctionComponent<ScreenshotCarouselProps> = ({
    images,
    videoId,
}) => {
    const { infoUrl } = getConfiguration();
    const [currentImage, setCurrentImage] = useState(0);
    const [animating, setAnimating] = useState(false);

    const slides = images.map(image => {
        const imageUrl = image.startsWith('https://')
            ? image
            : `${infoUrl}/images/services/${image}`;
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={image}
            >
                <div className="text-center">
                    <img src={imageUrl} className="mw-100 mh-100" alt="service screenshot" />
                </div>
            </CarouselItem>
        );
    });

    if (videoId) {
        slides.unshift(
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={videoId}
            >
                <Video videoId={videoId} />
            </CarouselItem>,
        );
    }

    const moveSlideBy = (step: number) => {
        if (!animating) {
            setCurrentImage((slides.length + currentImage + step) % slides.length);
        }
    };

    const next = () => moveSlideBy(1);
    const previous = () => moveSlideBy(-1);

    return (
        <div className="d-flex mb-5" aria-hidden="true">
            <Carousel
                activeIndex={currentImage}
                next={next}
                previous={previous}
                interval={false}
                className="w-100"
            >
                {slides}
                {slides.length > 1 &&
                    <>
                        <ControlPrev idPrefix="screenshotCarousel" onClickHandler={previous} />
                        <ControlNext idPrefix="screenshotCarousel" onClickHandler={next} />
                    </>
                }

            </Carousel>
        </div>
    );
};
