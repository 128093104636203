import configDev from 'config/config.dev.json';
import configLocal from 'config/config.local.json';
import configTest from 'config/config.test.json';
import configPrd from 'config/config.prd.json';
import configUat from 'config/config.uat.json';
import { Configuration, Environment } from './configuration';
import { SgConnectEnvironment } from 'services/SgConnect/SgConnectEnvironment';

export const getEnvironment = (): Environment => {
    if ((window as any).__vitest_environment__ === 'happy-dom') {
        return Environment.Test;
    }

    const hostname = window.location.hostname;

    if (hostname === 'local.fr.world.socgen' || hostname === 'localhost') {
        return Environment.Local;
    }

    if (hostname === 'www-dev.sgmarkets.com') {
        return Environment.Development;
    }

    if (hostname === 'www-uat.sgmarkets.com') {
        return Environment.Homologation;
    }

    if (hostname === 'www.sgmarkets.com'
        || hostname === 'www-preview.sgmarkets.com') {
        return Environment.Production;
    }

    throw new Error(`Could not load configuration for: ${hostname}`);
};

export const getConfiguration = (): Configuration => {
    const environment = getEnvironment();
    switch (environment) {
        case Environment.Test:
            return {
                ...configTest,
                ...configTest.sgConnect,
                sgConnectEnvironment: SgConnectEnvironment.Homologation,
            };

        case Environment.Local:
            return {
                ...configLocal,
                ...configLocal.sgConnect,
                sgConnectEnvironment: SgConnectEnvironment.Homologation,
            };


        case Environment.Development:
            return {
                ...configDev,
                ...configDev.sgConnect,
                sgConnectEnvironment: SgConnectEnvironment.Homologation,
            };
        case Environment.Homologation:
            return {
                ...configUat,
                ...configUat.sgConnect,
                sgConnectEnvironment: SgConnectEnvironment.Homologation,
            };

        case Environment.Production:
            return {
                ...configPrd,
                ...configPrd.sgConnect,
                sgConnectEnvironment: SgConnectEnvironment.Production,
            };

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = environment;
            throw new Error(`Could not get configuration env: ${environment}`);
    }
};
