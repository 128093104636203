import { GetUserDisclaimerContentResponse } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

// Generate User Disclaimer
export const GenerateUserDisclaimerFetchRequestActionType = '@DISCLAIMER/GENERATE_USER_DISCLAIMER_FETCH_REQUEST';
export const GenerateUserDisclaimerFetchSuccessActionType = '@DISCLAIMER/GENERATE_USER_DISCLAIMER_FETCH_SUCCESS';
export const GenerateUserDisclaimerFetchFailureActionType = '@DISCLAIMER/GENERATE_USER_DISCLAIMER_FETCH_FAILURE';

type GenerateUserDisclaimerFetchRequestAction = ReduxAction<
    typeof GenerateUserDisclaimerFetchRequestActionType
>;
type GenerateUserDisclaimerFetchSuccessAction = ReduxAction<
    typeof GenerateUserDisclaimerFetchSuccessActionType,
    { payload: Nullable<string> }
>;
type GenerateUserDisclaimerFetchFailureAction = ReduxAction<
    typeof GenerateUserDisclaimerFetchFailureActionType
>;

export type GenerateUserDisclaimerAction =
    | GenerateUserDisclaimerFetchRequestAction
    | GenerateUserDisclaimerFetchSuccessAction
    | GenerateUserDisclaimerFetchFailureAction;

export const createGenerateUserDisclaimerFetchRequestAction = (): GenerateUserDisclaimerFetchRequestAction => {
    return {
        type: GenerateUserDisclaimerFetchRequestActionType,
    };
};

export const createGenerateUserDisclaimerFetchSuccessAction = (disclaimer: Nullable<GetUserDisclaimerContentResponse>): GenerateUserDisclaimerFetchSuccessAction => {
    return {
        type: GenerateUserDisclaimerFetchSuccessActionType,
        payload: disclaimer?.html,
    };
};

export const createGenerateUserDisclaimerFetchFailureAction = (): GenerateUserDisclaimerFetchFailureAction => {
    return {
        type: GenerateUserDisclaimerFetchFailureActionType,
    };
};

// Last Validated User Disclaimer
export const LastValidatedUserDisclaimerFetchRequestActionType = '@DISCLAIMER/LAST_VALIDATED_USER_DISCLAIMER_FETCH_REQUEST';
export const LastValidatedUserDisclaimerFetchSuccessActionType = '@DISCLAIMER/LAST_VALIDATED_USER_DISCLAIMER_FETCH_SUCCESS';
export const LastValidatedUserDisclaimerFetchFailureActionType = '@DISCLAIMER/LAST_VALIDATED_USER_DISCLAIMER_FETCH_FAILURE';

type LastValidatedUserDisclaimerFetchRequestAction = ReduxAction<
    typeof LastValidatedUserDisclaimerFetchRequestActionType
>;
type LastValidatedUserDisclaimerFetchSuccessAction = ReduxAction<
    typeof LastValidatedUserDisclaimerFetchSuccessActionType,
    { payload: Nullable<GetUserDisclaimerContentResponse> }
>;
type LastValidatedUserDisclaimerFetchFailureAction = ReduxAction<
    typeof LastValidatedUserDisclaimerFetchFailureActionType
>;

export type LastValidatedUserDisclaimerAction =
    | LastValidatedUserDisclaimerFetchRequestAction
    | LastValidatedUserDisclaimerFetchSuccessAction
    | LastValidatedUserDisclaimerFetchFailureAction;

export const createLastValidatedDisclaimerFetchRequestAction = (): LastValidatedUserDisclaimerFetchRequestAction => {
    return {
        type: LastValidatedUserDisclaimerFetchRequestActionType,
    };
};

export const createLastValidatedDisclaimerFetchSuccessAction = (disclaimer: Nullable<GetUserDisclaimerContentResponse>): LastValidatedUserDisclaimerFetchSuccessAction => {
    return {
        type: LastValidatedUserDisclaimerFetchSuccessActionType,
        payload: disclaimer,
    };
};

export const createLastValidatedDisclaimerFetchFailureAction = (): LastValidatedUserDisclaimerFetchFailureAction => {
    return {
        type: LastValidatedUserDisclaimerFetchFailureActionType,
    };
};

// DisclaimerAction
export type DisclaimerAction =
    | GenerateUserDisclaimerAction
    | LastValidatedUserDisclaimerAction