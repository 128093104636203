import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useTranslation } from 'react-i18next';
import { Scroller } from 'components/common/scroll/Scroller';

const MYACCOUNT_PATH = '/myaccount';

type TabKeyType = 'settings' | 'personal-information' | 'signature';
type TabType = {
    key: TabKeyType,
    label: ReactNode,
    isShown: () => boolean,
}

interface IActiveTab {
    tabs: TabType[]
    activeTab: TabKeyType,
    showTabs: boolean,
}

const SettingsTab: FC = () => {
    const { t: translate } = useTranslation('my-account');
    return translate('settings-tab-title');
};

const InformationTab: FC = () => {
    const { t: translate } = useTranslation('my-account');
    return translate('information-tab-title');
};

const SignatureTab: FC = () => {
    const { t: translate } = useTranslation('my-account');
    return <>
        {translate('signature-tab-title')}
        <div className="badge badge-discreet-danger rounded-pill ms-2">{translate('signature-tab-internal')}</div>
    </>;
};

export const useActiveTab = (): IActiveTab => {
    const { pathname } = useLocation();
    const mediaBreakpoint = useMediaBreakpoint();
    const { isSgGroupCurrentUser } = useApplicationContext();

    const tabPath = pathname.replace(`${MYACCOUNT_PATH}`, '').split('/').reverse()[0];

    const allTabs: TabType[] = [
        {
            key: 'settings',
            label: <SettingsTab />,
            isShown: () => true,
        },
        {
            key: 'personal-information',
            label: <InformationTab />,
            isShown: () => ['xs', 'sm', 'md'].includes(mediaBreakpoint),
        },
        {
            key: 'signature',
            label: <SignatureTab />,
            isShown: () => isSgGroupCurrentUser === true,
        },
    ];

    const tabs = allTabs.filter(x => x.isShown());
    const activeTab = tabs.find(x => x.key === tabPath)?.key || allTabs[0].key;

    return {
        tabs,
        activeTab,
        showTabs: Object.keys(tabs).length >= 2,
    };
};

export const MyAccountTabs: FC = () => {
    const { tabs, activeTab, showTabs } = useActiveTab();
    const { trackAction } = useServiceTracking();

    const getCategoryLinkClassName = (tabKey: Nullable<string>) => {
        return classNames('nav-link nav-link-lg', { 'active': tabKey === activeTab });
    };

    if (!showTabs) {
        return null;
    }

    return (
        <Scroller>
            <ul className="nav nav-underline flex-nowrap mt-3 mx-lg-5 mx-4">
                {tabs.map(x => {
                    return <li key={x.key} className="nav-item col-auto">
                        <Link
                            to={`${MYACCOUNT_PATH}/${x.key}`}
                            className={getCategoryLinkClassName(x.key)}
                            onClick={async () => await trackAction(FeatureTracked.MyAccountTab, {
                                topic: TopicTracked.MyAccount,
                                parameters: {
                                    'tab-key': x.key,
                                },
                            })}
                        >
                            {x.label}
                        </Link>
                    </li>;
                })}
            </ul>
        </Scroller>
    );
};