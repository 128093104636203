import { MarkAsClickedResponse, NotificationCountByProducerResponse, SearchNotificationsResponse } from 'services/ApiService/Notification/NotificationApiClient';

// Notification Tracking click
export const createTrackUserNotificationClickFetchRequestAction = () => ({
    type: '@NOTIFICATION/TRACK_USER_CLICK_FETCH_REQUEST',
} as const);

export const createTrackUserNotificationClickFetchSuccessAction = (markAsClickedResponseModel: MarkAsClickedResponse) => ({
    type: '@NOTIFICATION/TRACK_USER_CLICK_FETCH_SUCCESS',
    payload: markAsClickedResponseModel,
} as const);

export const createTrackUserNotificationClickFetchFailureAction = () => ({
    type: '@NOTIFICATION/TRACK_USER_CLICK_FETCH_FAILURE',
} as const);

// Notification Count By Producers
export const createNotificationCountByProducersFetchRequestAction = () => ({
    type: '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_REQUEST',
} as const);

export const createNotificationCountByProducersFetchSuccessAction = (countByProducers: NotificationCountByProducerResponse[] | undefined) => ({
    type: '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_SUCCESS',
    payload: countByProducers,
} as const);

export const createNotificationCountByProducersFetchFailureAction = () => ({
    type: '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_FAILURE',
} as const);

// Notification Search
export const createSearchNotificationsFetchRequestAction = (searchKey: string, fetchNextPage: boolean) => ({
    type: '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_REQUEST',
    payload: {
        searchKey,
        fetchNextPage,
    },
} as const);

export const createSearchNotificationsFetchSuccessAction = (searchKey: string, searchResult: SearchNotificationsResponse) => ({
    type: '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_SUCCESS',
    payload: {
        searchKey,
        searchResult,
    },
} as const);

export const createSearchNotificationsFetchFailureAction = (searchKey: string) => ({
    type: '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_FAILURE',
    payload: {
        searchKey,
    },
} as const);

// Notification Tracking unread
export const createTrackUserNotificationReadFetchRequestAction = () => ({
    type: '@NOTIFICATION/TRACK_USER_READ_FETCH_REQUEST',
} as const);

export const createTrackUserNotificationReadFetchSuccessAction = () => ({
    type: '@NOTIFICATION/TRACK_USER_READ_FETCH_SUCCESS',
} as const);

export const createTrackUserNotificationReadFetchFailureAction = () => ({
    type: '@NOTIFICATION/TRACK_USER_READ_FETCH_FAILURE',
} as const);

// Internal mark as read
export const createInternalMarkAsReadAction = (notificationId: number) => ({
    type: '@NOTIFICATION/INTERNAL_MARK_AS_READ',
    payload: {
        notificationId,
    },
} as const);

// NotificationAction
export type NotificationAction =
    | ReturnType<typeof createTrackUserNotificationClickFetchRequestAction>
    | ReturnType<typeof createTrackUserNotificationClickFetchSuccessAction>
    | ReturnType<typeof createTrackUserNotificationClickFetchFailureAction>
    | ReturnType<typeof createNotificationCountByProducersFetchRequestAction>
    | ReturnType<typeof createNotificationCountByProducersFetchSuccessAction>
    | ReturnType<typeof createNotificationCountByProducersFetchFailureAction>
    | ReturnType<typeof createSearchNotificationsFetchRequestAction>
    | ReturnType<typeof createSearchNotificationsFetchSuccessAction>
    | ReturnType<typeof createSearchNotificationsFetchFailureAction>
    | ReturnType<typeof createTrackUserNotificationReadFetchRequestAction>
    | ReturnType<typeof createTrackUserNotificationReadFetchSuccessAction>
    | ReturnType<typeof createTrackUserNotificationReadFetchFailureAction>
    | ReturnType<typeof createInternalMarkAsReadAction>;
