import { combineReducers } from 'redux';

import { ActionType } from 'store/actions/ActionType';
import { ConsentUpdateQumuActionType } from 'store/actions/consentAction';

const qumu = (state = false, action: ActionType) => {
    if (action.type === ConsentUpdateQumuActionType) {
        return action.payload;
    }
    return state;
};

export default combineReducers({
    qumu,
});
