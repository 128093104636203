export const createCategoryFetchRequestAction = () => ({
    type: '@CATEGORY/FETCH_REQUEST',
} as const);

export const createCategoryFetchSuccessAction = (categories: string[]) => ({
    type: '@CATEGORY/FETCH_SUCCESS',
    payload: categories,
} as const);

export const createCategoryFetchFailureAction = () => ({
    type: '@CATEGORY/FETCH_FAILURE',
} as const);

export type CategoriesAction =
    | ReturnType<typeof createCategoryFetchRequestAction>
    | ReturnType<typeof createCategoryFetchSuccessAction>
    | ReturnType<typeof createCategoryFetchFailureAction>;
