import {
    GetNotificationResponse,
    MarkAsClickedResponse,
    NotificationCountByProducerResponse,
} from 'services/ApiService/Notification/NotificationApiClient';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export type NotificationsSearchState = ApiRequest<GetNotificationResponse[]> & {
    lastFetchedPage: number,
    isLastPage: boolean,
    searchKey: string,
}

export const initialState: {
    markAsClicked: ApiRequest<MarkAsClickedResponse>,
    countByProducers: ApiRequest<NotificationCountByProducerResponse[]>,
    searchResult: NotificationsSearchState,
    isDismissingAll: boolean,
} = {
    markAsClicked: initialApiRequest(),
    countByProducers: initialApiRequest(),
    searchResult: {
        ...initialApiRequest(),
        lastFetchedPage: 0,
        isLastPage: false,
        searchKey: '',
    },
    isDismissingAll: false,
} as const;

export type NotificationState = typeof initialState;

export const NotificationReducer: Reducer<NotificationState> = (state = initialState, action) => {
    switch (action.type) {
        case '@NOTIFICATION/TRACK_USER_CLICK_FETCH_REQUEST':
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    state: 'isFetching',
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/TRACK_USER_CLICK_FETCH_SUCCESS':
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    state: 'valid',
                    data: action.payload,
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/TRACK_USER_CLICK_FETCH_FAILURE':
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    state: 'error',
                },
            } satisfies NotificationState;

        case '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_REQUEST':
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    state: 'isFetching',
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_SUCCESS':
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    state: 'valid',
                    data: action.payload,
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_FAILURE':
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    state: 'error',
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_REQUEST':
            return {
                ...state,
                searchResult: {
                    ...(action.payload.fetchNextPage ? state.searchResult : { lastFetchedPage: 0, isLastPage: false, data: undefined }),
                    searchKey: action.payload.searchKey,
                    state: 'isFetching',

                },
            } satisfies NotificationState;
        case '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_SUCCESS':
            if (state.searchResult.searchKey !== action.payload.searchKey) {
                // Nothing to do, another resquest is in progress
                return state;
            }
            const fetchedPage = action.payload.searchResult.pageMeta?.page ?? 1;
            const fetchedNotifs = action.payload.searchResult.notifications ?? [];
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    data: fetchedPage === 1 ? fetchedNotifs : [...(state.searchResult.data || []), ...fetchedNotifs],
                    isLastPage: action.payload.searchResult.pageMeta?.isLastPage === true,
                    lastFetchedPage: action.payload.searchResult.pageMeta?.page || 1,
                    state: 'valid',
                },
            } satisfies NotificationState;
        case '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_FAILURE':
            if (state.searchResult.searchKey !== action.payload.searchKey) {
                // Nothing to do, another resquest is in progress
                return state;
            }
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    state: 'error',
                },
            } satisfies NotificationState;

        case '@NOTIFICATION/TRACK_USER_READ_FETCH_REQUEST':
            return {
                ...state,
                isDismissingAll: true,
            } satisfies NotificationState;
        case '@NOTIFICATION/TRACK_USER_READ_FETCH_SUCCESS':
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    state: 'outdated',
                },
                searchResult: {
                    ...state.searchResult,
                    state: 'outdated',
                },
                isDismissingAll: false,
            } satisfies NotificationState;
        case '@NOTIFICATION/TRACK_USER_READ_FETCH_FAILURE':
            return {
                ...state,
                isDismissingAll: false,
            } satisfies NotificationState;

        case '@NOTIFICATION/INTERNAL_MARK_AS_READ': {
            const searchResultData = state.searchResult.data;
            const countByProducersData = state.countByProducers.data;

            const notif = searchResultData?.find(x => x.id === action.payload.notificationId);
            if (!notif || notif.isRead || notif.isExpired) {
                // Refresh count only if notif is found and not dismissed
                return state;
            }

            const newSearchResultData = searchResultData?.map(x => {
                return x.id === notif.id ? { ...x, isRead: true } : x;
            });

            const newCountByProducers = countByProducersData?.map(x => {
                if (!x.producer || x.producer.code === notif.producer?.code) {
                    return { ...x, unreadCount: x.unreadCount - 1 };
                }
                return x;
            });

            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    data: newCountByProducers,
                },
                searchResult: {
                    ...state.searchResult,
                    data: newSearchResultData,
                },
            } satisfies NotificationState;
        }

        default:
            return state;
    }
};
