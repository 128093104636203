import React from 'react';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';

export const AccountSignatureSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Skeleton width={180} height={50} className="mb-2" />
            <Skeleton width={180} />
            <Skeleton width={120} className="mb-2" />            
            <Skeleton width={200} height={76} className="mb-2" />
            <Skeleton height={100} className="mb-2" />
            <Skeleton height={100} className="mb-2" />
        </SkeletonWrapper>
    );
};