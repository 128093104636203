import React, { useEffect, useRef, FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Scroller.scss';

interface ScrollerProps {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
}

// Use touch scroll like mobile scroll but on desktop
export const Scroller: FC<ScrollerProps> = ({ children, className, disabled }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (sliderRef.current == null) {
            return;
        }

        let isDown = false;
        let startX: number;
        let scrollLeft: number;
        let velX = 0;
        let momentumID: number;

        const slider = sliderRef.current;

        function beginMomentumTracking() {
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }

        function cancelMomentumTracking() {
            cancelAnimationFrame(momentumID);
        }

        function momentumLoop() {
            slider.scrollLeft += velX;
            velX *= 0.95;
            if (Math.abs(velX) > 0.5) {
                momentumID = requestAnimationFrame(momentumLoop);
            }
        }

        const onMouseDown = (e: MouseEvent) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            cancelMomentumTracking();
        };

        const onMouseLeave = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const onMouseUp = () => {
            isDown = false;
            slider.classList.remove('active');
            beginMomentumTracking();
        };

        const onMouseMove = (e: MouseEvent) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            const prevScrollLeft = slider.scrollLeft;
            slider.scrollLeft = scrollLeft - walk;
            velX = slider.scrollLeft - prevScrollLeft;
        };

        const onWheel = () => {
            cancelMomentumTracking();
        };

        slider.addEventListener('mousedown', onMouseDown);
        slider.addEventListener('mouseleave', onMouseLeave);
        slider.addEventListener('mouseup', onMouseUp);
        slider.addEventListener('mousemove', onMouseMove);
        slider.addEventListener('wheel', onWheel);

        return () => {
            slider.removeEventListener('mousedown', onMouseDown);
            slider.removeEventListener('mouseleave', onMouseLeave);
            slider.removeEventListener('mouseup', onMouseUp);
            slider.removeEventListener('mousemove', onMouseMove);
            slider.removeEventListener('wheel', onWheel);
        };
    }, []);

    const wrapperClassName = classNames(className, {
        'scroller-horizontal-scroll': !disabled,
    });

    return <div ref={sliderRef} className={wrapperClassName}>
        {children}
    </div>;
};