import { useSelector } from 'react-redux';
import { ApiRequest, AppState } from 'store/store';
import { GetSearchResponse, SearchMetaPageDataDto } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

const getResultsOrEmpty = <T>(results: Nullable<ApiRequest<IPaginatedSgmSearchResults<T> | undefined>>): Required<ApiRequest<Required<IPaginatedSgmSearchResults<T>>>> => {
    return {
        state: results?.state || 'neverFetched',
        data: {
            items: [],
            searchMetaPageData: {
                page: 0,
                pageSizeBySearchType: 0,
                isFirstPage: false,
                isLastPage: false,
                totalCount: 0,
                totalPage: 0,
            },
            ...(results?.data || {}),
        },
    };
};

export type RequiredGetSearchResponse = Required<GetSearchResponse> & {
    [P in keyof GetSearchResponse]: Required<GetSearchResponse[P]>
}

export interface IPaginatedSgmSearchResults<T> {
    items?: T[]
    searchMetaPageData?: SearchMetaPageDataDto
}

type UseSearchItemResultsResponse = { searchTerm: string } & {
    [P in keyof Omit<RequiredGetSearchResponse, 'meta'>]: Required<ApiRequest<RequiredGetSearchResponse[P]>>
}

export const useSearchItemResults = (): UseSearchItemResultsResponse => {
    const { sgm, sg, person, company, searchTerm } = useSelector((state: AppState) => state.search);
    return {
        searchTerm: searchTerm || '',
        sgm: getResultsOrEmpty(sgm),
        sg: getResultsOrEmpty(sg),
        person: getResultsOrEmpty(person),
        company: getResultsOrEmpty(company),
    };
};