import classNames from 'classnames';
import React, { useId } from 'react';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';

type ToogleOption<K extends string | number> = {
    key: K;
    label: string;
}

type ToogleFormProps<T extends FieldValues, K extends string | number> = {
    name: FieldPath<T>;
    label: string;
    options: ToogleOption<K>[];
    helper?: string;
    className?: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

type ToogleOptionProps<T extends FieldValues, K extends string | number> = {
    name: FieldPath<T>;
    option: ToogleOption<K>;
    register: UseFormRegister<T>;
    disabled?: boolean;
}

export const ToogleForm = <T extends FieldValues, K extends string | number>({ name, label, options, helper, className, register, errors, disabled }: ToogleFormProps<T, K>): JSX.Element => {
    const error = errors[name];

    const btnGroupClassName = classNames('btn-group btn-group-toggle', {
        'is-invalid': error,
    });

    return <div className={classNames('input-container', className)}>
        <label>{label}</label>
        <div className={btnGroupClassName}>
            {options.map(x => <ToogleOption<T, K> key={x.key} option={x} name={name} register={register} disabled={disabled} />)}
        </div>
        {helper && <span className="form-text">{helper}</span>}
        {error && <div className="invalid-feedback">
            {error.message as string}
        </div>}
    </div>;
};

const ToogleOption = <T extends FieldValues, K extends string | number>({ option, name, register, disabled }: ToogleOptionProps<T, K>): JSX.Element => {
    const inputId = useId();

    return <>
        <input
            id={inputId}
            type="radio"
            value={option.key}
            {...register(name)}
            className="btn-check"
            disabled={disabled}
        />
        <label
            htmlFor={inputId}
            className="btn btn-toggle-primary"
        >
            {option.label}
        </label>
    </>;
};