import { useSelector } from 'react-redux';
import { MyAccountPersonalInformation } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { AppState } from 'store/store';

const getMyAccountPersonalInformation = (state: AppState) => state.myAccountPersonalInformation;
type UseMyAccountPersonalInformationResponse = {
    personalInformation: Nullable<MyAccountPersonalInformation>;
    isLoading: boolean;
};

export const useMyAccountPersonalInformation = (): UseMyAccountPersonalInformationResponse => {
    const myAccountPersonalInformation = useSelector(getMyAccountPersonalInformation);
    return {
        ...myAccountPersonalInformation,
    };
};