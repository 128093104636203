import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { SGConnectConfiguration } from './configuration/configuration';

let sgwtConnect: SGWTConnectCore;

type AppWindow = {
    sgwtConnect: SGWTConnectCore;
    SGWTWidgetConfiguration: { environment: 'homologation' | 'production' };
};

export const loadSGConnect = (configuration: SGConnectConfiguration) => {
    if (!sgwtConnect) {
        sgwtConnect = setupSGWTConnect({
            authorization_endpoint: configuration.authorizationEndpoint,
            client_id: configuration.clientId,
            scope: configuration.scope,
            acr_values: configuration.level,
            redirect_uri: configuration.redirect_uri,
        });
    }

    setupPassiveMode();

    if (!sgwtConnect.isAuthorized()) {
        sgwtConnect.requestAuthorization();
        return;
    }
    const windowIfDefined =
        typeof window === 'undefined'
            ? null
            : ((window as unknown) as AppWindow);
    if (windowIfDefined && !windowIfDefined.sgwtConnect) {
        windowIfDefined.sgwtConnect = sgwtConnect;
    }

    return sgwtConnect;
};

export const setupPassiveMode = () => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (!widget) {
        return;
    }

    if (typeof widget.setSgwtConnectInstance === 'undefined') {
        // retry until widget is loaded
        setTimeout(setupPassiveMode, 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

export const getSGConnect = () => {
    return sgwtConnect;
};
