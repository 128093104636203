import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createHighlightLayoutsFetchFailureAction,
    createHighlightLayoutsFetchRequestAction,
    createHighlightLayoutsFetchSuccessAction,
} from '../../../store/actions/highlightLayoutAction';
import { HighlightLayoutsApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const getMyHighlightLayouts = (language: string): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { logger },
    ) => {
        dispatch(createHighlightLayoutsFetchRequestAction());
        try {
            const myHighlightLayouts = await HighlightLayoutsApi.getHighlightLayoutForMe(undefined, undefined, language);
            dispatch(createHighlightLayoutsFetchSuccessAction(myHighlightLayouts?.layouts || []));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createHighlightLayoutsFetchFailureAction());
        }
    };
};
