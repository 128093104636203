import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createMyAccountSettingFetchFailureAction,
    createMyAccountSettingFetchRequestAction,
    createMyAccountSettingFetchSuccessAction,
} from 'store/actions/myAccountSettingAction';
import {
    createMyAccountPersonalInformationFetchFailureAction,
    createMyAccountPersonalInformationFetchRequestAction,
    createMyAccountPersonalInformationFetchSuccessAction,
} from 'store/actions/myAccountPersonalInformationAction';
import { MyAccountsApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const GetSettings = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { logger },
    ) => {
        const { myAccountSetting: { setting } } = state();

        if (setting) {
            return;
        }

        dispatch(createMyAccountSettingFetchRequestAction());
        try {
            const myAccountSetting = await MyAccountsApi.getSettings();
            dispatch(
                createMyAccountSettingFetchSuccessAction(myAccountSetting),
            );
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createMyAccountSettingFetchFailureAction());
        }
    };
};

export const GetPersonalInformation = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { logger },
    ) => {
        const { myAccountPersonalInformation: { personalInformation } } = state();

        if (personalInformation) {
            return;
        }

        dispatch(createMyAccountPersonalInformationFetchRequestAction());
        try {
            const myAccountPersonalInformation = await MyAccountsApi.getPersonalInformation();
            dispatch(
                createMyAccountPersonalInformationFetchSuccessAction(myAccountPersonalInformation),
            );
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createMyAccountPersonalInformationFetchFailureAction());
        }
    };
};
