import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedObject, useLocalizedObject } from 'components/hooks/translation/useLocalizedObject';
import { FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';

type ShareButtonProps = {
    url: Nullable<string>;
    share: Nullable<LocalizedObject<string[]>>;
};

export const ShareButton: React.FunctionComponent<ShareButtonProps> = ({
    url,
    share,
}) => {
    const { t: translate } = useTranslation('service-description');
    const localize = useLocalizedObject();
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    const shareLines = share ? localize(share) : [];

    return (
        <a href={getHref(url, shareLines || [])}
            onClick={async () => await trackService(FeatureTracked.Share, serviceTracked)}
            className="btn btn-lg btn-flat-secondary btn-icon-end"
        >
            {translate('service-description:share')}
            <i className="icon icon-md">share</i>
        </a>
    );
};

const getSubject = (shareLines: string[]) => encodeURIComponent(shareLines[0] || '');

const getBody = (url: Nullable<string>, shareLines: string[]) => {
    const lines = shareLines.slice(1);
    if (url) {
        lines.push(url);
    }
    return encodeURIComponent(lines.join('\n'));
};

const getHref = (url: Nullable<string>, shareLines: string[]) => {
    const subject = getSubject(shareLines);
    const body = getBody(url, shareLines);
    return `mailto:?${subject ? `subject=${subject}&` : ''
        }${body ? `body=${body}` : ''
        }`;
};
