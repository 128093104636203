import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createAuthenticatedUserFetchFailureAction,
    createAuthenticatedUserFetchRequestAction,
    createAuthenticatedUserFetchSuccessAction,
    createAuthenticatedUserContactsFetchFailureAction,
    createAuthenticatedUserContactsFetchRequestAction,
    createAuthenticatedUserContactsFetchSuccessAction,
} from '../../../store/actions/authenticatedUserAction';
import { ActionType } from 'store/actions/ActionType';
import { UserApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const GetAuthenticatedUser = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { logger },
    ) => {
        dispatch(createAuthenticatedUserFetchRequestAction());
        try {
            const userInformation = await UserApi.get();
            dispatch(
                createAuthenticatedUserFetchSuccessAction(userInformation),
            );
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createAuthenticatedUserFetchFailureAction());
        }
    };
};

export const getAuthenticatedUserContacts = (search = ''): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { logger },
    ) => {
        dispatch(createAuthenticatedUserContactsFetchRequestAction());
        try {
            const userContacts = await UserApi.getContacts(search);
            dispatch(
                createAuthenticatedUserContactsFetchSuccessAction(userContacts),
            );
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createAuthenticatedUserContactsFetchFailureAction());
        }
    };
};