import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useHasAccessServices } from 'components/hooks/services/useBoardServices';
import { useServiceDescriptionLink } from 'components/hooks/services/useServiceDescriptionLink';
import { FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';

type ServiceLinkProps = {
    serviceKey: Nullable<string>;
    url: Nullable<string>;
    className?: string;
};

export const ServiceLink: React.FunctionComponent<PropsWithChildren<ServiceLinkProps>> = ({
    serviceKey,
    url,
    className,
    children,
}) => {
    const descriptionUrl = useServiceDescriptionLink(serviceKey);
    const { hasAccessTo } = useHasAccessServices();
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    if (!serviceKey) {
        return (
            <div className={className}>
                {children}
            </div>
        );
    }

    const canAccess = hasAccessTo(serviceKey);
    if (canAccess && url) {
        return (
            <a
                href={url}
                onClick={async () => await trackService(FeatureTracked.OpenService, serviceTracked)}
                target="_blank"
                rel="noopener noreferrer"
                className={className}
            >
                {children}
            </a>
        );
    }

    return (
        <Link to={descriptionUrl}
            onClick={async () => await trackService(FeatureTracked.ViewServiceDetails, serviceTracked)}
            className={className}
        >
            {children}
        </Link>
    );
};
