import { combineReducers } from 'redux';
import {
    EssentialServicesFetchSuccessActionType,
    EssentialServicesFetchRequestActionType,
    EssentialServicesFetchFailureActionType,
} from '../actions/essentialServicesAction';
import { ActionType } from 'store/actions/ActionType';
import { EssentialServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const items = (
    state: Nullable<EssentialServiceDto[]> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case EssentialServicesFetchSuccessActionType:
            {
                return action.payload;
            }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case EssentialServicesFetchRequestActionType: {
            return true;
        }
        case EssentialServicesFetchFailureActionType:
        case EssentialServicesFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    items,
    isLoading,
});
