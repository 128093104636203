export const IMPERSONATING_USER = 'impersonatingUser';

export const DEFAULT_CATEGORY = 'Societe Generale'; // never translated

export const DEFAULT_SGM_CATEGORY = 'SG Markets'; // never translated

export const TRACKING_SEARCH_TERM_KEY = 'searched-term';

export const NON_SGM_RELATED_TO_PLATFORM_SGM = 'SGM';

export const CATALOG_BROWSE_PATH = '/catalog/browse';

export const CATALOG_FOR_YOU_PATH = '/catalog/for-you';

export const CATALOG_PATH = '/catalog';

export const COUNTRY_RESTRICTED_BROWSE_PATH = '/countryrestricted';

export const MARKETS_CATEGORY = 'services.category.markets';

export const DEFAULT_LANGUAGE = 'en';

export const NOTIFICATION_LIST_PATH = '/mynotifications';