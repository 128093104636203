import { http } from 'services/ApiService/Http';
import { Utilities } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

export type Verb = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function fetcher<T>(
    url: string,
    verb: Verb,
    body?: object,
    plainText = false,
): Promise<Nullable<T>> {
    const headers: HeadersInit = [
        plainText ? ['accept', 'text/plain'] : ['accept', 'application/json'],
        ['content-type', 'application/json'],
    ];
    const options = {
        method: verb,
        headers: headers,
        body: body ? JSON.stringify(body) : undefined,
    } as RequestInit;

    const response = await http.fetch(url, options);
    if (plainText && response.ok) {
        return (await response.text()) as T;
    } else {
        return await Utilities.processResponse<T>(response, false, []);
    }
}