import { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { GetIntroTour, setupIntroductionTour } from './introductionTourService';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';

const IntroTour = (state: AppState) => state.introTour;

export const IntroductionTour: FC = () => {
    const dispatch = useDispatch();
    const { hasOnlyB2b2cAccess } = useApplicationContext();
    const { introTour, isLoading } = useSelector(IntroTour);

    const hasIntroTour = !hasOnlyB2b2cAccess;

    useEffect(() => {
        hasIntroTour && dispatch<any>(GetIntroTour());
    }, [dispatch, hasIntroTour]);

    useEffect(() => {
        if (!isLoading && introTour && hasIntroTour) {
            setupIntroductionTour(introTour);
        }
    }, [introTour, isLoading, hasIntroTour]);

    return null;
};