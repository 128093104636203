import { useSelector, shallowEqual } from 'react-redux';
import { Section } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { AppState, ApiRequest } from 'store/store';

export const useBanners = (): {
    loading: boolean,
    bannerSections: Section[],
} => {
    const { state, data } = useSelector<AppState, ApiRequest<Section[]>>(state => state.banner.list, shallowEqual);

    return {
        loading: state === 'isFetching' || state === 'neverFetched',
        bannerSections: data || [],
    };
};

export const useBannerTemplate = (templateUrl: Nullable<string>): {
    loading: boolean,
    templateContent?: string,
} => {
    const { state, data } = useSelector<AppState, ApiRequest<string>>(state => state.banner.templates[templateUrl || ''] || {}, shallowEqual);

    return {
        loading: state === 'isFetching' || state === 'neverFetched',
        templateContent: data,
    };
};
