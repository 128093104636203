import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type ActionLinkButtonProps = {
    labelLngKey: string,
    actionUrl: string,
};

export const ActionLinkButton: FC<ActionLinkButtonProps> = ({ labelLngKey, actionUrl }) => {
    const { t: translate } = useTranslation('my-account');
    return <>
        {!!labelLngKey && !!actionUrl ?
            <a
                href={actionUrl}
                target="_blank" rel="noreferrer"
                className="btn btn-md btn-flat-primary btn-icon-end fw-medium" >
                {translate(labelLngKey)} <i className="ms-1 icon icon-lg">arrow_forward</i>
            </a>
            : <EmptyActionBlock />
        }
    </>;
};

export const EmptyActionBlock = () => <div style={{ minWidth: '87px' }}></div>;