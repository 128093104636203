import React, { FC } from 'react';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';
import { clipPaginateList } from 'components/pages/search/clip-list';
import { SeeMoreLinkPerson } from 'components/pages/search/SeeMoreLink';
import { SourceInfosSearchItemCompactList } from './SourceInfosSearchItemCompactList';
import { useTranslation } from 'react-i18next';
import { FeatureTracked } from 'models/tracking';
import { GetSearchResponse } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

const MAX_PERSON = 3;

type QuicksearchPersonResultsProps = {
    personSearchResultItems: NonNullable<Required<GetSearchResponse['person']>>;
    isLoading: boolean;
    openInNew: boolean;
};

export const QuicksearchPersonResults: FC<QuicksearchPersonResultsProps> = ({
    personSearchResultItems,
    isLoading,
    openInNew,
}) => {
    const { t: translation } = useTranslation('service-list');
    const { canSearchPersonsAndCompanies } = useApplicationContext();
    const { clipped, total, hasMore } = clipPaginateList(personSearchResultItems, MAX_PERSON);
    if (!canSearchPersonsAndCompanies) {
        return <></>;
    }
    if (!isLoading && total === 0) {
        return <></>;
    }
    return (
        <div className="mt-5">
            <h4 className="quicksearch-title text-secondary pb-2 mb-0">
                {translation('personResults')}
            </h4>
            {isLoading ? <EmptyLinePlaceholder count={MAX_PERSON} /> : null}
            {!isLoading ? (
                <SourceInfosSearchItemCompactList
                    data={clipped}
                    feature={FeatureTracked.OpenPerson}
                />
            ) : null}
            {!isLoading && hasMore ? (
                <SeeMoreLinkPerson total={total} openInNew={openInNew} />
            ) : null}
        </div>
    );
};
