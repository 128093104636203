import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import { FormLink } from '../myServicesPage/components/search/FormLink';
import { NoNonSgmServices } from '../myServicesPage/layout/NoServices';
import { clipPaginateList } from './clip-list';
import { SeeMoreLinkSgAll } from './SeeMoreLink';
import { ServiceSearchItemCompactList } from '../myServicesPage/components/search/ServiceSearchItemCompactList';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

type SearchNonSgmResultsProps = {
    limit?: number;
    forceEmptyMessage?: boolean;
};

export const SearchNonSgmResults: React.FC<SearchNonSgmResultsProps> = ({
    limit,
    forceEmptyMessage,
}) => {
    const { sg: { data, state }, sgm: { data: sgmData } } = useSearchItemResults();
    const { isSgGroupCurrentUser } = useApplicationContext();

    const { clipped, total, hasMore } = clipPaginateList(data, limit);
    const isLoading = state === 'isFetching' || state === 'neverFetched';

    if (!isSgGroupCurrentUser) {
        return null;
    }

    if (isLoading) {
        return (
            <div className="mb-5">
                <Title />
                <EmptyLinePlaceholder count={limit} />
            </div>
        );
    }

    if (clipped.length === 0) {
        const hasSgmServices = !!sgmData.items.length;
        const hideEmptyMessage = hasSgmServices && !forceEmptyMessage;

        if (hideEmptyMessage) {
            return null;
        }

        return (
            <div className="mb-5">
                <Title />
                <NoNonSgmServices />
            </div>
        );
    }

    return (
        <div className="mb-5">
            <Title />
            <ServiceSearchItemCompactList
                serviceSearchItemLineInfos={clipped.map((sgmItem) => {
                    return {
                        referenceId: sgmItem.referenceId,
                        title: sgmItem.title,
                        url: sgmItem.url,
                        category: sgmItem.category,
                        initials: sgmItem.initials,
                        searchItemSource: sgmItem.sourceInfos?.at(0)?.source,
                        searchType: SearchResultType.SgItem,
                    };
                })}
            />
            {hasMore && <SeeMoreLinkSgAll total={total} />}
            <FormLink />
        </div>
    );
};

const Title = () => {
    const { t: translate } = useTranslation('service-list');
    return (
        <h2 className="h6 text-secondary py-2 mb-2">
            {translate('service-list:nonSgmServices')}
        </h2>
    );
};
