import React from 'react';
import { SettingListGroup, SettingListGroupItemProps } from '../components/SettingListGroup';
import { ActionLinkButton } from '../components/ActionLinkButton';
import { useMyAccountSetting } from 'components/hooks/myAccount/useMyAccountSetting';

export const NotificationSettings = () => {
    const { setting } = useMyAccountSetting();
    if (!setting || !setting.notificationSettings || setting.notificationSettings.length === 0) {
        return <></>;
    }
    const items: SettingListGroupItemProps[] = setting.notificationSettings.filter(ns => ns.settingUrl).map(setting => {        
        const item: SettingListGroupItemProps = {
            title: setting.name || '',
            titleLngKey: '',
            descriptionLngKey: 'settings-notification-description',
            iconName: '',
            initials: setting.initials || '',
            wrapInLink: !!setting.settingUrl,
            itemUrl: setting.settingUrl || '',
            actionComponent: <ActionLinkButton labelLngKey="label-manage" actionUrl={setting.settingUrl || ''} />,
        };
        return item;
    });
    return <SettingListGroup items={items} titleLngKey="settings-notification" />;
};