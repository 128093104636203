import { NonSgmServiceDto, ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const ServiceBoardServiceFetchRequestActionType =
    '@SERVICES/FETCH_REQUEST';
export const ServiceBoardServiceFetchSuccessActionType =
    '@SERVICES/FETCH_SUCCESS';
export const ServiceBoardServiceFetchFailureActionType =
    '@SERVICES/FETCH_FAILURE';

export type ServiceBoardServiceFetchRequestAction = ReduxAction<
    typeof ServiceBoardServiceFetchRequestActionType,
    {
        payload: {
            key: string
        }
    }
>;

export type ServiceBoardServiceFetchSuccessAction = ReduxAction<
    typeof ServiceBoardServiceFetchSuccessActionType,
    {
        payload: {
            key: string,
            services: Nullable<ServiceDto[]>,
            nonSgmServices: Nullable<NonSgmServiceDto[]>,
        }
    }
>;

export type ServiceBoardServiceFetchFailureAction = ReduxAction<
    typeof ServiceBoardServiceFetchFailureActionType,
    {
        payload: {
            key: string
        }
    }
>;

export const createServiceBoardServiceFetchRequestAction = (key: string): ServiceBoardServiceFetchRequestAction => ({
    type: ServiceBoardServiceFetchRequestActionType,
    payload: {
        key,
    },
});

export function createServiceBoardServiceFetchSuccessAction(
    key: string,
    services: Nullable<ServiceDto[]>,
    nonSgmServices: Nullable<NonSgmServiceDto[]>)
    : ServiceBoardServiceFetchSuccessAction {
    return {
        type: ServiceBoardServiceFetchSuccessActionType,
        payload: {
            key,
            services,
            nonSgmServices,
        },
    };
}

export const createServiceBoardServiceFetchFailureAction = (key: string): ServiceBoardServiceFetchFailureAction => ({
    type: ServiceBoardServiceFetchFailureActionType,
    payload: {
        key,
    },
});

export type ServiceBoardServiceAction =
    | ServiceBoardServiceFetchRequestAction
    | ServiceBoardServiceFetchSuccessAction
    | ServiceBoardServiceFetchFailureAction;