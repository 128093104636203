import classNames from 'classnames';
import React, { FC } from 'react';

type LoaderProps = {
    className?: string,
}
export const Loading: FC<LoaderProps> = ({ className }) =>
    <div className={classNames('spinner m-auto', className)} role="status" aria-hidden="true" />;

export const Blinker: FC<LoaderProps> = ({ className }) =>
    <div className={classNames('blinker', className)} role="status" aria-hidden="true" />;
