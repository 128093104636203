import { Signature } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export const initialState: {
    body: ApiRequest<string>,
    info: ApiRequest<Signature>,
    isSavingInfo: boolean,
} = {
    body: initialApiRequest(),
    info: initialApiRequest(),
    isSavingInfo: false,
} as const;

export type SignatureState = typeof initialState;

const signature: Reducer<SignatureState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SIGNATURE/FETCH_SIGNATURE_BODY':
            return {
                ...state,
                body: {
                    ...state.body,
                    state: 'isFetching',
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_BODY_SUCCESS':
            return {
                ...state,
                body: {
                    ...state.body,
                    data: action.payload.signature || '',
                    state: 'valid',
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_BODY_FAILURE':
            return {
                ...state,
                body: {
                    ...state.body,
                    state: 'error',
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO':
            return {
                ...state,
                info: {
                    ...state.info,
                    state: 'isFetching',
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO_SUCCESS':
            return {
                ...state,
                info: {
                    ...state.info,
                    data: action.payload.info || undefined,
                    state: 'valid',
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO_FAILURE':
            return {
                ...state,
                info: {
                    ...state.info,
                    state: 'error',
                },
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO':
            return {
                ...state,
                isSavingInfo: true,
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO_SUCCESS':
            return {
                ...state,
                isSavingInfo: false,
                info: {
                    ...state.info,
                    state: 'outdated',
                },
                body: {
                    ...state.body,
                    state: 'outdated',
                },
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO_FAILURE':
            return {
                ...state,
                isSavingInfo: false,
            };
        default:
            return state;
    }
};

export default signature;
