import React from 'react';
import { ModalBody } from 'reactstrap';
import { PublicationContent } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type LearnMoreModalBodyProps = {
    sections: PublicationContent[]
};

export const LearnMoreModalBody: React.FunctionComponent<LearnMoreModalBodyProps> = ({
    sections,
}) => (
    <ModalBody className="overflow-visible text-large mt-5" style={{ lineHeight: 1.8 }}>
        {sections.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
                <h2 className="mb-3">{section.bullet}</h2>
                <div
                    className="pb-3"
                    dangerouslySetInnerHTML={{ __html: section.text?.replace('\r\n', '<br/>') ?? '' }}
                />
                {section.pictureUrl && (
                    <div className="mb-3">
                        {section.pictureLink ? (
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={section.pictureLink}
                            >
                                <img
                                    style={{ maxWidth: '100%' }}
                                    src={section.pictureUrl}
                                    alt="illustration with link"
                                />
                            </a>
                        ) : (
                            <img
                                style={{ maxWidth: '100%' }}
                                src={section.pictureUrl}
                                alt="illustration"
                            />
                        )}
                    </div>
                )}
            </React.Fragment>
        ))}
    </ModalBody>
);
