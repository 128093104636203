import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSignatureBody } from 'core/services/signature/signatureService';
import { useSignatureBody } from 'components/hooks/signature/useSignature';
import { EditSignature } from './EditSignature';
import { useTranslation } from 'react-i18next';
import { AccountSignatureSkeleton } from './AccountSignatureSkeleton';

import './AccountSignature.scss';

export const AccountSignature: FC = () => {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation('signature');
    const { loading } = useSignatureBody();
    const { body } = useSignatureBody();
    const [editSignature, setEditSignature] = useState(false);

    useEffect(() => {
        dispatch<any>(getSignatureBody());
    }, [editSignature]);

    const handleOnEditSignature = () => {
        setEditSignature(true);
    };


    if (loading) {
        return <SignatureContentWrapper>
            <AccountSignatureSkeleton />
        </SignatureContentWrapper>;
    }

    if (editSignature) {
        return <SignatureContentWrapper>
            <EditSignature onCancel={() => setEditSignature(false)} onSave={() => setEditSignature(false)} />
        </SignatureContentWrapper>;
    }

    if (body) {
        return <SignatureContentWrapper>
            <div className="d-flex pb-3">
                <h3 className="h3">
                    {translate('title')}
                </h3>
                <button className="btn btn-default ms-auto" onClick={handleOnEditSignature}>
                    {translate('signature:edit')}
                </button>
            </div>
            <div className="d-flex flex-column overflow-hidden signature-preview-border">
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
        </SignatureContentWrapper>;
    }

    return <SignatureContentWrapper>
        <div className="d-flex pb-3">
            <h3 className="h3">
                {translate('title')}
            </h3>
        </div>
        <div className="d-flex flex-column overflow-hidden signature-preview-border flex-center p-0">
            <div className="d-flex flex-column flex-center col-8 col-sm-6 col-md-4 mt-3">
                <i className="icon icon-outlined icon-xl text-secondary mt-3">contact_mail</i>
                <div className="text-secondary h4">{translate('signature:noSignature')}</div>
                <div className="text-secondary mb-2 text-center">{translate('signature:noSignatureText')}</div>
                <button className="btn btn-default mx-auto m-5" onClick={handleOnEditSignature}>
                    {translate('signature:create')}
                </button>
            </div>
        </div>
    </SignatureContentWrapper>;
};

const SignatureContentWrapper: FC<PropsWithChildren> =
    ({ children }) => <div className="my-5 mx-4 mx-lg-5">{children}</div>;