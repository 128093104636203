import React from 'react';
import { times } from 'lodash-es';

const EmptyStateLine: React.FC = () => {
    return (
        <li className="list-group-item p-0 me-1 me-sm-2 border-bottom border-opacity-40">
            <div className="d-flex align-items-center justify-content-between text-primary">
                <div className="d-inline-flex flex-grow-1 align-items-center p-1 p-sm-2">
                    <div className="d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon p-0 bg-lvl3" />
                    <div className="ms-3 d-inline-flex flex-column justify-content-center">
                        <span className="bg-lvl4 fw-semibold line-height-1 ps-5 me-5 text-primary">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span className="bg-lvl3 line-height-1 mt-1 text-secondary w-75">
                            &nbsp;
                        </span>
                    </div>
                </div>
            </div>
        </li>
    );
};

export const EmptyLinePlaceholder: React.FC<{ count?: number }> = ({
    count = 3,
}) => {
    return (
        <ul className="list-group border-top w-100">
            {times(count, (c) => (
                <EmptyStateLine key={c} />
            ))}
        </ul>
    );
};
