import React from 'react';
import { useCustomParamsFromUrl } from 'components/hooks/UseCustomParams';
import { useLocation } from 'react-router-dom';
import { ServiceDescription } from './ServiceDescription';

export const SingleServiceModal = () => {
    const { pathname, search } = useLocation();
    const backPath = pathname.split('/service/')[0];
    const backLink = backPath + search;
    const match = useCustomParamsFromUrl(`${backPath}/service/:serviceKey`);
    if (match) {
        return <ServiceDescription backLink={backLink} />;
    }
    return null;
};
