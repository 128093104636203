import React, { FC } from 'react';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { useMyAccountSetting } from 'components/hooks/myAccount/useMyAccountSetting';
import { openHelpCenter } from 'components/layout/helpCenter/HelpCenter';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { MyAccountSetting } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const GDPR_SGCIB_EMAIL = 'FR-GDPR-SG-CONTACT@sgcib.com';
const GDPR_SGSS_EMAIL = 'FR-MIDDLE-OFFICE-CORPORATE-GDPR@sgss.socgen.com';

export const AccountNotice: FC = () => {
    const { t: translate } = useTranslation('my-account');
    const { hasOnlyB2b2cAccess } = useApplicationContext();
    const { setting, isLoading } = useMyAccountSetting();

    if (isLoading) {
        return <div className="mt-xxl-5 mb-5 mx-3 mx-lg-5">
            <AccountNoticeSkeleton />
        </div>;
    }

    if (!setting || !setting.isVisibleDisclaimerRequestPersonalData) {
        return <></>;
    }

    const computeMailto = (setting: MyAccountSetting): string => {
        const gdprEmail = !hasOnlyB2b2cAccess ? GDPR_SGCIB_EMAIL : GDPR_SGSS_EMAIL;
        let mailto = translate('settings-request-personal-data-mailto', {
            gdprEmail: gdprEmail,
            userFullname: setting.userFullname,
        });
        if (setting.clientFullname) {
            mailto += translate('settings-request-personal-data-mailto-working-for', {
                clientFullname: setting.clientFullname,
                clientBdrCommercialId: setting.clientBdrCommercialId,
            });
        }

        return mailto;
    };

    return (
        <div className="mt-xxl-5 mb-5 mx-3 mx-lg-5">
            <>
                <div className="text-secondary pb-3">
                    <div className="fw-bold pb-3">{translate('settings-personal-data-notice')}</div>
                    <div className="pb-3" dangerouslySetInnerHTML={{
                        __html: translate('settings-disclaimer1'),
                    }} />
                    <div className="py-3" dangerouslySetInnerHTML={{
                        __html: translate('settings-disclaimer2'),
                    }} />
                    <div className="pt-3" dangerouslySetInnerHTML={{
                        __html: translate('settings-disclaimer3'),
                    }} />
                    <div className="pb-3" dangerouslySetInnerHTML={{
                        __html: translate('settings-disclaimer4'),
                    }} />
                    <div className="py-3" dangerouslySetInnerHTML={{
                        __html: translate('settings-disclaimer5'),
                    }} />
                </div>
                <div className="py-3">
                    <a className="btn btn-lg btn-icon-start btn-outline-primary" href={computeMailto(setting || {})}>
                        <i className="icon icon-md">drafts</i><span>{translate('settings-request-personal-data')}</span>
                    </a>
                </div>
                <div className="pt-3 text-secondary">
                    <span>{translate('settings-use-help-center1')}</span>
                    <button className="btn btn-link p-0 fw-bold align-baseline text-decoration-underline" onClick={openHelpCenter} >
                        {translate('settings-use-help-center2')}
                    </button>
                    <span>{translate('settings-use-help-center3')}</span>
                </div>
            </>
        </div>
    );
};

const AccountNoticeSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Skeleton height={76} className="mb-4" />
        </SkeletonWrapper>
    );
};
