import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalizedObject } from 'components/hooks/translation/useLocalizedObject';
import { ListModel } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type ServiceDescriptionFeaturesProps = {
    features: Nullable<ListModel[]>
};

const ICON_SIZE = '45px';

export const ServiceDescriptionFeatures: React.FunctionComponent<ServiceDescriptionFeaturesProps> = ({
    features,
}) => {
    const localize = useLocalizedObject();
    const { t: translate } = useTranslation('service-description');

    if (!features || features.length === 0) {
        return <></>;
    }

    return (
        <div className="mb-4">
            <h2 className="h4 mb-3">
                {translate('service-description:features')}
            </h2>

            <div className="row">
                {features.map((feature, index) => (
                    <div key={index} className="col-lg-6">
                        <div
                            className="bg-opacity-20 bg-secondary rounded-circle my-2 position-relative overflow-hidden"
                            style={{ height: ICON_SIZE, width: ICON_SIZE }}
                            aria-hidden="true"
                        >
                            <Icon icon={feature.icon} />
                        </div>
                        <p key={index} dangerouslySetInnerHTML={{
                            __html: localize(feature) || '',
                        }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

type IconProps = { icon: Nullable<string>; };

const Icon: React.FunctionComponent<IconProps> = ({ icon }) => {
    if (!icon) {
        return <></>;
    }

    const isPe7 = icon.slice(0, 3) === 'pe-';
    const className = isPe7 ? `pe-2x ${icon} ` : 'icon';
    const left = isPe7
        ? '10.5px' // (45px - 28px) / 2
        : '9.5px'; // (45px - 24px) / 2
    return (
        <i
            className={`${className} d-block icon-md text-center position-absolute top-0`}
            style={{
                lineHeight: ICON_SIZE,
                left,
            }}
        >{isPe7 ? null : icon}</i>
    );
};
