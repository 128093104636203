import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, CloseButtonProps } from 'components/common/modal/CloseButton';

type LearnMoreModalHeaderProps = CloseButtonProps & {
    title: Nullable<string>;
    headerImage: Nullable<string>;
    tag: Nullable<string>;
};

export const LearnMoreModalHeader: React.FunctionComponent<LearnMoreModalHeaderProps> = ({
    title,
    headerImage,
    tag,
    closeModal,
}) => {
    const { t: translate } = useTranslation('learn-more');

    const headerStyle: React.CSSProperties = headerImage ? {
        backgroundImage: `linear-gradient(0deg, rgba(250, 250, 250, 0.4) 10%, rgba(250, 250, 250, 0) 25%), url(${headerImage})`,
        backgroundSize: 'cover',
        minHeight: '450px',
    } : {};

    return (
        <div
            className="modal-header position-relative d-flex flex-shrink-0 align-items-end"
            style={headerStyle}
        >
            <div className="d-flex flex-column">
                <div className="subtitle mb-3">
                    <div className="badge badge-primary-alt badge-lg badge-prepend-square">
                        {translate(`learn-more:tag-${tag ?? 'story'}`)}
                    </div>
                </div>
                <h1
                    className="modal-title col-11 display-3 text-black px-0 mb-4"
                    id="serviceDescriptionTitle"
                    style={{ lineHeight: '1.2' }}
                >
                    {title}
                </h1>
            </div>

            <div className="ms-auto me-5">
                <div className="position-fixed" style={{ top: '3rem', zIndex: 1100 }}>
                    <CloseButton closeModal={closeModal} />
                </div>
            </div>
        </div>
    );
};
