export const ExternalServiceOnlySetShowSuccessActionType =
    '@EXTERNAL_SERVICE/SET_SHOW_ONLY_SUCCESS';

export type ExternalServiceSetShowOnlySuccessAction = ReduxAction<
    typeof ExternalServiceOnlySetShowSuccessActionType,
    { payload: boolean }
>;

export function createExternalServiceSetOnlySuccessAction(showExternalServiceOnly: boolean): ExternalServiceSetShowOnlySuccessAction {
    return {
        type: ExternalServiceOnlySetShowSuccessActionType,
        payload: showExternalServiceOnly,
    };
}

export type ExternalServiceAction = ExternalServiceSetShowOnlySuccessAction;