import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { scrollUp } from 'core/utils/animations';
import { useSearchTerm } from 'components/hooks/search/useSearchTerm';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { FeatureTracked } from 'models/tracking';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import { SearchSgmFilterMenu } from './SearchSgmFilterMenu';
import classNames from 'classnames';
import { ApiRequest } from 'store/store';

const SearchNavValues = [
    '',
    'sgmarkets',
    'societe',
    'person',
    'company',
] as const;
export type SearchNav = typeof SearchNavValues[number];

export const transformStringToSearchNav = (search: string): SearchNav => {
    const type = search.toLocaleLowerCase();

    if (SearchNavValues.includes(type as SearchNav)) {
        return type as SearchNav;
    }
    return '';
};

type SearchMenuProps = {
    active: SearchNav;
};

const isLoading = (request: ApiRequest<unknown>): boolean =>  request.state === 'isFetching' ||  request.state === 'neverFetched';

export const SearchMenu: React.FunctionComponent<SearchMenuProps> = ({
    active,
}) => {
    const { t: translate } = useTranslation('service-list');
    const { trackAction } = useServiceTracking();
    const { canSearchPersonsAndCompanies, isSgGroupCurrentUser } = useApplicationContext();
    const { actionTrackedInfo } = useActionTrackingContext();
    const { sgm, sg, person, company } = useSearchItemResults();

    const trackFilter = async (featureTracked: FeatureTracked) => {
        await trackAction(featureTracked, actionTrackedInfo);
    };

    return (
        <>
            <SearchOption
                active={active}
                to=""
                title={translate('service-list:bestResults')}
            />

            <SearchOption
                count={isLoading(sgm) ? undefined : sgm.data.searchMetaPageData.totalCount}
                title={translate('service-list:sgmResults')}
                active={active}
                to="sgmarkets"
                onSearchOptionClick={async () =>
                    await trackFilter(FeatureTracked.FilterSGMResults)
                }
            >
                <SearchSgmFilterMenu />
            </SearchOption>
            {isSgGroupCurrentUser && <SearchOption
                count={isLoading(sg) ? undefined : sg.data.searchMetaPageData.totalCount}
                title={translate('service-list:nonSgmResults')}
                active={active}
                to="societe"
                onSearchOptionClick={async () =>
                    await trackFilter(FeatureTracked.FilterSGResults)
                }
            />}
            {canSearchPersonsAndCompanies ? (
                <>
                    <SearchOption
                        count={isLoading(person) ? undefined : person.data.searchMetaPageData.totalCount}
                        active={active}
                        title={translate('service-list:personResults')}
                        to="person"
                        onSearchOptionClick={async () =>
                            await trackFilter(
                                FeatureTracked.FilterPersonResults,
                            )
                        }
                    />
                    <SearchOption
                        count={isLoading(company) ? undefined : company.data.searchMetaPageData.totalCount}
                        title={translate('service-list:companyResults')}
                        active={active}
                        to="company"
                        onSearchOptionClick={async () =>
                            await trackFilter(
                                FeatureTracked.FilterCompanyResults,
                            )
                        }
                    />
                </>
            ) : null}
        </>
    );
};

type SearchOptionProps = {
    active: SearchNav;
    title: string;
    count?: number;
    to: SearchNav;
    onSearchOptionClick?: () => Promise<void>;
};

const SearchOption: React.FunctionComponent<
    PropsWithChildren<SearchOptionProps>
> = ({ active, to, onSearchOptionClick, count, title, children }) => {
    const { getSearchRoute } = useSearchTerm();
    const linkTo = getSearchRoute(to);
    const isActive = to === active;
    const onLinkClicked = async () => {
        scrollUp();
        if (onSearchOptionClick) {
            await onSearchOptionClick();
        }
    };
    return (
        <div className={`d-block p-0 mb-1 ${isActive ? 'fw-bold text-primary' : ''}`}>
            <Link
                to={linkTo}
                onClick={onLinkClicked}
                className="d-flex justify-content-between align-items-center flex-wrap w-100 m-0 p-3 bg-lvl2"
            >
                <label className="m-0">{title}</label>
                <div className="d-flex justify-content-between align-items-center">
                    {count && count > 0 ? (
                        <span className="badge rounded-pill badge-secondary badge-md me-2">
                            {count}
                        </span>
                    ) : null}
                    <CheckboxInput isActive={isActive} />
                </div>
            </Link>
            {children}
        </div>
    );
};

const CheckboxInput = ({ isActive }: { isActive: boolean }) => {
    const size = '20px';
    return (
        <button
            className="btn btn-discreet p-0 d-inline-block"
            style={{ width: size }}
            type="button"
        >
            <i
                className={classNames('icon', { 'text-info': isActive })}
                style={{ fontSize: size, fontWeight: 100 }}
            >
                {isActive ? 'radio_button_checked' : 'radio_button_unchecked'}
            </i>
        </button>
    );
};
