export const prefersFullMotion = (): boolean => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    return mediaQuery && !mediaQuery.matches;
};

export const scrollUp = () => {
    if (prefersFullMotion()) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};
