import React, { FC } from 'react';
import { HighlightLayout } from './HighlightLayout';
import { MyHighlightLayoutDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type HighlightLayoutsProps = {
    layouts: Nullable<MyHighlightLayoutDto[]>
};

export const HighlightLayouts: FC<HighlightLayoutsProps> = ({ layouts }) => {
    if (!layouts) {
        return <></>;
    }
    const hasNoLayoutWithItems = layouts?.some(l => l.items && l.items.length > 0) || false;
    return (<>
        {hasNoLayoutWithItems && layouts.map((hl, index) =>
            <div className="mb-3 mb-md-4 mb-lg-5" key={index}>
                <HighlightLayout layout={hl} key={index} />
            </div>,
        )}
    </>);
};