import { Options } from 'intro.js/src/option';

export const IntroTourFetchRequestActionType =
    '@INTRO_TOUR/FETCH_REQUEST';
export const IntroTourFetchSuccessActionType =
    '@INTRO_TOUR/FETCH_SUCCESS';
export const IntroTourFetchFailureActionType =
    '@INTRO_TOUR/FETCH_FAILURE';

export type IntroTourFetchRequestAction = ReduxAction<
    typeof IntroTourFetchRequestActionType
>;
export type IntroTourFetchSuccessAction = ReduxAction<
    typeof IntroTourFetchSuccessActionType,
    { payload: Nullable<Options> }
>;
export type IntroTourFetchFailureAction = ReduxAction<
    typeof IntroTourFetchFailureActionType
    >;

export const createIntroTourFetchRequestAction = (): IntroTourFetchRequestAction => ({
    type: IntroTourFetchRequestActionType,
});

export function createIntroTourFetchSuccessAction(
    user: Nullable<Options>,
): IntroTourFetchSuccessAction {
    return {
        type: IntroTourFetchSuccessActionType,
        payload: user,
    };
}

export const createIntroTourFetchFailureAction = (): IntroTourFetchFailureAction => ({
    type: IntroTourFetchFailureActionType,
});

export type IntroTourAction =
    | IntroTourFetchRequestAction
    | IntroTourFetchSuccessAction
    | IntroTourFetchFailureAction;
