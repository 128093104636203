import { combineReducers } from 'redux';
import {
    BookmarkFetchSuccessActionType,
    BookmarkFetchRequestActionType,
    BookmarkFetchFailureActionType,
    BookmarkCreateSuccessActionType,
    BookmarkDeleteSuccessActionType,

} from '../actions/bookmarkAction';
import { ActionType } from 'store/actions/ActionType';
import { BookmarkDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const items = (state: Nullable<BookmarkDto[]> = null, action: ActionType) => {
    switch (action.type) {
        case BookmarkFetchSuccessActionType:
            {
                return action.payload;
            }
        case BookmarkCreateSuccessActionType:
            {
                if (action.payload && action.payload.Id) {
                    const newBookmark: BookmarkDto = {
                        Id: action.payload?.Id,
                        ReferenceId: action.payload?.ReferenceId,
                        Origin: action.payload?.Origin,
                        PageUrl: action.payload?.PageUrl,
                        Title: action.payload?.Title,
                        BeginDate: action.payload?.BeginDate,
                        IcId: action.payload?.IcId,
                        Initials: action.payload?.Initials,
                    };

                    return [...(state || []), newBookmark];
                }

                return state;
            }
        case BookmarkDeleteSuccessActionType:
            {
                if (action.payload > 0) {
                    return (state || []).filter(b => b.Id !== action.payload);
                }

                return state;
            }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case BookmarkFetchRequestActionType: {
            return true;
        }
        case BookmarkFetchFailureActionType:
        case BookmarkFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    items,
    isLoading,
});
