import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { ThunkResult } from 'store/store';
import {
    createFetchBannersAction,
    createFetchBannersSuccessAction,
    createFetchBannersFailureAction,
    createFetchBannerTemplateAction,
    createFetchBannerTemplateSuccessAction,
    createFetchBannerTemplateFailureAction,
} from 'store/actions/bannerAction';
import { needReload } from 'store/store-tools';
import { BannerApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';
import { fetcher } from '../common/http/fetcher';

export const getBanners = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { banner: { list: request } } = state();

        if (!needReload(request)) {
            return;
        }

        dispatch(createFetchBannersAction());
        try {
            const banners = await BannerApi.getAllBanners();
            dispatch(createFetchBannersSuccessAction(banners));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createFetchBannersFailureAction());
        }
    };
};

export const getBannerTemplate = (templateUrl: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { banner: { templates: request } } = state();

        if (!needReload(request[templateUrl])) {
            return;
        }

        dispatch(createFetchBannerTemplateAction(templateUrl));
        try {
            const templateContent = await fetcher<string>(templateUrl, 'GET', undefined, true);
            dispatch(createFetchBannerTemplateSuccessAction(templateUrl, templateContent));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createFetchBannerTemplateFailureAction(templateUrl));
        }
    };
};

