import { ApiRequest } from './store';

export const mergeRecords = <T extends string | number, V>(records: Record<T, ApiRequest<V>>, key: T, data: ApiRequest<V>) => {
    return {
        ...records,
        [key]: {
            ...records[key],
            ...data,
        },
    };
};

export const initialApiRequest = <T>(): ApiRequest<T> => ({
    state: 'neverFetched',
});

export const needReload = (apiRequest: ApiRequest<unknown> | undefined) => {
    const { state } = apiRequest ?? { state: 'neverFetched' };
    return state === 'neverFetched' || state === 'outdated' || state === 'error';
};
