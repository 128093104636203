import { useTranslation } from 'react-i18next';
import { PRODUCER_CODE_ALL_KEY, useNotificationListFilter } from './useNotificationListFilter';
import { useNotificationCountByProducer } from './useNotificationCountByProducer';

type UseNotificationTitle = {
    title: string;
};

export const useNotificationTitle = (): UseNotificationTitle => {
    const { t: translate } = useTranslation('my-notification');
    const { producerCodeActive } = useNotificationListFilter();
    const { getProducerName } = useNotificationCountByProducer();
    
    const title = producerCodeActive === PRODUCER_CODE_ALL_KEY
        ? translate('listTitleAll')
        : translate('listTitle', { producerName: getProducerName(producerCodeActive) });

    return {
        title,
    };
};
