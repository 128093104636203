import React, { FC } from 'react';
import { ProducerInfo, ProducerUniverse } from 'services/ApiService/Notification/NotificationApiClient';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export type ProducerItemProps = {
    producer: ProducerInfo | undefined,
    className?: string,
};

export const ProducerItem: FC<ProducerItemProps> = ({ producer, className }) => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextCountByProducerIcon');

    if (!producer) {
        return false;
    }

    const isSgmUniverse = producer.universe === ProducerUniverse.SgMarkets;
    return (
        <span className={classNames('fw-medium text-truncate', className)}>
            <span
                className={classNames('d-inline-flex flex-center-y fw-semibold text-dark me-2 border border-opacity-40 border-radius-4 overflow-hidden fs-12', {
                    'bg-lvl1': isSgmUniverse,
                    'bg-lvl3': !isSgmUniverse,
                })}
                style={{
                    width: '24px',
                    height: '24px',
                }}
                aria-label={ariaTextIcon}
                title={ariaTextIcon}
            >
                {producer.initials}
            </span>
            {producer.name}
        </span>
    );
};
