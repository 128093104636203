import { combineReducers } from 'redux';
import {
    RecommendationsFetchSuccessActionType,
    RecommendationsFetchRequestActionType,
    RecommendationsFetchFailureActionType,
} from '../actions/recommendationsAction';
import { ActionType } from 'store/actions/ActionType';
import { ServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

const recommendedServices = (
    state: Nullable<ServiceDto[]> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case RecommendationsFetchSuccessActionType:
            {
                return action.payload;
            }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case RecommendationsFetchRequestActionType: {
            return true;
        }
        case RecommendationsFetchFailureActionType:
        case RecommendationsFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    recommendedServices,
    isLoading,
});
