import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type LearnMoreCardTranslation = {
    translate: (
        defaultValue: Nullable<string>,
        translation: Nullable<string>
    ) => Nullable<string>;
};

export const useLearnMoreCardTranslation = (): LearnMoreCardTranslation => {
    const { i18n } = useTranslation();

    const translate = useCallback(
        (
            defaultValue: Nullable<string>,
            translation: Nullable<string>,
        ): Nullable<string> => {
            if (i18n.language.toUpperCase() === 'FR') {
                return translation || defaultValue;
            }
            return defaultValue;
        },
        [i18n.language],
    );

    return { translate };
};
