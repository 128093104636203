import { ApplicationContext } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const ApplicationContextFetchRequestActionType =
    '@APPLICATION_CONTEXT/FETCH_REQUEST';
export const ApplicationContextFetchSuccessActionType =
    '@APPLICATION_CONTEXT/FETCH_SUCCESS';
export const ApplicationContextFetchFailureActionType =
    '@APPLICATION_CONTEXT/FETCH_FAILURE';

export type ApplicationContextFetchRequestAction = ReduxAction<
    typeof ApplicationContextFetchRequestActionType
>;
export type ApplicationContextFetchSuccessAction = ReduxAction<
    typeof ApplicationContextFetchSuccessActionType,
    { payload: Nullable<ApplicationContext> }
>;
export type ApplicationContextFetchFailureAction = ReduxAction<
    typeof ApplicationContextFetchFailureActionType
>;

export const createApplicationContextFetchRequestAction = (): ApplicationContextFetchRequestAction => ({
    type: ApplicationContextFetchRequestActionType,
});

export function createApplicationContextFetchSuccessAction(
    user: Nullable<ApplicationContext>,
): ApplicationContextFetchSuccessAction {
    return {
        type: ApplicationContextFetchSuccessActionType,
        payload: user,
    };
}

export const createApplicationContextFetchFailureAction = (): ApplicationContextFetchFailureAction => ({
    type: ApplicationContextFetchFailureActionType,
});

export type ApplicationContextAction =
    | ApplicationContextFetchRequestAction
    | ApplicationContextFetchSuccessAction
    | ApplicationContextFetchFailureAction;
