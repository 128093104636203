import { EssentialServiceDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const EssentialServicesFetchRequestActionType = '@ESSENTIAL_SERVICES/FETCH_REQUEST';
export const EssentialServicesFetchSuccessActionType = '@ESSENTIAL_SERVICES/FETCH_SUCCESS';
export const EssentialServicesFetchFailureActionType = '@ESSENTIAL_SERVICES/FETCH_FAILURE';

export type EssentialServicesFetchRequestAction = ReduxAction<
    typeof EssentialServicesFetchRequestActionType
>;
export type EssentialServicesFetchSuccessAction = ReduxAction<
    typeof EssentialServicesFetchSuccessActionType,
    { payload: EssentialServiceDto[] }
>;
export type EssentialServicesFetchFailureAction = ReduxAction<
    typeof EssentialServicesFetchFailureActionType
>;

export const createEssentialServicesFetchRequestAction = (): EssentialServicesFetchRequestAction => ({
    type: EssentialServicesFetchRequestActionType,
});

export function createEssentialServicesFetchSuccessAction(
    essentialServices: EssentialServiceDto[],
): EssentialServicesFetchSuccessAction {
    return {
        type: EssentialServicesFetchSuccessActionType,
        payload: essentialServices,
    };
}

export const createEssentialServicesFetchFailureAction = (): EssentialServicesFetchFailureAction => ({
    type: EssentialServicesFetchFailureActionType,
});

export type EssentialServicesAction =
    | EssentialServicesFetchRequestAction
    | EssentialServicesFetchSuccessAction
    | EssentialServicesFetchFailureAction;
