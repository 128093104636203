import { useSelector } from 'react-redux';
import { Path, useLocation } from 'react-router-dom';
import { AppState } from 'store/store';

const getSearchTerm = (state: AppState) => state.search.searchTerm;

type UseSearchTermResponse = {
    searchTerm: Nullable<string>;
    getSearchRoute: (subRoute?: string) => Partial<Path>;
};

export const useSearchTerm = (): UseSearchTermResponse => {
    const searchTerm = useSelector(getSearchTerm);
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const getSearchRoute = (subRoute?: string): Partial<Path> => ({
        pathname: `/search/${encodeURIComponent(searchTerm ?? '')}${
            subRoute ? `/${subRoute}` : ''
        }`,
        search: params.toString() ? `?${params.toString()}` : '',
    });
    return {
        searchTerm,
        getSearchRoute,
    };
};
