import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContentProps, TypeOptions } from 'react-toastify';
import { useDate } from 'services/date';
import { openHelpCenterForm } from 'components/layout/helpCenter/HelpCenter';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string,
    message: string,
};

export const ToastMessage: FC<ToastMessageProps> = ({
    title,
    message,
    closeToast,
    toastProps,
}) => {
    const { t: translate } = useTranslation('tooltip');
    const { formatTimeSec } = useDate();

    const toastTitle = title || translate(getGenericTitle(toastProps?.type));

    return (
        <>
            <div className="notification-header">
                <span className="fw-medium me-auto text-nowrap text-truncate">{toastTitle}</span>
                <span className="notification-timestamp text-nowrap">
                    {formatTimeSec(new Date())}
                </span>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeToast} />
            </div>
            <div className="toast-body text-primary">
                {message}
                {toastProps?.type === 'error' && <div className="mt-2">
                    {translate('errorAlertButtomMessage1')}&nbsp;
                    <button className="btn btn-link text-decoration-underline p-0 m-0" onClick={openHelpCenterForm}>
                        {translate('errorAlertButtomMessage2')}
                    </button>
                    &nbsp;{translate('errorAlertButtomMessage3')}
                </div>}
            </div>
        </>
    );
};

const getGenericTitle = (type: TypeOptions | undefined): string => {
    switch (type) {
        case 'error':
        case 'warning':
        case 'success':
            return `${type}AlertTitle`;
        default:
            return 'infoAlertTitle';
    }
};
