import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { ThunkResult } from 'store/store';
import {
    createFetchSignatureBodyAction,
    createFetchSignatureBodySuccessAction,
    createFetchSignatureBodyFailureAction,
    createFetchSignatureInfoAction,
    createFetchSignatureInfoSuccessAction,
    createFetchSignatureInfoFailureAction,
    createSaveSignatureInfoAction,
    createSaveSignatureInfoSuccessAction,
    createSaveSignatureInfoFailureAction,
} from 'store/actions/signatureAction';
import { toastError, toastSuccess } from 'components/common/toaster/toast';
import { needReload } from 'store/store-tools';
import { SignatureApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';
import { Signature } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const getSignatureBody = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { signature: { body: request } } = state();

        if (!needReload(request)) {
            return;
        }

        dispatch(createFetchSignatureBodyAction());
        try {
            const signature = await SignatureApi.getSignatureBodyForCurrentUser();
            dispatch(createFetchSignatureBodySuccessAction(signature.SignatureBody));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createFetchSignatureBodyFailureAction());
        }
    };
};

export const getSignatureInfo = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { signature: { info: request } } = state();

        if (!needReload(request)) {
            return;
        }

        dispatch(createFetchSignatureInfoAction());
        try {
            const info = await SignatureApi.getSignature();
            dispatch(createFetchSignatureInfoSuccessAction(info));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createFetchSignatureInfoFailureAction());
        }
    };
};

export const saveSignatureInfo = (signatureInfo: Signature): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger, translate },
    ) => {
        const { signature: { isSavingInfo } } = state();

        if (isSavingInfo) {
            return;
        }

        dispatch(createSaveSignatureInfoAction());
        try {
            await SignatureApi.saveSignature(signatureInfo);

            toastSuccess(translate('signature:saveSuccesfull'));

            dispatch(createSaveSignatureInfoSuccessAction());
        } catch (error: any) {
            logger.error(error);
            toastError(translate('signature:saveError'));
            dispatch(createSaveSignatureInfoFailureAction());
        }
    };
};
