import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { Loading } from 'components/common/loading/Loading';
import { useLocalizedObject } from 'components/hooks/translation/useLocalizedObject';
import {
    CloseButton,
    CloseButtonProps,
} from 'components/common/modal/CloseButton';
import { useCustomParamsFromUrl } from 'components/hooks/UseCustomParams';
import { useLearnMoreCardTranslation } from 'components/hooks/translation/useLearnMoreCardTranslation';
import { AppState } from 'store/store';
import { LearnMoreModalHeader } from './LearnMoreModalHeader';
import { LearnMoreModalBody } from './LearnMoreModalBody';
import { LearnMoreItem, LearnMoreItemContent } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type LearnMoreModalContentProps = {
    backLink: string;
};

const retrieveLearnMore = (id: number) => (state: AppState) => {
    return {
        isLoading: state.learnMore.isLoading,
        item: (state.learnMore.items || [])
            .find(x => x.id === id),
    };
};

const useCompleteUrlLearnMoreMatch = () => {
    const { pathname } = useLocation();
    const backPath = pathname.split('/news/')[0];
    return useCustomParamsFromUrl(`${backPath}/news/:newsId/:language`);
};

export const LearnMoreModalContent: React.FunctionComponent<
    LearnMoreModalContentProps
> = ({ backLink }) => {
    const match = useCompleteUrlLearnMoreMatch();
    const navigate = useNavigate();

    const newsIdNumber = parseInt(match?.newsId ?? '', 10) || 0;
    const { isLoading, item } = useSelector(retrieveLearnMore(newsIdNumber));

    const closeModal = () => {
        navigate(backLink);
    };

    return (
        <Modal
            isOpen={true}
            className="modal-top modal-lg"
            contentClassName="overflow-auto"
            aria-labelledby="learnMoreTitle"
            fullscreen="lg"
        >
            {isLoading ? (
                <LoadingModal closeModal={closeModal} />
            ) : item ? (
                <LearnMoreModal
                    learnMore={item}
                    language={match?.language ?? 'en'}
                    closeModal={closeModal}
                />
            ) : (
                <NotFoundModal closeModal={closeModal} />
            )}
        </Modal>
    );
};

const LoadingModal: React.FunctionComponent<CloseButtonProps> = ({
    closeModal,
}) => (
    <>
        <div className="modal-header">
            <Loading />
            <CloseButton closeModal={closeModal} />
        </div>
        <ModalBody />
    </>
);

const NotFoundModal: React.FunctionComponent<CloseButtonProps> = ({
    closeModal,
}) => {
    const { t: translate } = useTranslation('learn-more');
    return (
        <>
            <SimpleModalHeader closeModal={closeModal}>
                {translate('learn-more:notFoundTitle')}
            </SimpleModalHeader>
            <ModalBody>{translate('learn-more:notFoundBody')}</ModalBody>
        </>
    );
};

const SimpleModalHeader: React.FunctionComponent<
    PropsWithChildren<CloseButtonProps>
> = ({ children, closeModal }) => (
    <div className="modal-header">
        <h3 className="modal-title h3" id="learnMoreTitle">
            {children}
        </h3>
        <CloseButton closeModal={closeModal} />
    </div>
);

type ServiceProps = {
    learnMore: LearnMoreItem;
};

type SimpleProps = ServiceProps & CloseButtonProps;
type DescriptionProps = SimpleProps & { language: string };

const LearnMoreModal: React.FunctionComponent<DescriptionProps> = ({
    learnMore,
    closeModal,
    language,
}) => {
    if (learnMore.content) {
        return (
            <DescriptionFullContentModal
                learnMore={learnMore}
                language={language}
                closeModal={closeModal}
            />
        );
    }
    return (
        <DescriptionSimpleModal learnMore={learnMore} closeModal={closeModal} />
    );
};

const DescriptionSimpleModal: React.FunctionComponent<SimpleProps> = ({
    learnMore,
    closeModal,
}) => {
    const { t: translate } = useTranslation('learn-more');
    const { translate: learnMoreTranslation } = useLearnMoreCardTranslation();
    const title = learnMoreTranslation(learnMore.title, learnMore.title_fr);
    const contentUrl = learnMoreTranslation(
        learnMore.content_url,
        learnMore.content_url_fr,
    );
    return (
        <>
            <SimpleModalHeader closeModal={closeModal}>
                {title}
            </SimpleModalHeader>
            <ModalBody>
                <>
                    {learnMore.image_url && (
                        <div>
                            <img
                                style={{ maxWidth: '100%' }}
                                src={learnMore.image_url}
                                alt="news"
                            />
                            <hr />
                        </div>
                    )}
                    {contentUrl && <a
                        rel="noreferrer"
                        target="_blank"
                        className="btn btn-link btn-text-icon"
                        href={contentUrl}
                    >
                        {translate('learn-more:read')}{' '}
                        <i className="icon icon-md">open_in_new</i>
                    </a>}
                </>
            </ModalBody>
        </>
    );
};

const DescriptionFullContentModal: React.FunctionComponent<
    DescriptionProps
> = ({ learnMore, language, closeModal }) => {
    const localize = useLocalizedObject(language);

    if (!learnMore.content) {
        return null;
    }

    const content = localize<LearnMoreItemContent>(learnMore.content);
    if (!content) {
        return null;
    }

    const { contents, title } = content;
    const headerImage =
        contents && contents.length > 0 && contents[0].pictureUrl;

    const sections = contents
        ? headerImage
            ? contents.slice(1)
            : contents
        : [];

    return (
        <>
            <LearnMoreModalHeader
                closeModal={closeModal}
                title={title}
                headerImage={headerImage || null}
                tag={learnMore.tag}
            />
            <LearnMoreModalBody sections={sections} />
        </>
    );
};
