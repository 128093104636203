import { uniqueId } from 'lodash-es';
import { Placement } from 'popper.js';
import React, { useMemo } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type TooltipButtonProps = ButtonProps & {
    tooltipId: string;
    placement?: Placement;
    children: React.ReactElement;
};

export const TooltipButton: React.FunctionComponent<TooltipButtonProps> = ({
    tooltipId,
    id,
    children,
    placement,
    ...restProps
}) => {
    const { t: translate } = useTranslation('tooltip');
    const label = translate(tooltipId);

    const buttonId = useMemo(() => id || uniqueId('tooltipTarget'), [id]);

    return (
        <Button id={buttonId} aria-label={label} {...restProps}>
            <DefaultTooltip text={label} placement={placement} >
                {children}
            </DefaultTooltip>
        </Button>
    );
};

type DefaultTooltipProps = {    
    text: string;
    placement?: Placement;
    enabled?: boolean;
    children: React.ReactElement;
};

export const DefaultTooltip: React.FunctionComponent<DefaultTooltipProps> = ({    
    text,
    placement = 'right',
    enabled = true,
    children,
}) => {
    if (!enabled) {
        return children;
    }
    return (
        <OverlayTrigger placement={placement} overlay={<Tooltip id={uniqueId('defaultTooltip')}>{text}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );
};