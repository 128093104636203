import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { useDispatch } from 'react-redux';
import { consentUpdateQumuAction } from 'store/actions/consentAction';

export interface JSXElementProps {
    [key: string]: any;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-mini-footer': JSXElementProps;
            'sgwt-splash-screen': any;
        }
    }
}

interface CookiesConsentElement {
    getUserConsentOnQumu: () => Promise<boolean>;
}

interface SplashScreenElement {
    show: () => void;
}

interface FooterProps {
    withoutBackground?: boolean;
}

export const Footer: FC<FooterProps> = ({ withoutBackground = false }) => {
    const dispatch = useDispatch();
    const { hasOnlyB2b2cAccess } = useApplicationContext();

    useEffect(() => {
        const updateConsent = () => {
            const widget:
                | (Element & CookiesConsentElement)
                | null = document.querySelector('sgwt-mini-footer');
            widget
                ?.getUserConsentOnQumu()
                .then((consent: boolean) =>
                    dispatch(consentUpdateQumuAction(consent)),
                );
        };

        document.addEventListener(
            'sgwt-mini-footer--current-user-consent',
            updateConsent,
        );
        document.addEventListener(
            'sgwt-mini-footer--user-consent-changed',
            updateConsent,
        );
        return () => {
            document.removeEventListener(
                'sgwt-mini-footer--current-user-consent',
                updateConsent,
            );
            document.removeEventListener(
                'sgwt-mini-footer--user-consent-changed',
                updateConsent,
            );
        };
    }, [dispatch]);

    useEffect(() => {
        const widget:
            | (Element & SplashScreenElement)
            | null = document.querySelector('sgwt-splash-screen');
        if (typeof widget?.show !== 'function') {
            widget?.addEventListener('sgwt-splash-screen--ready', () => {
                widget.show();
            });
        } else {
            widget?.show();
        }
    }, []);

    const mode = hasOnlyB2b2cAccess ? 'b2b2c' : 'sg-markets';
    const application = hasOnlyB2b2cAccess
        ? 'service-board-b2b2c'
        : 'service-board';
    const noBorder = withoutBackground ? false : true;
    const miniFooterProps: JSXElementProps = {
        'id': 'sgwt-mini-footer',
        'mode': mode,
        'type': 'compact',
        'design': 'light',
        'accessibility-compliance': 'partial',
        'accessibility-link': '/accessibility-statement.html',
    };
    if (hasOnlyB2b2cAccess) {
        miniFooterProps['contact-us'] = '{"link":"https://sharinbox.sgmarkets.com/nousContacter"}';
    }
    else {
        miniFooterProps['contact-us-by-help-center'] = 'sgwt-help-center';
    }
    return (
        <div className={classNames('w-100', { 'bg-lvl2': withoutBackground })}>
            <sgwt-mini-footer
                {...miniFooterProps}
            />
            <sgwt-splash-screen
                id="sgwt-splash-screen"
                application={application}
                sg-connect-support="sg-connect-v2"
                no-border={noBorder}
            />
        </div>
    );
};
