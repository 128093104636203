import React from 'react';
import { useCustomParamsFromUrl } from 'components/hooks/UseCustomParams';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { LearnMoreModalContent } from './LearnMoreModalContent';

const useMatchUrl = (url: string) => {
    return !!useMatch(url);
};

export const LearnMoreModal = () => {
    const { search } = useLocation();
    const backPath = useBackPath();
    const backLink = backPath + search;
    const isMatchPartial = useMatchUrl(`${backPath}/news/:newsId`);
    const isMatchComplete = useMatchUrl(`${backPath}/news/:newsId/:language`);
    if (isMatchPartial || isMatchComplete) {
        if (isMatchComplete) {
            return <LearnMoreModalContent backLink={backLink} />;
        }
        return <LanguageRedirection />;
    }

    return null;
};

const LanguageRedirection = () => {
    const backPath = useBackPath();
    const match = useCustomParamsFromUrl(`${backPath}/news/:newsId`);
    const {
        i18n: { language },
    } = useTranslation();
    return (
        <Navigate
            replace={true}
            to={`${backPath}/news/${match?.newsId}/${language}`}
        />
    );
};

const useBackPath = () => {
    const { pathname } = useLocation();
    return pathname.split('/news/')[0];
};
