import React, { FC } from 'react';
import { NotificationCountByProducerResponse } from 'services/ApiService/Notification/NotificationApiClient';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ProducerItem } from './ProducerItem';

type ProducerCountItemProps = {
    countByProducer: NotificationCountByProducerResponse,
    showTopSeparator?: boolean,
    index?: number,
};

export const ProducerCountItem: FC<ProducerCountItemProps> = ({ countByProducer }) => {
    const { producerCodeActive, readStatusActive, getFilterNavigatePath, isAllProducer } = useNotificationListFilter();
    const isAllCountLabel = isAllProducer(countByProducer.producer?.code ?? '');
    const isActive = countByProducer.producer?.code?.toLocaleLowerCase() === producerCodeActive.toLocaleLowerCase();

    return (
        <li className="nav-item w-100">
            <Link
                className={classNames('nav-link fs-14 d-flex justify-content-between align-items-center', { 'active': isActive })}
                to={getFilterNavigatePath(countByProducer.producer?.code, readStatusActive)}
            >
                {isAllCountLabel
                    ? <span className="fw-medium">{countByProducer.producer?.name}</span>
                    : <ProducerItem producer={countByProducer.producer} />}
                <div
                    className="badge badge-flat-info rounded-pill ms-2 me-3"
                    style={{ minWidth: 30 }}
                >
                    {countByProducer.unreadCount ? countByProducer.unreadCount : ' '}
                </div>
            </Link>
        </li>
    );
};