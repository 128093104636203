export const PreferredLanguageFetchRequestActionType = '@PREFERRED_LANGUAGE/FETCH_REQUEST';
export const PreferredLanguageFetchSuccessActionType = '@PREFERRED_LANGUAGE/FETCH_SUCCESS';
export const PreferredLanguageFetchFailureActionType = '@PREFERRED_LANGUAGE/FETCH_FAILURE';

export type PreferredLanguageFetchRequestAction = ReduxAction<
    typeof PreferredLanguageFetchRequestActionType
>;

export type PreferredLanguageFetchSuccessAction = ReduxAction<
    typeof PreferredLanguageFetchSuccessActionType,
    { payload: string }
>;

export type PreferredLanguageFetchFailureAction = ReduxAction<
    typeof PreferredLanguageFetchFailureActionType
>;

export function createPreferredLanguageFetchRequestAction(): PreferredLanguageFetchRequestAction {
    return {
        type: PreferredLanguageFetchRequestActionType,
    };
}

export function createPreferredLanguageFetchSuccessAction(language: string): PreferredLanguageFetchSuccessAction {
    return {
        type: PreferredLanguageFetchSuccessActionType,
        payload: language,
    };
}

export function createPreferredLanguageFetchFailureAction(): PreferredLanguageFetchFailureAction {
    return {
        type: PreferredLanguageFetchFailureActionType,
    };
}

export type PreferredLanguageAction =
    PreferredLanguageFetchRequestAction
    | PreferredLanguageFetchSuccessAction
    | PreferredLanguageFetchFailureAction

export const ChangeAvailableLanguagesActionType = '@PREFERRED_LANGUAGE/CHANGE_AVAILABLE_LANGUAGES';

export type ChangeAvailableLanguagesAction = ReduxAction<
    typeof ChangeAvailableLanguagesActionType,
    { payload: string[] }
>;

export function createChangeAvailableLanguagesAction(languages: string[]): ChangeAvailableLanguagesAction {
    return {
        type: ChangeAvailableLanguagesActionType,
        payload: languages,
    };
}

export type LanguageAction =
    PreferredLanguageAction
    | ChangeAvailableLanguagesAction;