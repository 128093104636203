
import { Reducer, ApiRequest } from 'store/store';
import { GetSearchResponse, SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { initialApiRequest } from 'store/store-tools';

export const initialState: {
    searchTerm?: string
    requestKey?: string
    sg: ApiRequest<GetSearchResponse['sg']>
    sgm: ApiRequest<GetSearchResponse['sgm']>
    person: ApiRequest<GetSearchResponse['person']>
    company: ApiRequest<GetSearchResponse['company']>
} = {
    searchTerm: undefined,
    requestKey: undefined,
    sg: initialApiRequest(),
    sgm: initialApiRequest(),
    person: initialApiRequest(),
    company: initialApiRequest(),
} as const;

export type SgmSearchState = typeof initialState;

export const SgmSearchReducer: Reducer<SgmSearchState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SEARCH/TERM':
            return {
                ...state,
                searchTerm: action.payload.searchTerm || '',
            };

        case '@SEARCH/ITEM/FETCH_REQUEST':
            return {
                ...state,
                requestKey: action.payload.requestKey,
                sg: merge(state.sg, {
                    state: 'isFetching',
                }),
                sgm: merge(state.sgm, {
                    state: 'isFetching',
                }),
                person: merge(state.person, {
                    state: 'isFetching',
                }),
                company: merge(state.company, {
                    state: 'isFetching',
                }),
            };

        case '@SEARCH/ITEM/FETCH_SUCCESS':
            if (state.requestKey?.toLowerCase() !== action.payload.requestKey?.toLowerCase()) {
                // Nothing to do, another resquest is in progress
                return state;
            }
            return {
                ...state,
                sg: mergeIf(action, SearchResultType.SgItem, state.sg, {
                    state: 'valid',
                    data: action.payload.response.sg,
                }),
                sgm: mergeIf(action, SearchResultType.SgmItem, state.sgm, {
                    state: 'valid',
                    data: action.payload.response.sgm,
                }),
                person: mergeIf(action, SearchResultType.People, state.person, {
                    state: 'valid',
                    data: action.payload.response.person,
                }),
                company: mergeIf(action, SearchResultType.Company, state.company, {
                    state: 'valid',
                    data: action.payload.response.company,
                }),
            };

        case '@SEARCH/ITEM/FETCH_FAILURE':
            if (state.requestKey?.toLowerCase() !== action.payload.requestKey?.toLowerCase()) {
                // Nothing to do, another resquest is in progress
                return state;
            }
            return {
                ...state,
                sg: mergeIf(action, SearchResultType.SgItem, state.sg, {
                    state: 'error',
                }),
                sgm: mergeIf(action, SearchResultType.SgmItem, state.sgm, {
                    state: 'error',
                }),
                person: mergeIf(action, SearchResultType.People, state.person, {
                    state: 'error',
                }),
                company: mergeIf(action, SearchResultType.Company, state.company, {
                    state: 'error',
                }),
            };

        default:
            return state;
    }
};

const merge = <T>(oldState: T, newState: Partial<T>): T => ({
    ...oldState,
    ...newState,
});

interface ISearchResultTypesPayload {
    payload: {
        searchResultTypes: SearchResultType[],
    }
}

const mergeIf = <T>(action: ISearchResultTypesPayload, type: SearchResultType, oldState: T, newState: Partial<T>): T => {
    const condition = action.payload.searchResultTypes.length == 0 || action.payload.searchResultTypes.includes(type);
    return condition ? merge(oldState, newState) : oldState;
};
