import { NOTIFICATION_LIST_PATH } from 'models/constants';
import { Path, useMatch, useSearchParams } from 'react-router-dom';
import { ReadStatus } from 'services/ApiService/Notification/NotificationApiClient';

export const PRODUCER_CODE_ALL_KEY = 'all';

type UseNotificationFilterResponse = {
    producerCodeActive: string;
    readStatusActive: ReadStatus;
    isAllProducerActive: boolean;
    getFilterNavigatePath: (producerCode?: string, sortBy?: ReadStatus) => Partial<Path>;
    isAllProducer: (producerCode: string) => boolean;
};

export const useNotificationListFilter = (): UseNotificationFilterResponse => {
    const [searchParams] = useSearchParams();
    const match = useMatch(`${NOTIFICATION_LIST_PATH}/:producer_code`);
    const producerCodeActive = match?.params.producer_code ?? PRODUCER_CODE_ALL_KEY;
    const readStatusActive: ReadStatus = searchParams.get('sort') as Nullable<ReadStatus> ?? ReadStatus.Any;

    const isAllProducer = (producerCode: string | undefined): boolean => {
        return producerCode?.toLocaleLowerCase() === PRODUCER_CODE_ALL_KEY.toLocaleLowerCase();
    };
    
    const getFilterNavigatePath = (producerCode?: string, sortBy?: ReadStatus): Partial<Path> => {        
        producerCode = isAllProducer(producerCode) ? undefined : producerCode;
        let notifPath = NOTIFICATION_LIST_PATH;
        if (producerCode) {
            notifPath += `/${encodeURIComponent(producerCode)}`;
        }

        sortBy = sortBy === ReadStatus.Any ? undefined : sortBy;
        let searchSortBy = '';
        if (sortBy) {
            searchSortBy = new URLSearchParams({ sort: sortBy }).toString();
        }
        return ({
            pathname: notifPath,
            search: searchSortBy,
        });
    };

    const isAllProducerActive = isAllProducer(producerCodeActive);

    return {
        producerCodeActive,
        readStatusActive,
        isAllProducerActive,
        getFilterNavigatePath,
        isAllProducer,
    };
};
