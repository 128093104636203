import React, { FC, ReactNode, useState } from 'react';
import { Scroller } from './Scroller';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { Carousel, CarouselItem } from 'reactstrap';
import { ControlNext, ControlPrev } from '../carousel/CarouselControlTooltip';
import classNames from 'classnames';

interface ScrollerCarouselProps {
    name: string;
    maxDisplayedItems: number;
    className?: string;
    children: ReactNode[];
}

export const ScrollerCarousel: FC<ScrollerCarouselProps> = ({ name, maxDisplayedItems, className, children }) => {
    const mediaBreakpoint = useMediaBreakpoint();
    const isScrollable = ['xs', 'sm'].includes(mediaBreakpoint);
    const scrollerClassName = classNames('row g-2 flex-nowrap', className);

    if (isScrollable) {
        return <Scroller className={scrollerClassName}>
            {children}
        </Scroller>;
    } else {
        return <CarouselGroup name={name} mediaBreakpoint={mediaBreakpoint} maxDisplayedItems={maxDisplayedItems} className={className}>
            {children}
        </CarouselGroup>;
    }
};

interface CarouselGroupProps {
    name: string;
    mediaBreakpoint: string;
    maxDisplayedItems: number;
    className?: string;
    children: ReactNode[];
}

const CarouselGroup: FC<CarouselGroupProps> = ({ name, mediaBreakpoint, maxDisplayedItems, className, children }) => {
    const cardsPerGroup = getCardsPerGroup(mediaBreakpoint, maxDisplayedItems);
    const carouselClassName = classNames('row flex-row flex-nowrap', className);
    const [currentSlide, setCurrentSlide] = useState(0);
    const cardGroups = groupCards(children, cardsPerGroup);

    const moveSlideBy = (step: number) => {
        setCurrentSlide((cardGroups.length + currentSlide + step) % cardGroups.length);
    };

    const next = () => moveSlideBy(1);
    const previous = () => moveSlideBy(-1);

    const classNameCardWrapper = classNames({
        'col-12': cardsPerGroup === 1,
        'col-6': cardsPerGroup === 2,
        'col-4': cardsPerGroup === 3,
        'col-3': cardsPerGroup === 4,
    });

    const showButtons = cardGroups.length > 1;

    return (
        <div className="d-flex" aria-hidden="true">
            <Carousel
                activeIndex={currentSlide}
                next={next}
                previous={previous}
                interval={false}
                className="w-100"
                slide={false}
            >
                {cardGroups.map((group, groupIndex) => (
                    <CarouselItem key={groupIndex}>
                        <div className={carouselClassName}>
                            {group.map((card, cardIndex) => (
                                <div key={cardIndex} className={classNames(classNameCardWrapper, 'px-2')}>
                                    {card}
                                </div>
                            ))}
                        </div>
                    </CarouselItem>
                ))}
                {showButtons && <>
                    <ControlPrev idPrefix={name} onClickHandler={previous} showTop={true} />
                    <ControlNext idPrefix={name} onClickHandler={next} showTop={true} />
                </>}
            </Carousel>
        </div>
    );
};

export const groupCards = <T,>(
    cards: T[],
    cardsPerGroup: number,
): T[][] => {
    if (cards.length <= cardsPerGroup) {
        return [cards];
    }

    const groups = [];
    const nbMainGroups = Math.max(cards.length + 1 - cardsPerGroup, 1);
    for (let index = 0; index < nbMainGroups; index++) {
        groups.push(cards.slice(index, index + cardsPerGroup));
    }
    for (let index = nbMainGroups; index < cards.length; index++) {
        const tail = cards.slice(index);
        const head = cards.slice(0, index + cardsPerGroup - cards.length);
        groups.push(tail.concat(head));
    }
    return groups;
};

const getCardsPerGroup = (mediaBreakpoint: string, maxDisplayedItems: number): number => {
    switch (mediaBreakpoint) {
        case 'md':
            return maxDisplayedItems - 1;
        case 'lg':
        case 'xl':
        case 'xxl':
            return maxDisplayedItems;
        default:
            return 1; // Should not be used
    }
};
