import React from 'react';
import { TooltipButton } from 'components/common/tooltip/Tooltip';

export type CloseButtonProps = {
    closeModal: () => void;
};

export const CloseButton: React.FunctionComponent<CloseButtonProps> = ({
    closeModal,
}) => (
    <TooltipButton
        type="button"
        onClick={closeModal}
        tooltipId="close"
        className="btn-xl btn-icon btn-flat-secondary"
    >
        <i className="icon" style={{ fontSize: '2rem' }}>
            close
        </i>
    </TooltipButton>
);
